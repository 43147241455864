import logo from './logo.svg';
import './App.css';
import Main from './components/MainPage/Main';
import { Navigate, Route, Routes,  useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import PrivateBlockChainDevelopment from './components/PrivateBlockChainDevelopment/PrivateBlockChainDevelopment';
import BlockchainCunsalting from './components/blockchainCunsalting/BlockchainCunsalting';
import SmartContractArchiture from './components/SmartContractArchiture/SmartContractArchiture';
import SmartContractAudit from './components/SmartContractAudit/SmartContractAudit';
import MetaverseDevelopment from './components/MetaverseDevelopment/MetaverseDevelopment';
import Nftdevelopment from './components/Nftdevelopment/Nftdevelopment';
import Web3Development from './components/web30/Web3Development';
import Defidevelopment from './components/defiDevelopment/Defidevelopment';
import Aidevelopment from './components/AIDevelopment/Aidevelopment';
import AIAnalysis from './components/AIAnalysis/AiAnalysis';
import Blog from './components/Blog/blog';
import AiEngineer from './components/AI-Engineer/AI-Engineer';
import ChatgptApp from './components/Chatgpt-Application-Development/Chatgpt-Application';
import DStorage from './components/DStorage/index';
import Dcentralised from './components/Decentralised-whitelabel/Dcentralised';
import Cintegration from './components/Chatgpt-integration/Cintegration';
import GenerativeAi from './components/Generative-Ai-Solution/GenerativeAi';
import Dex from './components/Decentralised-Exchange/Dex';
import Dapp from './components/Dapp-Development/Dapp';
import CloudS from './components/Cloud-Services/CloudS';
import Wallet from './components/Defi-Wallet/Wallet';
import DefiStaking from './components/Defi-Staking/Defi-Staking';
import Dfarming from './components/Defi-Farming/Dfarming';
import Crowdfunding from './components/Defi-Crowdfunding/Crowdfunding';
import DLending from './components/Defi-Lending/DLending';
import NMarket from './components/Nft-marketplace/NMarket';
import Clone from './components/Nft-Clone/Clone';
import Token from './components/Nft-Token/Token';
import Lending from './components/Nft-Lending-Platform/Lending';
import Launchpad from './components/Nft-Launchpad/Launchpad';
import Minting from './components/Nft-Minting/Minting';
import AiProductMarket from './components/AI-ProductMarketing/AiProductMarket';
import SmartContractDevelopment from './components/Smartcontract-Dev/Smartcontract-Dev';
import Contact from './components/Contact/Contact';
import Sft from './components/Sft-development/Sft';
import Whitelabel from './components/Whitelabel-market/Whitelabel';
import MetaNft from './components/Meta-Nftmarket/MetaNft';
import MetaEcommerce from './components/Metaverse-Ecommerce/MetaEcommerce';
import MetaEstate from './components/Metaverse-Estate/MetaEstate';
import MetaAvatar from './components/Metaverse-Avatar/MetaAvatar';
import MetaHealthcare from './components/Metaverse-Healthcare/MetaHealthcare';
import { MetaVirtualevent } from './components/Metaverse-Virtual/MetaVirtualevent';
import MetaVirtualPlatform from './components/Meta-VirtualPlatform/MetaVirtualPlatform';
import { MetaApplication } from './components/Metaverse-Application/MetaApplication';
import Design from './components/3D-Design/Design';
import Crosschain from './components/Cross-Chain-Bridge/Crosschain';
import TradingBot from './components/Crypto-TradingBot/TradingBot';
import CryptoExchange from './components/Crypto-Exchange/CryptoExchange';
import ArbitrageBot from './components/CryptoArbitrage-Bot/ArbitrageBot';
import CryptocurrencyWallet from './components/Cryptocurrency-Wallet/CryptocurrencyWallet';
import P2pCrypto from './components/P2P-Cryptocurrency/P2pCrypto';
import CentralisedCrypto from './components/Centralised-Cryptocurrency-Exchange/CentralisedCrypto';
import Web3GameDevelopment from './components/Web3-Game-Development/Web3GameDevelopment';
import Blogdetail from './components/BlogDetails/Blogdetail';






function App() {
  const { pathname } = useLocation();
  const redirects = [
    {from:'PrivateBlockChainDevelopment',to:'blockchain-development-company'},
    {from:'Smartcontract-Dev',to:'smart-contract-development'},
    {from:'Smart Contract Audit',to:'smart-contract-audit'},
    {from:'DStorage',to:'decentralized-storage-solutions'},
    {from:'Metaverse Development Service',to:'metaverse-development-service'},
    {from:'Metaverse-Application',to:'metaverse-application-development'},
    {from:'Metaverse-Estate',to:'metaverse-real-estate-platform-development'},
    {from:'Metaverse-Healthcare',to:'metaverse-healthcare-solution'},
    {from:'Metaverse-Avatar',to:'metaverse-avatar-development '},
    {from:'Meta-VirtualPlatform',to:'metaverse-event-platform-development'},
    {from:'Meta-Nftmarket',to:'meta-nft-marketplace-Development'},
    {from:'Metaverse-Ecommerce',to:'metaverse-ecommerce-platform-development'},
    {from:'NFT Development',to:'nft-development'},
    {from:'Nft-marketplace',to:'nft-marketplace-development'},
    {from:'Nft-Clone',to:'nft-marketplace-clone'},
    {from:'Whitelabel-market',to:'whitelabel-market-NFT-marketplace-development'},
    {from:'Nft-Token',to:'nft-token-development'},
    {from:'Nft-Minting',to:'nft-minting-platform-development'},
    {from:'Nft-Launchpad',to:'nft-launchpad-development-service'},
    {from:'Nft-Lending-Platform',to:'nft-Lending-Platform-development'},
    {from:'Sft-development',to:'semi-funglibe-tokens-development '},
    {from:'Cryptocurrency-Wallet',to:'cryptocurrency-wallet-development'},
    {from:'Crypto-Exchange',to:'crypto-exchange-platform-development'},
    {from:'Decentralised-Exchange',to:'decentralised-exchange-development-company'},
    {from:'Decentralised-whitelabel',to:'decentralised-whitelabel-platform-development-company'},
    {from:'P2P-Cryptocurrency',to:'p2p-crypto-exchange-development-company'},
    {from:'Centralised-Cryptocurrency-Exchange',to:'centralised-exchange-development-company'},
    {from:'Crypto-TradingBot',to:'cryptocurrency-trading-bot-development'},
    {from:'CryptoArbitrage-Bot',to:'cryptocurrency-arbitrage-bot-development-company'},
    {from:'Web 3.0 Development',to:'web-3.0-development-company'},
    {from:'Defi Development',to:'defi-development-company'},
    {from:'Defi-Wallet',to:'defi-wallet-development'},
    {from:'Defi-Staking',to:'defi-staking-platform-development'},
    {from:'Defi-Farming',to:'defi-yield-farming-development'},
    {from:'Defi-Crowdfunding',to:'defi-crowdfunding-platform-development'},
    {from:'Defi-Lending',to:'defi-lending-and-borrowing-platform-development'},
    {from:'AI Development',to:'ai-development-company'},
    {from:'Chatgpt-integration',to:'chatgpt-integration-service'}
  ]
  // Scroll-to-top effect when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (

 
    // <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main/>} />
        {
          redirects.map((data,index)=>{
            return (
              <Route key={index} path={`/${data.from}`} element={<Navigate to={`/${data.to}`} replace />} />
            )
          })
        }
        
         <Route path="/blockchain-development-company" element={<PrivateBlockChainDevelopment/>} />
        <Route path="/BlockchainCunsalting" element={<BlockchainCunsalting />} />
        <Route path="/Smart-Contract-Architure" element={<SmartContractArchiture />} />
        <Route path="/smart-contract-audit" element={<SmartContractAudit />} />
        <Route path="/smart-contract-development" element={<SmartContractDevelopment/>}/>
        <Route path="/metaverse-development-service" element={<MetaverseDevelopment />} />
        <Route path="/metaverse-application-development" element={<MetaApplication/>}/>
        <Route path="/meta-nft-marketplace-development" element={<MetaNft/>}/>
        <Route path="/metaverse-ecommerce-platform-development" element={<MetaEcommerce/>}/>
        <Route path="/metaverse-real-estate-platform-development" element={<MetaEstate/>}/>
        <Route path="/metaverse-avatar-development" element={<MetaAvatar/>}/>
        <Route path="/metaverse-healthcare-solution" element={<MetaHealthcare/>}/>
        <Route path="/Metaverse-Virtual" element={<MetaVirtualevent/>}/>
        <Route path="/metaverse-event-platform-development" element={<MetaVirtualPlatform/>}/>
        <Route path="/nft-development" element={<Nftdevelopment />} />
        <Route path="/nft-marketplace-development" element={<NMarket/>}/>
        <Route path="/nft-marketplace-clone" element={<Clone/>}/>
        <Route path="/nft-token-development" element={<Token/>}/>
        <Route path="/nft-lending-platform-development" element={<Lending/>}/>
        <Route path="/nft-launchpad-development-service" element={<Launchpad/>}/>
        <Route path="/nft-minting-platform-development" element={<Minting/>}/>
        <Route path="/semi-funglibe-tokens-development" element={<Sft/>}/>
        <Route path="/whitelabel-market-nft-marketplace-development" element={<Whitelabel/>}/>
        <Route path="/web-3.0-development-company" element={<Web3Development/>} />
        <Route path="/web3-game-development" element={<Web3GameDevelopment/>}/>
        <Route path="/defi-development-company" element={<Defidevelopment/>} />
        <Route path="/defi-wallet-development" element={<Wallet/>}/>
        <Route path="/defi-staking-platform-development" element={<DefiStaking/>}/>
        <Route path="/defi-yield-farming-development" element={<Dfarming/>}/>
        <Route path="/defi-crowdfunding-platform-development" element={<Crowdfunding/>}/>
        <Route path="/defi-lending-and-borrowing-platform-development" element={<DLending/>}/>
        <Route path="/ai-development-company" element={<Aidevelopment/>} />
        <Route path="/ai-analysis" element={<AIAnalysis/>} />
        <Route path="/ai-engineering" element={<AiEngineer/>} />
        <Route path="/ai-product-marketing" element={<AiProductMarket/>}/>
        <Route path="/chatgpt-application-development" element={<ChatgptApp/>} />
        <Route path="/decentralized-storage-solutions" element={<DStorage/>}/>
        {/* <Route path="/blog" element={<Blog/>} /> */}
        <Route path="/decentralised-whitelabel-platform-development-company" element={<Dcentralised/>}/>
        <Route path="/chatgpt-integration-service" element={<Cintegration/>}/>
        <Route path="/generative-ai-solution" element={<GenerativeAi />}/>
        <Route path="/decentralised-exchange-development-company" element={<Dex/>}/>
        <Route path="/dapp-development" element={<Dapp/>}/>
        <Route path="/cloud-services" element={<CloudS/>}/>
        <Route path="/3d-design" element={<Design/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/Blog" element={<Blog/>}/>
        <Route path="/:slug"  element={<Blogdetail/>}/>
        <Route path="/cross-chain-bridge" element={<Crosschain/>}/>
        <Route path="/crypto-exchange-platform-development" element={<CryptoExchange/>}/>
        <Route path="/cryptocurrency-trading-bot-development" element={<TradingBot/>}/>
        <Route path="/cryptocurrency-arbitrage-bot-development-company" element={<ArbitrageBot/>}/>
        <Route path="/cryptocurrency-wallet-development" element={<CryptocurrencyWallet/>}/>
        <Route path="/p2p-crypto-exchange-development-company" element={<P2pCrypto/>}/>
        <Route path="/centralised-exchange-development-company" element={<CentralisedCrypto/>}/>
      </Routes>

  );
}

export default App;
