// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metacard img {
    width: 40% !important;
}

.nft-card-Dev2 {
    text-align: center !important;
    padding: 10px !important;
    margin: 10px 0px !important;
    height: 220px !important;
    background-color: #ffffff !important;
    position: relative !important;
    border: 0 !important;
  
}
.metacardSD {
    background-color: #000000;
    border: 1px solid #66bce8;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    height: 230px !important;
    /* width: 100% !important; */
}
.metacardSD img {
width: 150px ;
}
.metacardSD h2 {
    font-size: 20px;
    color: #66bce8;
    }
    .metacardSD p {
      color: #C1C1C1;
        }
.heading-box p{
    color: #ffffff !important;
    font-size: 15px !important;
} 

.heading-box1 p{
    border-left: 3px solid #4f6bf4 !important;
    color: #ffffff !important;
    font-size: 16px !important;
    padding-left: 20px;
}
.why-img2 img{
    width: 500px;
    height: 400px;
    margin-top: 5px;
}
.heading-box h4 {
    color: #57c2e9 !important;
    font-size: 27px;
}`, "",{"version":3,"sources":["webpack://./src/components/Smartcontract-Dev/SmartDevelopment.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,wBAAwB;IACxB,2BAA2B;IAC3B,wBAAwB;IACxB,oCAAoC;IACpC,6BAA6B;IAC7B,oBAAoB;;AAExB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,wBAAwB;IACxB,4BAA4B;AAChC;AACA;AACA,aAAa;AACb;AACA;IACI,eAAe;IACf,cAAc;IACd;IACA;MACE,cAAc;QACZ;AACR;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,0BAA0B;IAC1B,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".metacard img {\n    width: 40% !important;\n}\n\n.nft-card-Dev2 {\n    text-align: center !important;\n    padding: 10px !important;\n    margin: 10px 0px !important;\n    height: 220px !important;\n    background-color: #ffffff !important;\n    position: relative !important;\n    border: 0 !important;\n  \n}\n.metacardSD {\n    background-color: #000000;\n    border: 1px solid #66bce8;\n    text-align: center;\n    padding: 10px;\n    margin-bottom: 20px;\n    border-radius: 10px;\n    height: 230px !important;\n    /* width: 100% !important; */\n}\n.metacardSD img {\nwidth: 150px ;\n}\n.metacardSD h2 {\n    font-size: 20px;\n    color: #66bce8;\n    }\n    .metacardSD p {\n      color: #C1C1C1;\n        }\n.heading-box p{\n    color: #ffffff !important;\n    font-size: 15px !important;\n} \n\n.heading-box1 p{\n    border-left: 3px solid #4f6bf4 !important;\n    color: #ffffff !important;\n    font-size: 16px !important;\n    padding-left: 20px;\n}\n.why-img2 img{\n    width: 500px;\n    height: 400px;\n    margin-top: 5px;\n}\n.heading-box h4 {\n    color: #57c2e9 !important;\n    font-size: 27px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
