import React from 'react'
import Layout from '../Layout/Layout'
import '../SmartContractAudit/SmartContractAudit.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
const SmartContractAudit = () => {
  return (
    <Layout>
 <section className="bannerSC">

<div className="container">
  <div className="row ">
    <div className="col-12 col-md-6">
      <div className="banner-caption2">
        <h1> <span className='block-clr'>Smart Contract Audit</span> </h1>
        <p>Ensure the security and reliability of your blockchain projects with Blockcoaster's expert Smart Contract Auditing services. Trust in our meticulous approach for flawless results.
        </p>
        <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>     

      </div>
    </div>

    <div className="col-12 col-md-6 text-center">
      <div className="banner-caption2">
        <img src="./Assets/smart-contract/1.png"  width="390px"  alt="" />

      </div>
    </div>     
  </div>
</div>
</section>

{/* 2 */}
<section className='bgleft'>
          <div className='container'>
         
          <div className='row align-items-center'>
               <div className='col-12 col-md-6 pt-3'>
               <div class="heading-box text-start">
               <h4>Achieve Impeccable Blockchain Security with Our Smart Contract Auditing Services</h4>
               
                    <p style={{color:"#fff", fontSize:"15px"}}>
                    Blockcoaster's smart contract audit services provide the highest level of security for your blockchain applications. Our team of experts identifies and mitigates potential vulnerabilities in your smart contracts before they become critical threats. By offering comprehensive audits, we ensure your project is secure, efficient, and fully compliant with industry standards. Whether it's DeFi, NFTs, or crypto tokens, we help safeguard your assets and boost user trust, ensuring your blockchain venture's long-term success.
</p>
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
               <div className='col-12 col-md-6 text-center'>
               <div class="why-img2">
               <img src="./Assets/audit.png" className='img-fluid' alt="" />
                  </div>   
               </div>
          </div>
          </div>
      </section>
   

   {/* 3 */}



      <div className='containerSM'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-6'>
                            <div class="heading-boxe">
                                <h3>
                                Why Does Your Project Need a Smart Contract Security Audit?

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                        <div class="heading-boxe">
                                <p>Recent blockchain breaches, like the Poly Network hack, underline the critical need for smart contract security audits. As smart contracts are immutable, thorough audits are essential to uncover vulnerabilities and prevent costly exploits. Ensuring robust security through regular audits helps protect assets and maintains user trust in decentralized platforms.


                                </p>
                             
                               </div>
                        </div>
                    </div>
                   
                </div>     


      {/* 4 */}

      <section className='bgleft'>
                <div className='container'>
                    <div className='row '>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3> Key Features of Our Smart Contract Audit Services</h3>
                              
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row  justify-content-center'>
                        <div className='col-md-4 col-12'>
                            <div className='cardAudit core-cardS'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/10.png" alt=""  />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Security First Approach</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>We prioritize the identification of critical security vulnerabilities and loopholes.</p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardAudit core-cardS'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/11.png" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Comprehensive Bug Detection</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Our audits ensure no bugs or errors go unnoticed, protecting your project from potential exploits.</p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardAudit core-cardS'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/12.png" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Gas Optimization</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>We improve the efficiency of your smart contract by optimizing gas usage, making your transactions cost-effective.</p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardAudit core-cardS'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/13.png" alt=""  />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Compliance Verification</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Our audit ensures your smart contracts meet industry standards and comply with blockchain protocols.</p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardAudit core-cardS'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/14.png" alt=""  />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Detailed Audit Reports</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> After completion, we provide a comprehensive report outlining all detected issues and solutions to resolve them.</p>

                            </div>
                        </div>

                    


                    </div>

                </div>
            </section>

            {/* 5 */}
            <section className='bgright'>

<div className="container">
<div class="row py-1">
             <div class="col-12">
               <div class="heading-box">
                 <h3>Comprehensive Audits for Leading Blockchain Platforms </h3>
                 <div class="line2"></div>
               </div>
             </div>
           </div>
<Swiper
  modules={[Navigation, Pagination, Scrollbar, A11y]}
  spaceBetween={10}
  slidesPerView={3}
  centeredSlides={true}
  navigation
  pagination={{ clickable: true }}
  scrollbar={{ draggable: true }}
  onSwiper={(swiper) => console.log(swiper)}
  loop={true}  // Enable looping
  onSlideChange={() => console.log('slide change')}
  breakpoints={{
   320: {  // Screens with a width of 320px or more
     slidesPerView: 1,  // Show 1 slide
     centeredSlides: true,  // Center the slide
   },
   768: {  // Screens with a width of 768px or more (e.g., tablets)
     slidesPerView: 5,  // Show 2 slides
   },
   1024: {  // Screens with a width of 1024px or more (e.g., desktops)
     slidesPerView:5,  // Show 3 slides
   },
 }}
   
  
>
<SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/binance.png"   alt="" />
           <h3>Binance Smart Chain (BSC)</h3>
      </div>
   
     </SwiperSlide>
     {/* 2 */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/solana.png"   alt="" />
           <h3>Solana</h3>
      </div>
   
     </SwiperSlide>

     {/* 3/ */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card' style={{height: "0px !important"}}>
         <img src="./Assets/nft/polygon.png"  alt="" />
           <h3>Polygon</h3>
      </div>
   
     </SwiperSlide>

     {/* 4 */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/eth.png"   alt="" />
           <h3>	Ethereum</h3>
      </div>
   
     </SwiperSlide>
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
      <img src="./Assets/smart-contract/Polkadot.jpg" alt="" />
      <h3>Polkadot</h3>
          
      </div>
   
     </SwiperSlide>



  
   ...
 </Swiper>
</div>
</section>


<section className='bgleft'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Our Auditing Process for Secure Smart Contracts</h3>
                          
                            </div>
                        </div>
                    </div>
                <div className='row mt-5 justify-content-center'>
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                      <div className='line-circle'>
                        <p>01</p>
                      </div>
                         <div className='s-line'></div>
                            {/* <div className='block-icon'>
                            <img src="./Assets/web30/Process/1.png" alt="" />
                            </div> */}
                          <h3>Initial Assessment
                          </h3>
                          <p>We begin by understanding your project and its requirements, reviewing the architecture and codebase.</p>
                             


                     </div>
               </div>

               {/* 2 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>02</p>
                      </div>
                         <div className='s-line2'></div>
                           
                          <h3>Manual Code Review
                          </h3>
                          <p>Our experts manually inspect to uncover logic flaws, vulnerabilities, and inefficiencies.</p>
                             


                     </div>
               </div>

               {/* 3 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>03</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Automated Testing
                          </h3>
                          <p>We use automated tools to detect vulnerabilities like reentrancy, gas inefficiencies, and overflows.</p>
                             


                     </div>
               </div>
               {/* 4 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>04</p>
                      </div>
                         <div className='s-line2'></div>
                          
                          <h3>Risk Mitigation
                          </h3>
                          <p>We provide solutions to fix vulnerabilities and optimize smart contract performance.</p>
                             


                     </div>
               </div>
               {/* 5 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>05</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Final Audit Report
                          </h3>
                          <p> We re-audit the updated code and provide a detailed report on fixes and security.
                          </p>
                             


                     </div>
               </div>
             
        
          </div>   
                </div>

              </section>
    </Layout>
  )
}

export default SmartContractAudit;
