import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import "../Blog/blog.css";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from "../../environment";
import axios from "axios";

const Blog = () => {
  const [blogdata, setBlogdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getBlogs = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`${apiUrl}/blog/getAllBlogs`);
      setBlogdata(resp.data.data);
    } catch (error) {
      console.log("errorn in  blog Page");
    }
    setLoading(false);
  };

  const openBlog = async (data) => {
    debugger
    const keysdata = !data?.key ? data?.subtitle : data?.key;
    const slug = `${keysdata
      .replace(/\s+/g, "-")
      .toLowerCase()}-${data.blogId?.toString()}`;
      navigate(`/${slug}`); // Pushes the route to /:slug
  };
  useEffect(() => {
    getBlogs();
  }, []);

  console.log("blogdata",blogdata);
  

  return (
    <Layout>
      <section className="bgdark1">
        <div className="col-12">
        <div className="col-12">
          <div className="heading-box-sec text-center py-4">
            <h3> Recent Updates</h3>
          </div>
        </div>
        </div>
        <div className="container">
          <div className="blog-container">
            {blogdata.map((value,index)=>{
               const date = new Date(+value.timestamps);
               const options = {
                 year: "numeric",
                 month: "short",
                 day: "2-digit",
               };
 
               const formattedDate = date.toLocaleDateString("en-US", options);
              return(
                <div className="blog-card">
                <img src={`${apiUrl}/image?pathName=BLOGS_IMAGE&imageName=${value?.blogImg[0]}`} alt="Blog Image" />
                <div className="blog-card-content">
                  <div className="blog-date">{formattedDate}</div>
                  {/* <div className="blog-author">By John Doe</div> */}
                  <div className="blog-title">{value?.title}</div>
                  {/* <div className="blog-excerpt">
                  {value?.description}
                  </div> */}
                  <div onClick={()=>openBlog(value)} className="blog-read-more">
                    Read more
                  </div>
                </div>
              </div>
              )
            })}
          
         
          </div>
        </div>
      </section>

      {/* 
<div className="container">
  <h4>Latest Updates</h4>
  <div className="row">
    <div className="col-md-12">
      <div id="clients-slider" className="owl-carousel">
        <div className="client">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
              <img src="https://www.blockchainappfactory.com/blog/wp-content/uploads/2025/01/On-chain-Crypto-Coin-Development.webp" />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="post_category">
                <a className="btn">Cryptocurrency development</a>
              </div>
              <h2 title="On-Chain Crypto Coin Development: Why On-Chain Crypto Is Vital for Modern Businesses in 2025?"> <a href="https://www.blockchainappfactory.com/blog/on-chain-crypto-coin-development/"> On-Chain Crypto Coin Development: Why On-Chain Crypto Is Vital for Modern Businesses in 2025? </a> </h2>
              <p>Blockchain technology is reshaping modern business foundations, offering solutions beyond innovation to address fundamental challenges like transparency, security, and efficiency. From revolutionizing financial transactions to streamlining supply chains and enhancing customer engagement, blockchain is steadily becoming a cornerstone of business…                                  </p><p><a href="https://www.blockchainappfactory.com/blog/on-chain-crypto-coin-development/"> Read More </a> </p>
            </div>
          </div>
        </div>
        <div className="client">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
              <img src="https://www.blockchainappfactory.com/blog/wp-content/uploads/2025/01/Agentic-AI-for-Building-Smarter-RAG-Applications.webp" />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="post_category">
                <a className="btn">AI Development</a>
              </div>
              <h2 title="Agentic AI in Action: Building Intelligent, Autonomous RAG Applications"> <a href="https://www.blockchainappfactory.com/blog/agentic-ai-for-building-autonomous-rag-applications/"> Agentic AI in Action: Building Intelligent, Autonomous RAG Applications </a> </h2>
              <p>Artificial Intelligence (AI) has come a long way, hasn't it? From the early days of simple rule-based systems to today's sophisticated autonomous agents, the journey has been nothing short of remarkable. Let's take a closer look at this evolution and…                                  </p><p><a href="https://www.blockchainappfactory.com/blog/agentic-ai-for-building-autonomous-rag-applications/"> Read More </a> </p>
            </div>
          </div>
        </div>
        <div className="client">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
              <img src="https://www.blockchainappfactory.com/blog/wp-content/uploads/2025/01/AI-and-ML-for-Application-Modernization.webp" />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="post_category">
                <a className="btn">AI Development</a>
              </div>
              <h2 title="AI and ML for Application Modernization: Transforming Legacy Systems for the Future"> <a href="https://www.blockchainappfactory.com/blog/ai-and-ml-for-application-modernization/"> AI and ML for Application Modernization: Transforming Legacy Systems for the Future </a> </h2>
              <p>Key Takeaways Modernizing legacy systems with AI and ML improves efficiency, reduces costs, and enhances scalability for businesses. Success relies on stakeholder alignment, skilled teams, and addressing ethical concerns like data privacy and algorithmic fairness. Businesses can leverage tailored AI…                                  </p><p><a href="https://www.blockchainappfactory.com/blog/ai-and-ml-for-application-modernization/"> Read More </a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
    </Layout>
  );
};

export default Blog;
