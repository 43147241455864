import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import "../BlogDetails/Blogdetail.css";
import { apiUrl } from "../../environment";
import axios from "axios";
function Blogdetail() {
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const [info, setInfo] = useState({});
  const timestamp = info?.timestamps;
  const date = new Date(+timestamp);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  console.log(
    `${apiUrl}/image?pathName=BLOGS_IMAGE&imageName=${info?.blogImg}`
  );

  const getInfo = async (blogId) => {
    try {
      const resp = await axios(`${apiUrl}/blog/getBlog/${blogId}`);
      console.log("blog details", resp.data.findBlog);
      setInfo(resp.data.findBlog);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // const blogno = searchParams.get("blogno");
    const blogId = slug.split("-").pop();
    getInfo(blogId);
  }, [searchParams]);

  return (
    <Layout>
      <div id="main-content" className="blog-page">
        <div className="container mx-auto">
          {info && (
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <div className="post-card single_post">
                  <div className="body">
                    <div className="img-post">
                      <img
                        className="d-block img-fluid"
                        src={`${apiUrl}/image?pathName=BLOGS_IMAGE&imageName=${info?.blogImg}`}
                        alt="First slide"
                      />
                    </div>
                    <h3>
                      <a href="blog-details.html">{info?.title}</a>
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: info?.description,
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-12 right-box">
    <div className="post-card">
      <div className="header">
        <h2 style={{ color: "black" }}>Categories Clouds</h2>
      </div>
      <div className="body widget">
        <ul className="list-unstyled categories-clouds m-b-0">
          <li>
            <a href="javascript:void(0);">eCommerce</a>
          </li>
          <li>
            <a href="javascript:void(0);">Microsoft Technologies</a>
          </li>
          <li>
            <a href="javascript:void(0);">Creative UX</a>
          </li>
          <li>
            <a href="javascript:void(0);">Wordpress</a>
          </li>
          <li>
            <a href="javascript:void(0);">Angular JS</a>
          </li>
          <li>
            <a href="javascript:void(0);">Enterprise Mobility</a>
          </li>
          <li>
            <a href="javascript:void(0);">Website Design</a>
          </li>
          <li>
            <a href="javascript:void(0);">HTML5</a>
          </li>
          <li>
            <a href="javascript:void(0);">Infographics</a>
          </li>
          <li>
            <a href="javascript:void(0);">Wordpress Development</a>
          </li>
        </ul>
      </div>
    </div>
    <div className="post-card">
      <div className="header">
        <h2 style={{ color: "black" }}>Popular Posts</h2>
      </div>
      <div className="body widget popular-post">
        <div className="row">
          <div className="col-lg-12">
            <div className="single_post">
              <p className="m-b-0">
                Apple Introduces Search Ads Basic
              </p>
              <span>jun 22, 2018</span>
              <div className="img-post">
                <img
                  src="https://www.bootdey.com/image/280x280/FFB6C1/000000"
                  alt="Awesome Image"
                />
              </div>
            </div>
            <div className="single_post">
              <p className="m-b-0">
                new rules, more cars, more races
              </p>
              <span>jun 8, 2018</span>
              <div className="img-post">
                <img
                  src="https://www.bootdey.com/image/280x280/FFB6C1/000000"
                  alt="Awesome Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="post-card">
<div className="header">
  <h2>Instagram Post</h2>
</div>
<div className="body widget">
  <ul className="list-unstyled instagram-plugin m-b-0">
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
    <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
  </ul>
</div>
</div>
    <div className="post-card">
<div className="header">
  <h2>Email Newsletter <small>Get our products/news earlier than others, let’s get in touch.</small></h2>
</div>
<div className="body widget newsletter">                        
  <div className="input-group">
    <input type="text" className="form-control" placeholder="Enter Email" />
    <div className="input-group-append">
      <span className="input-group-text"><i className="icon-paper-plane" /></span>
    </div>
  </div>
</div>
</div>
  </div> */}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Blogdetail;
