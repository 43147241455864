// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headLine{
  width: 100vh;
}
.card{
margin-right: 10px;
margin-left: 10px;
margin-bottom: 15px;
width: 30%;
border-radius: 10px;
}
.card img{
  width: 100%;
}
.icon{
    padding-top: 10px;
    font-size: 15px;
    margin-left: 40%;
    margin-top: -35px;
}
.icon img {
    width: 20px;
    height: 20px;
}
.card h5{
    padding-top: 10px;
}
.icon1{
    font-size: 15px;
    margin-left: 60%;
    margin-top: -25px;
}
.icon1 img {
    width: 20px;
    height: 20px;
}
.text1{
    padding: 10px;
    font-size: 15px;
   justify-content: right;
}
.link{
    font-size: 15px;
   background-color: rgb(235, 228, 228);
   background-size: cover;
}
.subcontainer{
    margin:auto;
    /* width: 100%; */
}
.maincontainer{
    justify-content: center;

}`, "",{"version":3,"sources":["webpack://./src/components/Blog/blog.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;AACA,kBAAkB;AAClB,iBAAiB;AACjB,mBAAmB;AACnB,UAAU;AACV,mBAAmB;AACnB;AACA;EACE,WAAW;AACb;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,aAAa;IACb,eAAe;GAChB,sBAAsB;AACzB;AACA;IACI,eAAe;GAChB,oCAAoC;GACpC,sBAAsB;AACzB;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,uBAAuB;;AAE3B","sourcesContent":[".headLine{\n  width: 100vh;\n}\n.card{\nmargin-right: 10px;\nmargin-left: 10px;\nmargin-bottom: 15px;\nwidth: 30%;\nborder-radius: 10px;\n}\n.card img{\n  width: 100%;\n}\n.icon{\n    padding-top: 10px;\n    font-size: 15px;\n    margin-left: 40%;\n    margin-top: -35px;\n}\n.icon img {\n    width: 20px;\n    height: 20px;\n}\n.card h5{\n    padding-top: 10px;\n}\n.icon1{\n    font-size: 15px;\n    margin-left: 60%;\n    margin-top: -25px;\n}\n.icon1 img {\n    width: 20px;\n    height: 20px;\n}\n.text1{\n    padding: 10px;\n    font-size: 15px;\n   justify-content: right;\n}\n.link{\n    font-size: 15px;\n   background-color: rgb(235, 228, 228);\n   background-size: cover;\n}\n.subcontainer{\n    margin:auto;\n    /* width: 100%; */\n}\n.maincontainer{\n    justify-content: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
