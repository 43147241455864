import React from 'react'
import Layout from '../Layout/Layout';
import '../Decentralised-whitelabel/Dcentralised.css'


function Dcentralised() {
  return (
  <Layout>
  
    <section id="home" class="hero">
    <div className="col-12 col-md-6">

<div class="hero-right">
    <div class="hero-content">
        <h1>White Label Decentralized Exchange Development (DEX)</h1>
        <p>Designed for forward-thinking businesses, our DEX enables you to launch a personalized trading
            platform
            that aligns with your brand’s vision and values. Experience hassle-free deployment and take your
            first
            step into the crypto space with confidence.</p>
    </div>
</div>
</div>
<div className="col-12 col-md-6">
<div class="hero-left">
    <img src="Assets/blockchain/DEX/banner.png" alt="Banner Image" class="hero-img"/>
</div>
</div>
</section>


<div className='bgright'>
<section id="services" class="services">
<h2>Standout Features That Elevate Your DEX Experience</h2>
<div class="service-cards">
    <div class="service-card">
        <img src="Assets/blockchain/DEX/1.jpg" alt="Blockchain"/>
        <h5>Decentralized Trading Mechanism </h5>
        <p>Empower users to trade crypto assets directly without intermediaries, ensuring greater security and
            autonomy.</p>
    </div>
    <div class="service-card">
        <img src="Assets/blockchain/DEX/2.jpg" alt="Security"/>
        <h5>Cross-Chain Compatibility</h5>
        <p>Offer users access to a wider range of tokens across different blockchain networks.</p>
    </div>
    <div class="service-card">
        <img src="Assets/blockchain/DEX/3.png" alt="Cloud Computing"/>
        <h5>Dynamic Liquidity Pools </h5>
        <p>Keep trading smooth and efficient with built-in liquidity pools that enhance user experience.</p>
    </div>

</div>
<div class="service-cards">
    <div class="service-card">
        <img src="Assets/blockchain/DEX/4.jpeg" alt="Blockchain"/>
        <h5>Seamless Wallet Integration </h5>
        <p>Incorporate your exchange wallet or connect with leading third-party wallets for secure
            transactions.</p>
    </div>
    <div class="service-card">
        <img src="Assets/blockchain/DEX/5.jpg" alt="Security"/>
        <h5>Mobile-First Approach </h5>
        <p>Our solutions include robust mobile application development, ensuring users can trade anytime,
            anywhere, using the best app for crypto trading.</p>
    </div>
    <div class="service-card">
        <img src="Assets/blockchain/DEX/6.jpg" alt="Cloud Computing"/>
        <h5>Fortified Security Protocols </h5>
        <p>Adhere to top-notch security measures, ensuring your platform remains the safest crypto exchange
            available.</p>
    </div>

</div>
</section>
</div>

<div className='bgleft'>
<section id="use-cases" class="use-cases">
<h2 class="use-cases1">Transformative Use Cases for Your White Label DEX</h2>

<div class="use-case-card">
    <div class="use-case-content">
        <h5>Cryptocurrency Exchange Hub</h5>
        <p>Enable users to trade popular cryptocurrencies like <strong>Bitcoin</strong> and
            <strong>Ethereum</strong> with ease. Our platform ensures fast transactions and a user-friendly
            interface, allowing both novice and seasoned traders to navigate effortlessly.
        </p>
    </div>
    <img src="Assets/blockchain/DEX/c1.jpeg" alt="Cryptocurrency Exchange Hub" class="use-case-image"/>
</div>

<div class="use-case-card">
    <div class="use-case-content">
        <h5>Decentralized Finance (DeFi) Solutions</h5>
        <p>Offer comprehensive DeFi functionalities such as <strong>staking</strong> and <strong>yield
                farming</strong>. By integrating these features, you can attract a diverse user base and enhance
            engagement, making your DEX a go-to destination for decentralized finance.</p>
    </div>
    <img src="Assets/blockchain/DEX/c2.png" alt="Decentralized Finance Solutions" class="use-case-image"/>
</div>

<div class="use-case-card">
    <div class="use-case-content">
        <h5>Tokenized Asset Trading</h5>
        <p>Unlock new trading avenues for <strong>NFTs</strong> and other digital assets. With our white label
            solution, you can facilitate the buying, selling, and trading of tokenized assets, positioning your
            platform at the forefront of the digital economy.</p>
    </div>
    <img src="Assets/blockchain/DEX/c3.png" alt="Tokenized Asset Trading" class="use-case-image"/>
</div>

<div class="use-case-card">
    <div class="use-case-content">
        <h5>Futures Trading Capabilities</h5>
        <p>Equip your platform with features for <strong>crypto futures trading</strong>, allowing users to
            leverage their investments across a range of trading pairs. This capability attracts professional
            traders seeking advanced trading strategies and opportunities.</p>
    </div>
    <img src="Assets/blockchain/DEX/c4.png" alt="Futures Trading Capabilities" class="use-case-image"/>
</div>

<div class="use-case-card">
    <div class="use-case-content">
        <h5>Comprehensive Mobile App</h5>
        <p>Launch a robust mobile application that empowers users with instant trading and portfolio management
            capabilities. A mobile-first approach ensures your users have seamless access to their investments
            on the go, enhancing their trading experience.</p>
    </div>
    <img src="Assets/blockchain/DEX/c5.jpg" alt="Comprehensive Mobile App" class="use-case-image"/>
</div>
</section>
</div>
   
<div className='bgright'>
<section id="whychoose" class="whychoose">
<h2 class="whychoose-title">Why Choose Blockcoaster for Your White Label DEX Development?</h2>

<div class="whychoose-card">
    <div class="whychoose-content">
        <h3>Tailored Solutions</h3>
        <p>Customize every feature and functionality of your DEX to reflect your brand and user preferences.
       Enable users to trade popular cryptocurrencies like <strong>Bitcoin</strong> and
            <strong>Ethereum</strong> with ease. Our platform ensures fast transactions and a user-friendly
            interface, allowing both novice and seasoned traders to navigate effortlessly.
        </p>
    </div>
</div>

<div class="whychoose-card">
    <div class="whychoose-content">
        <h3>Expert Development Team</h3>
        <p>Our experienced developers specialize in creating robust DeFi functionalities such as
            <strong>staking</strong> and <strong>yield farming</strong>. By integrating these features, you can
            attract a diverse user base and enhance engagement, making your DEX a go-to destination for
            decentralized finance.
        </p>
    </div>
</div>

<div class="whychoose-card">
    <div class="whychoose-content">
        <h3>Rapid Market Entry</h3>
        <p>Unlock new trading avenues for <strong>NFTs</strong> and other digital assets. With our white label
            solution, you can facilitate the buying, selling, and trading of tokenized assets, positioning your
            platform at the forefront of the digital economy.</p>
    </div>
</div>

<div class="whychoose-card">
    <div class="whychoose-content">
        <h3>Regulatory Expertise</h3>
        <p>Our team ensures your platform complies with industry regulations, equipping it with features for
            <strong>crypto futures trading</strong>. This allows users to leverage their investments across a
            range of trading pairs, attracting professional traders seeking advanced trading strategies.
        </p>
    </div>
</div>

<div class="whychoose-card">
    <div class="whychoose-content">
        <h3>Scalable and Secure Architecture</h3>
        <p>Launch a robust mobile application that empowers users with instant trading and portfolio management
            capabilities. Our mobile-first approach ensures users have seamless access to their investments on
            the go, enhancing their trading experience.</p>
    </div>
</div>
</section>
</div>
  </Layout>
  )
}
export default Dcentralised;
