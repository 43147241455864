import React from 'react'
import Layout from '../Layout/Layout'
import '../Nft-Lending-Platform/Lending.css'
import { Link } from 'react-router-dom';
function Lending() {
  return (
    <Layout>
    <section className="banner4">
   
   <div className="container">
     <div className="row ">
       <div className="col-12 col-md-6">
         <div className="banner-caption2" style={{marginTop:"-50px"}} >
           <h1>NFT Lending Platform Development 
           </h1>
           <p>At Blockcoaster, we provide advanced NFT lending platform development solutions that allow users to unlock liquidity from their digital assets. Our innovative platform enables NFT holders to use their valuable NFTs as collateral for securing loans, ensuring access to liquidity without having to sell their assets.

           </p>
           <Link to="/Contact" className="rounded-button-link">
  <button className="rounded-buttonM">Connect With Our Experts</button>
</Link>  
         </div>
       </div>
   
       <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/Nftlending/10.png" className='img-fluid' alt="" />
   
         </div>
       </div>
     </div>
   </div>
   </section>
   
   <section className="bgright">
   
   <div className="container">
     <div className="row ">
     <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/Nftlending/11.png" className='img-fluid' alt="" />
   
         </div>
       </div>
   
   
       <div className="col-12 col-md-6">
         <div className="banner-caption2">
           <h1>Why NFT Lending is a Smart Financial Move

   </h1>
           <p>NFT lending is a strategic financial option that allows owners to leverage their digital assets without liquidation. By turning static NFTs into active collateral, users can earn passive income and maintain ownership as the market fluctuates. This approach not only enhances liquidity but also provides a safeguard against market volatility, making it a transformative asset management strategy.

   
           </p>
         </div>
       </div>
   
      
     </div>
   </div>
   </section>
   
   {/* 2 */}
   
   
   <section className='bgleft'>
             <div className='container'>
             <div className='row'>
                  <div className='col-12'>
                  <div class="heading-box">
                       <h3>Cutting-Edge Features of Our NFT Lending Platform

   </h3>
   {/* <div class="line3"></div> */}
                       {/* <div class="line2"></div> */}
                     </div>   
                  </div>
             </div>
       
        <div className='row'>
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/Nftlending/24.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Cross-Chain NFT Collateralization
                         </h3>
                      </div>
   
                      <p>Lend or borrow against NFTs on multiple blockchain networks, such as Ethereum, Binance Smart Chain, and Polygon, ensuring greater flexibility.

   
                      </p>
                </div>
            </div>
   
            {/* 2 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                <img src="./Assets/Nftlending/25.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Instant Loan Approval
                         </h3>
                      </div>
   
                      <p>With blockchain-based smart contracts, loan approvals are automated and instant, providing quick access to liquidity.

   
                      </p>
                </div>
            </div>
   
            {/* 3 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/Nftlending/26.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Interest Rate Customization
                         </h3>
                      </div>
   
                      <p>Borrowers and lenders can negotiate and set customizable interest rates, offering flexibility to both parties.

   
                      </p>
                </div>
            </div>
   
            {/* 4 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/Nftlending/27.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Asset Valuation Tools
                         </h3>
                      </div>
   
                      <p>Our platform provides built-in tools that assess the current market value of NFTs, helping lenders and borrowers make informed decisions.

   
   
                      </p>
                </div>
            </div>
   
            {/* 5 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/Nftlending/28.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Integrated NFT Marketplace
                         </h3>
                      </div>
   
                      <p>Borrowers can easily transition between borrowing and selling, with an option to list collateralized NFTs for sale directly on the marketplace.
                      </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/Nftlending/29.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Secure Smart Contracts
                         </h3>
                      </div>
   
                      <p>Each lending transaction is governed by robust smart contracts that ensure transparency, security, and automation, reducing the risk of fraud.

                      </p>
                </div>
            </div>

         
   
        </div>
             </div>
         </section>
   
   
        
         {/* 3 */}
   
       
         <section className='bgright'>
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <div className="heading-box-sec text-center py-4">
                                   <h3>NFT Lending Process 

   
                                   </h3>
                               </div>
                           </div>
                       </div>
                       <div className="row justify-content-center">
                          
                           {/* 2 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardL">
                                   <img src="./Assets/Nftlending/30.png" alt="" />
                                   <h2>Register and Connect Wallet</h2>
                                   <p>Users sign up and connect their cryptocurrency wallets to the platform.

   
                                   </p>
                               </div>
                           </div>
                           {/* 3 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardL">
                                   <img src="./Assets/Nftlending/31.png" alt="" />
                                   <h2>List NFTs as Collateral
                                   </h2>
                                   <p> NFT holders can list their digital assets as collateral, specifying loan terms such as amount, interest, and duration.

                                   </p>
                               </div>
                           </div>
                           {/* 4 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardL">
                                   <img src="./Assets/Nftlending/32.png" alt="" />
                                   <h2>Lender Matches
                                   </h2>
                                   <p> Lenders review NFT collateral offers and match borrowers based on loan terms and asset value.
                                   </p>
                               </div>
                           </div>
                           {/* 5 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardL">
                                   <img src="./Assets/Nftlending/155.png" alt="" />
                                   <h2>Smart Contract Execution
                                   </h2>
                                   <p>Once terms are agreed upon, a smart contract is executed, holding the NFT as collateral while the loan is disbursed to the borrower.

                                   </p>
                               </div>
                           </div>
                           <div className="col-md-4 col-12">
                               <div className="metacardL">
                                   <img src="./Assets/Nftlending/33.png" alt="" />
                                   <h2>Repayment & NFT Return
                                   </h2>
                                   <p>After loan repayment, the smart contract automatically releases the NFT back to the borrower. If repayment fails, the lender can claim the NFT.


                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
   
               {/* 4 */}
               <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3> Business Benefits of NFT Lending Platforms

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/Nftlending/34.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Enhanced Asset Liquidity</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>NFT lending platforms provide NFT holders with immediate liquidity, expanding financial opportunities within the digital asset space.

                                </p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/Nftlending/35.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>New Revenue Streams</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>  Lenders can earn interest on loaned assets, creating a consistent revenue stream in exchange for loaning crypto funds.

                                </p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/Nftlending/36.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Broader Market Reach</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> By introducing lending, businesses can attract a larger audience, including those interested in decentralized finance (DeFi) and passive income opportunities.

                                </p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/Nftlending/37.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Secure and Transparent Transactions</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>All lending agreements are governed by blockchain-based smart contracts, ensuring immutable and secure transactions.

                                </p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/Nftlending/38.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Increased NFT Utility</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Allowing NFTs to be used as collateral increases their utility, enhancing their appeal to investors and expanding use cases beyond collectibles.
                                </p>

                            </div>
                        </div>

                        {/* 6 */}
                       


                    </div>

                </div>
            </section>        






               {/* <section className='bgright'>
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <div className="heading-box-sec text-center py-2">
                                   <h3>Why Choose Blockcoaster for NFT Lending Platform Development?
                                   </h3>
                               </div>
                           </div>
                       </div>
                       <div className="row">
                     
                           <div className="col-12 col-md-8">
                               <div className="caption-list mt-md-5">
                      <p>
                      Expertise in Blockchain & NFTs <br/>
                      Customized Solutions <br/>
                      Seamless User Experience <br/>
                      Top-Notch Security <br/>
                       Innovative Features <br/>


                      </p>
   
                                      
                               </div>
                           </div>
                        
                       </div>
                   </div>
               </section> */}
   
               <section className='bgright'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Why Choose Blockcoaster for NFT Lending Platform Development?</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/1.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption' style={{marginTop:"10px"}}>
                                   <h3> Expertise in Blockchain & NFTs

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/2.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption'  style={{marginTop:"20px"}}>
                                   <h3> Customized Solutions 

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/3.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption'  style={{marginTop:"20px"}}>
                                   <h3>  Seamless User Experience

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/4.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption'  style={{marginTop:"20px"}}>
                                   <h3>Top-Notch Security 
                                

                          </h3>
                       
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                {/* 9 */}
                <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/5.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption'  style={{marginTop:"20px"}}>
                                   <h3>   Innovative Features

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
               
                {/* 10 */}
                     {/* 9 */}
                  
             
          </div>
        
                </div>

              </section>

   
               
       </Layout>
  )
}

export default Lending
