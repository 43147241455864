import React from 'react'
import Layout from "../Layout/Layout";
import '../DStorage/index.css' ;
import { Link } from 'react-router-dom';
const index = () => {
  return (
    <Layout>
     <section className="banner">
        <h1>Decentralized Storage Solutions</h1>
        <p className="mt-25">With Blockcoaster’s decentralized storage solutions, experience unmatched data security and privacy by
            leveraging blockchain technology. Safeguard your critical data, eliminate single points of failure, and
            reduce costs with scalable, peer-to-peer storage systems tailored to your business needs.</p>
          
            <Link to="/contact" className="rounded-button-link">
  <button className="rounded-buttonII">Connect With Our Experts</button>
</Link>  
            
    </section>


    <div className="bgdark">
        <div className="bg-gradient"></div>

        <div className="container">
            <div className="about-block">
                <div className="block-1">
                    <div className="feature-thumb">
                        <img className="feature-thumb alltuchtopdown" src="Assets/blockchain/Decentralised/abt-1.png" alt="About Blockcoaster"/>
                    </div>
                </div>
                <div className="block-2">
                    <div className="section-title mb-75">
                        <h2 className="title style2">Decentralized Storage Solutions by Blockcoaster</h2>
                        <p className="mt-25">At Blockcoaster, we provide cutting-edge decentralized storage solutions that
                            enable businesses and individuals to securely store, share, and access data without relying
                            on traditional centralized servers. Our decentralized storage services harness the power of
                            blockchain technology to distribute data across a network of nodes, ensuring enhanced
                            privacy, security, and redundancy.</p>
                    </div>
                    <div className="about-grid-wrap">
                        <div className="about-wrap">
                            <div className="about-card">
                                <h3 className="about-card_number">63M</h3>
                                <p className="about-card_text">Blockchain users</p>
                            </div>
                        </div>
                        <div className="about-wrap">
                            <div className="about-card">
                                <h3 className="about-card_number">24%</h3>
                                <p className="about-card_text">Companies use blockchain</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div className="bgblue">
    <section className="container">
        <div className="decentralized-storage">
            <img className="feature-img floating-img" src="Assets/blockchain/Decentralised/feature.png" alt="img"/>
            <div className="feature-wrap-4">
                <div className="section-title mb-0">
                    <span className="sub-title text-title">FEATURES</span>
                    <h2 className="title style2 text-title">Decentralized Storage Explained</h2>
                    <p className="text-title mt-20 mb-55">Traditional cloud storage providers rely on centralized servers,
                        making them vulnerable to data breaches, downtime, and hacking. Decentralized storage eliminates
                        these
                        risks by splitting and encrypting your data across multiple nodes on a peer-to-peer (P2P)
                        network. This
                        ensures your files are accessible only to authorized users, with no single point of failure.</p>
                </div>
            </div>
        </div>
    </section>
    </div>


            <section className='bgdark'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Our Services</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/1.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Blockchain-based Data Storage Solutions

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/2.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Filecoin and IPFS Integration
                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/3.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Custom Decentralized Storage Development

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/4.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Data Encryption and Backup Solutions

                          </h3>
                       
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                {/* 9 */}
                <div className='col-12 col-md-4'>
                     <div className='cardSD Industry-blockchainSD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/blockchain/Decentralised/5.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Seamless Integration with Existing Cloud Platforms

                          </h3>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
               
                {/* 10 */}
                     {/* 9 */}
                  
             
          </div>
        
                </div>

              </section>





    <div className="bgblue">
    <section className="container">
        <div className="decentralized-storage">
            <div className="row">
               
                <div className="col-sm-6">
                    <div>
                        <h3>Advantages of Decentralized Storage</h3>
                        <ul>
                            <li><b>Enhanced Security:</b> With encryption and decentralization, your data is safeguarded
                                against
                                cyber threats.
                            </li>
                            <li><b>Privacy-Focused:</b> No third-party intermediaries can access or control your data.</li>
                            <li><b>Scalability:</b> Effortlessly scale up your storage requirements as your business grows,
                                without
                                the
                                limitations of central servers.</li>
                            <li><b>Reduced Costs:</b> By leveraging a global network of nodes, decentralized storage often
                                results
                                in lower
                                storage costs compared to traditional providers.</li>
                            <li><b>Immutable Data Records:</b> Blockchain technology ensures the immutability of stored data,
                                offering
                                long-term integrity for records and files.</li>
                        </ul>
                    </div>
                </div>


                <div className="col-sm-6">
                    <div className="advantages-img text-center">
                        <img  src="Assets/blockchain/Decentralised/abt-2.png" alt="About Blockcoaster"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
 </div>

 <section className='bgdark'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5">
                            <div className="sideimg">
                                <img src="./Assets/blockchain/Decentralised/why.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="sidetxt">
                                <h3>Why Decentralized Storage is Essential for Your Business
                                </h3>
                                <p>In a world where data privacy and security are paramount, decentralized storage solutions provide a revolutionary way to store and manage critical data. With Blockcoaster’s expertise in blockchain and decentralized technologies, you can trust us to deliver secure, reliable, and cost-effective storage for your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

     </Layout>
  )
}

export default index
