import React from 'react'
import Layout from '../Layout/Layout'
import { Link } from 'react-router-dom'
import '../Cryptocurrency-Wallet/CryptocurrencyWallet.css'

const CryptocurrencyWallet = () => {
  return (
    <Layout>
        <section className="bannerH">
       
       <div className="container">
         <div className="row ">
           <div className="col-12 col-md-6">
             <div className="banner-captionCW">
               <h1>Crypto Wallet Development Company

               </h1>
               <p>Get ahead in the decentralized world of finance with a robust crypto wallet designed for secure, seamless cryptocurrency storage and transactions. Our expert crypto wallet development solutions empower users to trade, invest, and manage their digital assets with confidence.
               </p>
               <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  
             </div>
           </div>
       
           <div className="col-12 col-md-6 text-center">  
             <div className="why-img-banner" >
               <img src="./Assets/Crypto/cryptowallet/1.png" className='img-fluid' alt="" />
       
             </div>
           </div>
         </div>
       </div>
       </section>
       
       <section className="bgleftH">
       
       <div className="container">
         <div className="row ">
         <div className="col-12 col-md-6 text-center">
             <div className="why-img-banner">
               <img src="./Assets/Crypto/cryptowallet/2.png" className='img-fluid' alt="" />
       
             </div>
           </div>
       
       
           <div className="col-12 col-md-6">
             <div className="banner-caption2">
               <h1>Our Crypto Wallet Development Solutions
               </h1>
               <p>Our comprehensive crypto wallet development services cater to the growing needs of the decentralized finance (DeFi) ecosystem. Designed to integrate with major cryptocurrency exchanges and crypto trading platforms, our wallets support diverse functionalities, from storing crypto coins to enabling secure transactions and exchanges. With a focus on user-centric design, each wallet we create is easy to navigate, providing users with a seamless experience while engaging with crypto markets, managing cryptocurrency prices, or accessing top crypto exchanges.
               </p>
             </div>
           </div>
       
          
         </div>
       </div>
       </section>
       
       {/* 2 */}
        
       <section className='bgleftH'>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="heading-box-secW text-center py-4">
                    <h3>Types of Crypto Wallet Development We Offer
                    </h3>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/101.png" alt="" />
                    <h2>Mobile Wallets
                    </h2>
                    <p>Custom-designed wallets for iOS and Android, providing users with secure access to crypto holdings and transactions on the go. These wallets support biometric login, QR code scanning, and real-time transaction tracking.
                    </p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/201.png" alt="" />
                    <h2>Web Wallets
                    </h2>
                    <p>Browser-based wallets that allow users to access their crypto from any device with internet, integrating easily with exchanges for trading. They provide quick access to assets without the need for software downloads.
                    </p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/301.png" alt="" />
                    <h2>Desktop Wallets
                    </h2>
                    <p>Desktop wallets offer enhanced security features, including offline capabilities and compatibility with hardware wallets. Ideal for advanced users who prioritize safety and need access to cold storage options.
                    </p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/4.png" alt="" />
                    <h2>Hardware Wallets
                    </h2>
                    <p>Physical wallets for secure offline storage, ideal for long-term holding and large investments due to their resistance to online attacks. They’re popular for their focus on security, especially in high-value asset management.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/5.png" alt="" />
                    <h2>DeFi Wallets
                    </h2>
                    <p>Specialized wallets for decentralized finance, allowing users to stake, yield farm, and swap tokens within the wallet. These wallets connect users directly to DeFi protocols, enhancing investment opportunities.
                    </p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/6.png" alt="" />
                    <h2>Multi-Signature Wallets
                    </h2>
                    <p>Designed for extra security, multi-signature wallets require multiple parties to approve transactions. This feature is especially useful for organizations needing additional transaction controls and safety.
                    </p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardCW">
                    <img src="./Assets/Crypto/cryptowallet/7.png" alt="" />
                    <h2>White-Label Wallets</h2>
                    <p>Ready-made, fully customizable wallets tailored to businesses, enabling rapid deployment and brand integration. These wallets offer flexibility in features, appearance, and security standards to meet specific needs.</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section className='bgleftH'>
  <div className='heading-box-secW text-center py-4'>
<h1>Our Wallet Development Process</h1>
</div>
      <ul className="timeline">
        <li className="timeline-item" style={{ "--accent-color": "#41516C" }}>
          <div className="date">Requirements Analysis</div>
          <div className="descr">
          Define the project’s scope by analyzing blockchain compatibility, security features, and user requirements. This step ensures a tailored approach to meet the unique goals and compliance standards of each wallet.

          </div>
        </li>
        <li className="timeline-item" style={{ "--accent-color": "#FBCA3E" }}>
          <div className="date">Architectural Design
          </div>
          <div className="descr">
          Plan a secure and scalable wallet architecture capable of handling high transaction volumes and multi-currency support. This stage establishes the framework for smooth performance and reliability across devices.

          </div>
        </li>
        <li className="timeline-item" style={{ "--accent-color": "#E24A68" }}>
          <div className="date">Smart Contracts</div>
          <div className="descr">
          Develop DeFi-compatible smart contracts that automate processes like staking, yield farming, and token swapping. These self-executing contracts ensure secure, real-time functionality within the wallet.

          </div>
        </li>
        <li className="timeline-item" style={{ "--accent-color": "#4CADAD" }}>
          <div className="date">UI/UX Design
          </div>
          <div className="descr">
          Design a user-friendly, intuitive interface to offer users seamless navigation and transaction handling. Focus on simplicity and accessibility to enhance user experience across devices and skill levels.
          </div>
        </li>
        <li className="timeline-item" style={{ "--accent-color": "#41516C"  }}>
          <div className="date">Backend & APIs
          </div>
          <div className="descr">
          Build a robust backend with APIs to enable real-time data updates, exchange integrations, and price feeds. This setup ensures that the wallet remains responsive and efficient during high-volume transactions.

          </div>
        </li>

        <li className="timeline-item" style={{ "--accent-color": "#FBCA3E" }}>
          <div className="date">Security Protocols

          </div>
          <div className="descr">
          Integrate advanced security features like multi-factor authentication, data encryption, and multi-signature capabilities. These protocols protect against unauthorized access and keep user assets safe.
          </div>
        </li>

        <li className="timeline-item" style={{ "--accent-color":"#E24A68" }}>
          <div className="date">Testing & QA</div>
          <div className="descr">
          Conduct extensive testing to ensure security, performance, and reliability of the wallet under various conditions. Rigorous quality assurance guarantees that all wallet functionalities meet the highest standards.
 </div>
        </li> 


        <li className="timeline-item" style={{ "--accent-color": "#4CADAD" }}>
          <div className="date">Deployment & Support</div>
          <div className="descr">
          Launch the wallet with full technical support and updates to keep up with evolving blockchain advancements. This step ensures the wallet’s stability, usability, and security post-launch.</div>
        </li> 

      </ul>
      {/* <div className="credits">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.freepik.com/free-vector/infographic-template-with-yearly-info_1252895.htm"
        >
          inspired by
        </a>
      </div> */}
</section>
       
                   <section className="bgleftH">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="heading-box-secW text-center py-4">
          <h3>Benefits of a Custom Crypto Wallet
          </h3>
        </div>
      </div>
    </div>
  </div>

  <div className="container1">
    <div className="card1 orange-card">
      <p className="ourText titleText">Enhanced Security</p>
      <p className="ourText">Protect your digital coins with advanced encryption and multi-signature capabilities.</p>
    </div>

    <div className="card1 orange-card">
      <p className="ourText titleText">Easy Access</p>
      <p className="ourText"> Buy, sell, and trade cryptocurrency from anywhere with a secure and intuitive wallet interface.
      </p>
    </div>

    <div className="card1 orange-card">
      <p className="ourText titleText">Cross-Platform Compatibility</p>
      <p className="ourText">Manage your investments across devices, whether it’s a mobile, desktop, or offline crypto wallet.</p>
    </div>

    <div className="card1 orange-card">
      <p className="ourText titleText">Customizable Features</p>
      <p className="ourText">Tailor the wallet to support specific coins, token standards, or crypto trading requirements.
      </p>
    </div>

   
  </div>
</section>
    
    
    
    
       <section className='bgleftH'>
                 <div className='container'>
                 <div className='row'>
                      <div className='col-12'>
                      <div class="heading-boxW text-center py-4">
                           <h3>Key Features of Our Crypto Wallet
                           </h3>
       {/* <div class="line3"></div> */}
                           {/* <div class="line2"></div> */}
                         </div>   
                      </div>
                 </div>
           
            <div className='row justify-content-center'>
                <div className='col-12 col-md-4'>
                    <div className='card marketing-cardW'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/Crypto/cryptowallet/8.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Multi-Currency Support
                             </h3>
                          </div>
       
                          <p>Our wallets support multiple cryptocurrencies, including Bitcoin, Ethereum, and altcoins.
                          </p>
                    </div>
                </div>
       
                {/* 2 */}
                <div className='col-12 col-md-4'>
                    <div className='card marketing-cardW'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                    <img src="./Assets/Crypto/cryptowallet/9.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Real-Time Market Data
                             </h3>
                          </div>
       
                          <p>Access real-time cryptocurrency prices and data on top cryptocurrencies to make informed trading decisions.
                          </p>
                    </div>
                </div>
       
                {/* 3 */}
                <div className='col-12 col-md-4'>
                    <div className='card marketing-cardW'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/Crypto/cryptowallet/10.png" className='img-fluid' alt="" />
                          </div>
                             <h3>In-App Exchange 
                             </h3>
                          </div>
       
                          <p>Easily convert digital coins within the wallet, eliminating the need for an external exchange.</p>
                    </div>
                </div>
       
                <div className='col-12 col-md-4'>
                    <div className='card marketing-cardW'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/Crypto/cryptowallet/11.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Two-Factor Authentication 
                             </h3>
                          </div>
       
                          <p> Robust security with two-factor authentication for safe and reliable transactions.

                          </p>
                    </div>
                </div>

                <div className='col-12 col-md-4'>
                    <div className='card marketing-cardW'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/Crypto/cryptowallet/12.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Push Notifications 
                             </h3>
                          </div>
       
                          <p>Stay updated on your investment, cryptocurrency prices, and market trends.


                          </p>
                    </div>
                </div>


            </div>
                 </div>
             </section>
       
    
             {/* 3 */}
             <section className='bgleftH'>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="heading-box-secW text-center py-4">
            <h3>Safety Features Integrated into Our Crypto Wallets


    
            </h3>
            <div className='line3'></div>
    
          </div>
    
    
        </div>
      </div>
    
      <div className='row justify-content-center'>
        <div className="col-12 col-md-3">
          <div className='card nft-card-DevW'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/Crypto/cryptowallet/13.png" alt="" />
            </div>
            <h3>Multi-Signature Authentication</h3>
    
            <p>Enhanced security for transactions by requiring multiple approvals.


    
            </p>
          </div>
        </div>
        {/* 2 */}  <div className="col-12 col-md-3">
          <div className='card nft-card-DevW'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/Crypto/cryptowallet/14.png" alt="" />
            </div>
            <h3>Biometric Authentication  </h3>
            <p>Supports fingerprint and facial recognition for secure access.


    
            </p>
          </div>
        </div>
    
        {/* 3 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-DevW'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/Crypto/cryptowallet/15.png" alt="" />
            </div>
            <h3>Cold Storage Option </h3>
            <p> A safe offline crypto wallet solution for long-term storage of assets.


    
            </p>
          </div>
        </div>
        {/* 4 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-DevW'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/Crypto/cryptowallet/16.png" alt="" />
            </div>
            <h3>End-to-End Encryption 
            </h3>
            <p>Protects sensitive user data and transactions within the wallet environment.

    
            </p>
          </div>
        </div>
        {/* 5 */}
       
        {/* 8 */}
       
      </div>
    </div>
    </section>
    
          


    <section className='bgleftH'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-secW text-center py-5">
                            <h3>Types of Multi-Currency Wallets


                            </h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/17.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Hot Multi-Currency Wallets

                          </h3>
                          <p>Internet-connected, allowing quick multi-asset access. Ideal for active traders.


                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/18.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Cold Multi-Currency Wallets

                          </h3>
                          <p>Offline storage for multiple assets, offering maximum security for long-term holding </p>
                                   </div>
                              </div>
                            </div>
                   </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/19.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Custodial Multi-Currency Wallets

                          </h3>
                          <p>Managed by third parties, offering convenience but requiring trust in the provider.
                              </p>
                              </div>
                              </div>
                            </div>
                   </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/20.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Non-Custodial Multi-Currency Wallets</h3>
                          <p> User controls private keys, ensuring full asset ownership and security.</p>
                              </div>
                              </div>
                            </div>
                   </div>
               </div>
                {/* 9 */}
                <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/21.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Wallets with Integrated Exchange</h3>
                          <p>Enables trading between cryptocurrencies within the wallet, streamlining asset conversion.</p>
                             </div>
                              </div>
                            </div>
                    </div>
               </div>
               
                {/* 10 */}

                <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/22.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Decentralized Multi-Currency Wallets

                          </h3>
                          <p>No third-party control, offering full privacy and ownership across decentralized networks.



                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                     {/* 9 */}
                     <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/23.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Browser Extension Multi-Currency Wallets</h3>
                          <p>Lightweight browser add-ons, ideal for interacting with dApps and Web3.</p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
             
   <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/24.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Hardware Wallets with Mobile Compatibility


                          </h3>
                          <p>Physical devices that connect to mobile, balancing security and portability.




                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/25.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Enterprise Multi-Currency Wallets


                          </h3>
                          <p>Designed for businesses, with multi-signature and compliance features for secure handling.




                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>


               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/26.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Cross-Platform Wallets

                          </h3>
                          <p>Syncs assets across mobile, desktop, and web for flexible access.




                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/27.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>NFT-Compatible Wallets

                          </h3>
                          <p>Supports NFTs and fungible tokens, tailored for digital asset collectors.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-imgW'>
                                     <img src="./Assets/Crypto/cryptowallet/28.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>DeFi-Specific Wallets


                          </h3>
                          <p>Built-in DeFi features like staking and lending, optimized for decentralized finance.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

          </div>
        
                </div>

              </section>
                   {/* 4 */}
                  
                
            

              <section className='bgleftH'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-box-secW text-center py-2">
                                    <h3>Why Choose Our Crypto Wallet Development Services?


                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                      
                            <div className="col-12 col-md-8">
                                <div className="caption-list mt-md-5">
                                  <p>As an industry leader in crypto and blockchain technology, we offer secure, reliable, and customizable wallet solutions that cater to diverse needs—whether for crypto trading platforms, DeFi projects, or individual users. Our team ensures top-tier security, quick deployment, and user-centric design in each wallet, setting new standards for cryptocurrency wallet solutions in the market.

                                  </p>                
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                          <div className="qstimg">
                             <img src="./Assets/Crypto/cryptowallet/3.png" className='img-fluid' alt="metaverse" />
                          </div>
                            </div>
                        </div>
                    </div>
                </section>
    
    
                <section className='bgleftH'>
                  <div className="container py-3">
      <div className="heading-box text-center ">
        <h3 className="mb-3">FAQ</h3>
      </div>
      {/* 1 */}
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item rounded-3 border-0 shadow mb-2">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            What is a crypto wallet?



            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>A crypto wallet is a digital tool that allows users to store, manage, and transact with cryptocurrencies securely. Our wallets provide additional functionalities like viewing real-time cryptocurrency prices and integration with major crypto exchanges.



              </p>
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="accordion-item rounded-3 border-0 shadow mb-2">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Is my crypto safe in your wallet?

    
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>Yes, our wallets come with state-of-the-art security measures, including multi-signature and end-to-end encryption, to ensure your assets are safe from unauthorized access.



              </p>
            </div>
          </div>
        </div>
        {/* 3 */}
     
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
            What are the different types of wallets you offer?


    
            </button>
          </h2>
          <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>We offer various wallet types, including cold wallets, hot wallets, mobile wallets, desktop wallets, and web wallets, each suited for different user preferences and security needs.



              </p>
            </div>
          </div>
        </div>
        {/* 5 */}
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
            Can I store multiple cryptocurrencies?

    
            </button>
          </h2>
          <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p> Absolutely! Our multi-currency wallets support a wide range of cryptos, making them ideal for those with diverse portfolios.

              </p>
            </div>
          </div>
        </div>
        {/* 6*/}
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
            Do I have control over my private keys?



    
            </button>
          </h2>
          <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>For non-custodial wallets, users maintain full control over their private keys, ensuring they have complete ownership of their assets.


              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
                  </section>

                  {/* <h1>Responsive Card Grid Layout</h1>
  <ul class="cardsWallet">
    <li class="cardsWallet_item">
      <div class="cardcrypto">
        <div class="cardsWallet_image">  <img src="./Assets/whitelabel/2.png" className='img-fluid' alt="" /></div>
        <div class="cardsWallet_content">
          <h2 class="cardsWallet_title">Card Grid Layout</h2>
          <p class="cardsWallet_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
        </div>
      </div>
    </li>
    <li class="cardsWallet_item">
      <div class="cardcrypto">
        <div class="cardsWallet_image"><img src="https://picsum.photos/500/300/?image=5"/></div>
        <div class="cardsWallet_content">
          <h2 class="cardsWallet_title">Card Grid Layout</h2>
          <p class="cardsWallet_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
        </div>
      </div>
    </li>
    <li class="cardsWallet_item">
      <div class="cardcrypto">
        <div class="cardsWallet_image"><img src="https://picsum.photos/500/300/?image=11"/></div>
        <div class="cardsWallet_content">
          <h2 class="cardsWallet_title">Card Grid Layout</h2>
          <p class="cardsWallet_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
        </div>
      </div>
    </li>
    <li class="cardsWallet_item">
      <div class="cardcrypto">
        <div class="cardsWallet_image"><img src="https://picsum.photos/500/300/?image=14"/></div>
        <div class="cardsWallet_content">
          <h2 class="cardsWallet_title">Card Grid Layout</h2>
          <p class="cardsWallet_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
        </div>
      </div>
    </li>
    <li class="cardsWallet_item">
      <div class="cardcrypto">
        <div class="cardsWallet_image"><img src="https://picsum.photos/500/300/?image=17"/></div>
        <div class="cardsWallet_content">
          <h2 class="cardsWallet_title">Card Grid Layout</h2>
          <p class="cardsWallet_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
        </div>
      </div>
    </li>
    <li class="cardsWallet_item">
      <div class="cardcrypto">
        <div class="cardsWallet_image"><img src="https://picsum.photos/500/300/?image=2"/></div>
        <div class="cardsWallet_content">
          <h2 class="cardsWallet_title">Card Grid Layout</h2>
          <p class="cardsWallet_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
        </div>
      </div>
    </li>
  </ul> */}





           </Layout>
  )
}

export default CryptocurrencyWallet
