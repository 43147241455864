import React from "react";
import Layout from "../Layout/Layout";
import '../AIAnalysis/AiAnalysis.css';

const AiAnalysis = () => {
  return (
    <Layout>
      <section className="banner3">
        {/* <div class="video_fadeoverlay"></div> */}
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-6">
              <div className="banner-caption2">
                <h1> <span className=''></span>AI-Driven Insights for Products & Business
                </h1>
                <p>Boost product performance and elevate your business strategies with Blockcoaster’s AI Analysis. Get real-time insights, predictive analytics, and smarter decision-making to fuel growth and innovation.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 text-center">
              <div className="why-img-banner">
                <img src="./Assets/aidevelopment/3.png" className='img-fluid' alt="" />

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 2 */}
      <section className='bgleft'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div class="heading-box">
                <h3>AI Analysis for Products
                </h3>
                {/* <div class="line2"></div> */}
              </div>
            </div>
          </div>
          <div className='row py-3 align-items-center'>
            <div className='col-12 col-md-6 pt-3'>
              <div class="heading-box-2">
                <h4>
                  Unleash the Power of AI for Product Optimization
                </h4>
                <p>
                  In today’s fast-paced digital world, leveraging Artificial Intelligence is essential for staying competitive. At Blockcoaster, we harness advanced AI and machine learning to optimize product performance, boost innovation, and drive efficiency.

                </p>
                {/* <div class="line2"></div> */}
              </div>
            </div>
            <div className='col-12 col-md-6 text-center'>
              <div class="why-img2">
                <img src="./Assets/aidevelopment/2.png" className='img-fluid' alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 3 */}
      <section className='bgright'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div class="heading-box">
                <h3>Our AI Development Services
                </h3>
                {/* <div class="line2"></div> */}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-12 col-md-3">
              <div className='card nft-card-Dev'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/aidevelopment/services/ai1.gif" alt="" />
                </div>
                <h3>Data Collection</h3>

                <p>Using AI data analytics, we gather extensive data, including sales, customer feedback, and industry trends.
                </p>
              </div>
            </div>
            {/* 2 */}  <div className="col-12 col-md-3">
              <div className='card nft-card-Dev'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/aidevelopment/services/ai2.gif" alt="" />
                </div>
                <h3>AI-Driven Evaluation</h3>
                <p>Our intelligent algorithms, supported by tools like ChatGPT and conversational AI, assess product performance in real time.
                </p>
              </div>
            </div>

            {/* 3 */}
            <div className="col-12 col-md-3">
              <div className='card nft-card-Dev'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/aidevelopment/services/ai3.gif" alt="" />
                </div>
                <h3>Predictive Modeling</h3>
                <p>By applying AI predictive analytics, we forecast market shifts and consumer behaviors to stay ahead of trends.
                </p>
              </div>
            </div>
            {/* 4 */}
            <div className="col-12 col-md-3">
              <div className='card nft-card-Dev'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/aidevelopment/services/ai4.gif" alt="" />
                </div>
                <h3>Insightful Reporting
                </h3>
                <p>We generate AI-powered reports with actionable recommendations for product enhancements and market strategy.
                </p>
              </div>
            </div>
            {/* 5 */}

            {/* 6 */}


          </div>
        </div>
      </section>

      {/* 4 */}
      
      <div className="pset">
  <div className="container">
    <div className="row listar-feature-items">

      <div className="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div className="listar-feature-item listar-feature-has-link">


          <div className="listar-feature-item-inner">

            <div className="listar-feature-right-border"></div>

            <div className="listar-feature-block-content-wrapper">
              <div className="listar-feature-icon-wrapper">
                <div className="listar-feature-icon-inner">
                  <div>
                    <img alt="Businesses" class="listar-image-icon" src="https://cdn-icons-png.flaticon.com/128/921/921591.png"/>
                  </div>
                </div>
              </div>

              <div className="listar-feature-content-wrapper" style={{ paddingTop: '0px' }}>

                <div className="listar-feature-item-title listar-feature-counter-added">
                  <span><span>01</span>
                  Predictive Market Analysis </span>
                </div>

                <div className="listar-feature-item-excerpt">
                Leverage AI for predictive insights into customer preferences and market movements.
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div className="listar-feature-item listar-feature-has-link">


          <div className="listar-feature-item-inner">

            <div className="listar-feature-right-border"></div>

            <div className="listar-feature-block-content-wrapper">
              <div className="listar-feature-icon-wrapper">
                <div className="listar-feature-icon-inner">
                  <div>
                    <img alt="Customers" class="listar-image-icon" src="https://cdn-icons-png.flaticon.com/512/3414/3414151.png"/>
                  </div>
                </div>
              </div>

              <div className="listar-feature-content-wrapper" style={{ paddingTop: '0px' }}>

                <div className="listar-feature-item-title listar-feature-counter-added">
                  <span><span>02</span>
                  Sentiment Feedback </span>
                </div>

                <div className="listar-feature-item-excerpt">
                With AI chatbot technology and NLP, we analyze user sentiment to inform product adjustments.
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div className="listar-feature-item listar-feature-has-link">


          <div className="listar-feature-item-inner">

            <div className="listar-feature-right-border"></div>

            <div className="listar-feature-block-content-wrapper">
              <div className="listar-feature-icon-wrapper">
                <div className="listar-feature-icon-inner">
                  <div>
                    <img alt="Feedback" class="listar-image-icon" src="https://cdn-icons-png.flaticon.com/512/3412/3412953.png"/>
                  </div>
                </div>
              </div>

              <div className="listar-feature-content-wrapper" style={{ paddingTop: '0px' }}>

                <div className="listar-feature-item-title listar-feature-counter-added">
                  <span><span>03</span>
                  Real-Time Insights </span>
                </div>

                <div className="listar-feature-item-excerpt">
                Continuous AI data processing ensures instant access to critical performance metrics.

                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-3 listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
        <div className="listar-feature-item listar-feature-has-link">


          <div className="listar-feature-item-inner">

            <div className="listar-feature-right-border"></div>

            <div className="listar-feature-block-content-wrapper">
              <div className="listar-feature-icon-wrapper">
                <div className="listar-feature-icon-inner">
                  <div>
                    <img alt="Feedback" class="listar-image-icon" src="https://cdn-icons-png.flaticon.com/512/3412/3412953.png"/>
                  </div>
                </div>
              </div>

              <div className="listar-feature-content-wrapper" style={{ paddingTop: '0px' }}>

                <div className="listar-feature-item-title listar-feature-counter-added">
                  <span><span>04</span>
                  Custom AI Solutions </span>
                </div>  

                <div className="listar-feature-item-excerpt">
                Tailor our AI systems to suit your product’s unique needs.
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
      </div>
    
      
    </div>
  </div>
</div>
      {/* 5 */}
      <section className='bgright'>
      <div className="container">
      <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center py-4">
                <h3>Core Benefits
                </h3>
              </div>
            </div>
          </div>
        <ul className="grid-testimonials">
            <li>
            <span className="grid-testimonials-citation">Better Decisions</span>
                <div><p>Empower product teams with data-driven insights from AI and data science.
               </p><span className="quote-arrow"></span></div>
            </li>
            <li>
            <span className="grid-testimonials-citation">Cost Reduction</span>
                <div><p>Achieve faster, more efficient product research using AI machine learning solutions.
                </p><span className="quote-arrow"></span></div>
            </li>
            <li>
            <span className="grid-testimonials-citation">Enhanced Innovation</span>
                <div><p>AI for business intelligence drives creativity and new features for your product line.
                </p><span className="quote-arrow"></span></div>
            </li>
            {/* <li>
            <span className="grid-testimonials-citation">Improved Customer Experience</span>
                <div><p> Engage users more effectively with AI chatbots that collect and analyze feedback for continuous improvement.
                </p><span className="quote-arrow"></span></div>
            </li> */}
           
                                                                                    
        </ul>
    </div>  
</section>



    

      <section className='bgleft'>
        <div className="container py-3">
          <div className="heading-box text-center ">
            <h3 className="mb-3">FAQ</h3>
          </div>
          {/* 1 */}
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item rounded-3 border-0 shadow mb-2">
              <h2 className="accordion-header">
                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  What is AI development?
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>AI development involves creating systems that simulate human intelligence for tasks such as learning, problem-solving, and decision-making.</p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="accordion-item rounded-3 border-0 shadow mb-2">
              <h2 className="accordion-header">
                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  How does Blockcoaster handle AI projects?
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>We tailor our approach based on your needs, following a structured process from discovery to deployment and ongoing optimization.</p>
                </div>
              </div>
            </div>
            {/* 3 */}

            <div className="accordion-item rounded-3 border-0 mb-2 shadow">
              <h2 className="accordion-header">
                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                  Which industries benefit from AI?
                </button>
              </h2>
              <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>AI enhances various sectors including healthcare, finance, retail, manufacturing, transportation, and education.</p>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className="accordion-item rounded-3 border-0 mb-2 shadow">
              <h2 className="accordion-header">
                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                  What’s the typical timeline for AI solutions?
                </button>
              </h2>
              <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>Timelines vary by project complexity, generally ranging from a few weeks to several months.</p>
                </div>
              </div>
            </div>
            {/* 6*/}
            <div className="accordion-item rounded-3 border-0 mb-2 shadow">
              <h2 className="accordion-header">
                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                  What support does Blockcoaster offer post-deployment?
                </button>
              </h2>
              <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>We provide continuous support, including performance monitoring, issue resolution, and system optimization.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}
export default AiAnalysis;