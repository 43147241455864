import React from 'react'
import  '../blockchainCunsalting/BlockchainCunsalting.css'
import Layout from '../Layout/Layout';

const BlockchainCunsalting = () => {
  return (
    <Layout>

    <div className='main-box'>
       {/* banner */}
        <section className="banner">
          <div className="banner-ovalay"></div>
             <div className="container">
                 <div className="row">
                     <div className="col-md-8 col-12 mx-auto">
                       <div className="banner-caption">
                       <div className="Banner-heading">
                            <h2>
                            Elevate Your Business with Blockcoaster Blockchain Consulting</h2>
                         </div>
                          <p>Welcome to Blockcoaster, where innovation and excellence converge to redefine the landscape of blockchain consulting. Our seasoned professionals are dedicated to delivering secure, adaptable, and high-yielding blockchain development solutions, meticulously tailored to meet your distinctive needs and aspirations.</p>
                       </div>
                     </div>
                 </div>
             </div>
        </section>
        {/* end */}

        {/* 2 */} 
         <section className='bg1'>
         <div className="container">
           <div className="row"> 
            <div className="col-12 col-md-8 mx-auto">
           <div className="sec-heading">
            <h2>Why Blockcoaster?</h2>
           </div>
            </div>

           </div>
      
          <div className="row">
             <div className="col-12 col-md-3">
              <div className="card block_cont_card">
                  <img src="./Assets/blockchain/UnparalleledExpertise.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>Unparalleled Expertise</h2>
                   <p>Dive into the realm of blockchain with confidence, knowing that Blockcoaster boasts unparalleled expertise in Full-Stack Blockchain Development. From bespoke smart contract solutions to cutting-edge decentralized applications, we're equipped to actualize your boldest blockchain ambitions. </p>
                   </div>
              </div>
             </div>
             {/* 2*/}
             <div className="col-12 col-md-3">
              <div className="card block_cont_card">
                  <img src="./Assets/Efficiency-Redefined.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>Passion for Excellence</h2>
                   <p>At Blockcoaster, our pursuit of excellence knows no bounds. With an unwavering commitment to quality, we're fueled by a relentless passion to deliver world-class services that catalyze innovation and drive sustainable growth for your business. </p>
                   </div>
              </div>
             </div>

             {/* 3 */}
             <div className="col-12 col-md-3">
              <div className="card block_cont_card">
                  <img src="./Assets/TailoredforYou.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>Comprehensive Services</h2>
                   <p>Blockchain Technology: Unleash the transformative potential of blockchain technology with Blockcoaster. From ideation to execution, we'll guide you through the intricacies of blockchain adoption, empowering you to revolutionize your industry landscape. </p>
                   </div>
              </div>
             </div>
               {/* 4*/}
               <div className="col-12 col-md-3">
              <div className="card block_cont_card">
                  <img src="./Assets/TransparentyetConfidential.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>NFTs and Cryptocurrency</h2>
                   <p>Venture into the dynamic realm of non-fungible tokens (NFTs) and cryptocurrency with Blockcoaster as your trusted guide. Whether you're seeking to tokenize assets or explore new avenues of digital exchange, we'll help you navigate the complexities with finesse. </p>
                   </div>
              </div>
             </div>

             {/* 5 */}
           <div className="col-12 col-md-3">
              <div className="card block_cont_card">
              <img src="./Assets/blockchain/Tokenizationservices.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>Tokenization and Web 3.0 Services</h2>
                   <p>Embrace the future of decentralized platforms and applications with Blockcoaster's comprehensive tokenization and Web 3.0 services. By harnessing the power of blockchain, we'll enable you to build robust ecosystems that transcend traditional boundaries. </p>
                   </div>
              </div>
             </div>
                      {/* 6 */}
           <div className="col-12 col-md-3">
              <div className="card block_cont_card">
              <img src="./Assets/blockchain/metaverse.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>DeFi and Metaverse</h2>
                   <p>Stay ahead of the curve with Blockcoaster's expertise in decentralized finance (DeFi) and the metaverse. From yield farming to virtual real estate, we'll empower you to capitalize on emerging trends and unlock new frontiers of opportunity. </p>
                   </div>
              </div>
             </div>

                      {/* 7*/}
           <div className="col-12 col-md-3">
              <div className="card block_cont_card">
              <img src="./Assets/blockchain/SmartContracts.jpg" alt="" />
                   <div className='card-caption'>
                   <h2>Smart Contracts and DApps Services</h2>
                   <p>Streamline operations and enhance efficiency with Blockcoaster's tailor-made smart contracts and decentralized applications (DApps). By automating processes and optimizing workflows, we'll help you realize unprecedented levels of productivity and performance.</p>
                   </div>
              </div>
             </div>
          </div>
          </div>
         </section>

         {/* 3 */}

   

<section className='bg2'>
      <div className="container">
           <div className="row align-items-center"> 
              <div className="col-12 col-md-6">
                   <div className="sec-heading2">
                      <h2>Embark on Your Blockchain Odyssey</h2>
                   </div>
                    <div className="caption">
                         <p>Ready to embark on a transformative journey with Blockcoaster? Let's collaborate to harness the full potential of blockchain technology and chart a course towards unparalleled success. Reach out to us today to explore how we can propel your business into the future.</p>
                    </div>
                </div> 

                 <div className="col-12 col-md-6">
                     <div className="blockchainimg">
                         <img src="./Assets/blockchain/blockchain-abt.png" className='img-fluid' alt="" />
                     </div>
                 </div>
           </div>
      </div>
</section>
     
 <section className='bg1'>
 <div className="container">
               <div className="row">
      <div className="col-12"> 
      <div className="sec-heading2 text-center">
                      <h2>Join the Blockcoaster Movement</h2>
                   </div>
                    <div className="caption">
                         <p>Join forces with Blockcoaster and become a trailblazer in the ever-evolving landscape of blockchain innovation. Together, let's push the boundaries of possibility and sculpt a brighter tomorrow, one block at a time. Connect with us now to embark on this exhilarating adventure.</p>
                    </div>
      </div>
    </div>
      </div>
 </section>

    </div>
    </Layout>
  )
}

export default BlockchainCunsalting;
