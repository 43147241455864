import React, { useState } from 'react'
import '../header/navbar.css'
import { Link } from 'react-router-dom';

const Navbar = () => {

  const [isStarred, setIsStarred] = useState(false);

  const toggleIcon = () => {
    setIsStarred(prevState => !prevState);
  }


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-cus">
        <div className="container">
          <Link className="navbar-brand cus-logo" to="/">
            <img src="./Assets/logonew.png" className='img-fluid' alt="logo" />
          </Link>
          <button className="navbar-toggler border-0 shadow-none togle-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleIcon}>

            {isStarred ? <i class="fa-solid fa-x"></i> : <i class="fa-solid fa-bars"></i>

            }

          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {/* blockchain */}
              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Blockchain
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <li >
                    <ul>
                      <li><Link className="dropdown-item" to="/PrivateBlockChainDevelopment"> BlockChain
                        Development</Link></li>

                      <p className='megamenu-heading'>Smart Contracts</p>

                      <li><Link className="dropdown-item" to="/Smart Contract Audit">Smart Contract Audit</Link></li>
                      <li><Link className="dropdown-item" to="/Smartcontract-Dev">Smart
                        Contract Development</Link></li>

                    </ul>
                  </li>
                  <li>

                    <ul>
                      <li><Link className="dropdown-item" to="/Dapp-Development">Dapp Development</Link></li>
                      <li><Link className="dropdown-item" to="/Decentralised-Exchange">Decentralized Exchange Development</Link></li>
                      <li><Link className="dropdown-item" to="/Decentralised-whitelabel">White Label
                        Decentralized Exchange Development</Link></li>
                      <li><Link className="dropdown-item" to="/DStorage">Decentralized Storage</Link></li>
                      <li><Link className="dropdown-item" to="/Cloud-Services">Cloud
                        Services</Link></li>

                    </ul>
                  </li>


                </ul>
              </li>
              {/* blockchain end */}

              {/* metaverse */}
              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Metaverse
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <li >
                    <ul>
                      <li><Link className="dropdown-item"
                        to="/Metaverse Development Service">Metaverse Development Services</Link>
                      </li>
                      {/* <li><a className="dropdown-item" href="metaverse-game-devlopment.html">Metaverse Game
                Development</a></li>
            <li><a className="dropdown-item" href="metaverse-event-management.html">Metaverse Event Management
                Development</a></li>
            <li><a className="dropdown-item" href="metaverse-real-estate.html">Metaverse Real Estate
                Platform Development</a></li>
            <li><a className="dropdown-item" href="metaverse-social-media-platform.html">Metaverse Social Media Platform
                Development</a></li>
            <li><a className="dropdown-item" href="Metaverse-Entertainment-Services-Development.html">Metaverse
                Entertainment Services Development</a></li>
            <li><a className="dropdown-item" href="metaverse-nft-marketplace.html">Metaverse NFT Marketplace
                Development</a></li>
            <li><a className="dropdown-item" href="metaverse-e-commerce.html">Metaverse E-Commerce
                Platform Development</a></li>
            <li><a className="dropdown-item" href="metaverse-tourism.html">Metaverse Tourism Platform Development</a></li> */}
                    </ul>
                  </li>




                </ul>
              </li>
              {/* nft */}
              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  NFT
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <li >
                    <ul>

                      <p className='megamenu-heading'> NFT Game Development</p>
                      <li><Link className="dropdown-item" to="/NFT Development">NFT Development</Link></li>
                      {/* <li><hr class="dropdown-divider"></li> */}
                      {/* <li><a className="dropdown-item" href="nft-game-devlopment.html">NFT Game Development</a></li>
                <li><a className="dropdown-item" href="NFT-clone-game.html">NFT Clone games</a></li> */}

                      <p className='megamenu-heading'> NFT Marketplace Development</p>
                      <li><Link className="dropdown-item" to="/Nft-marketplace"> NFT Marketplace Development</Link>
                      </li>
                      <li><Link className="dropdown-item" to="/Nft-Clone">NFT Marketplace Clone</Link></li>
                      {/* <li><a className="dropdown-item" href="White-label-NFT-Marketplace-development.html">White lable NFT marketplace Development</a></li> */}
                    </ul>
                  </li>
                  <li>


                    <ul>
                      <li><Link className="dropdown-item" to="/Nft-Token">NFT Token
                        Development (ERC 721)</Link></li>
                      <li><Link className="dropdown-item" to="/Nft-Minting">NFT Minting Development</Link></li>
                      <li><Link className="dropdown-item" to="/Nft-Launchpad">NFT Launchpad
                        Development</Link></li>
                      <li><Link className="dropdown-item" to="/Nft-Lending-Platform">NFT
                        Lending Platform Development</Link></li>
                      {/* <li><a className="dropdown-item" href="SFT-devlopment.html">SFT Development (ERC 1155)</a></li>
            <li><a className="dropdown-item" href="NFT-Marketplace-support-maintenance.html">Marketplace Support
                &amp; Maintenance</a></li> */}
                    </ul>
                  </li>


                </ul>
              </li>
              {/* crypto */}

              {/* <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Crypto
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
            <li >
               <ul>
               <li><a className="dropdown-item" href="crypto-wallet.html">Cryptocurrency Wallet Development
                (Decentralized)</a></li>
            <li><a className="dropdown-item" href="crypto-exchange-platform.html">Cryptocurrency Exchange
                Platform Development</a></li>
            <li><a className="dropdown-item" href="WHITE-LABEL-CRYPTO-CURRENCY-EXCHANGE-PLATFORM-DEVELOPMENT.html">White Label Cryptocurrency Exchange Platform Development</a></li>
            <li><a className="dropdown-item" href="cryptocurrency-token-devlopment.html">Cryptocurrency Token
                Development</a></li>
            <li><a className="dropdown-item" href="p2p-crypto.html">P2P Cryptocurrency Exchange Platform Development</a></li>
            <li><a className="dropdown-item" href="CENTRALIZED-crypto-exchange.html">Centralized
                Cryptocurrency Exchange Platform Development</a></li>
            <li><a className="dropdown-item" href="crypto-crowdfunding.html"> Crypto Crowdfunding and Fund Raising
                Platform Development</a></li>
               </ul>
            </li>
          
            
          
         
          </ul>
        </li> */}
              {/* web 30 */}

              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Web 3.0
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <ul> <li><Link className="dropdown-item" to="/Web 3.0 Development">Web 3.0 Development</Link></li>
                    {/* <li><a className="dropdown-item" href="web3-game-devlopment.html">Web 3.0 Game Development</a></li> */}
                  </ul>




                </ul>
              </li>

              {/* DEFI */}

              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Defi
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <ul>
                    <li><Link className="dropdown-item" to="/Defi Development">DeFi Development</Link></li>
                    <li><Link className="dropdown-item" to="/Defi-Wallet">DeFi Crypto Wallet
                      Development</Link></li>
                    <li><Link className="dropdown-item" to="/Defi-Staking">DeFi Staking Platform Development</Link></li>
                    <li><Link className="dropdown-item" to="/Defi-Farming">DeFi yield Farming
                      Platform Development</Link></li>
                    <li><Link className="dropdown-item" to="/Defi-Crowdfunding">DeFi Crowdfunding Platform Development</Link></li>
                    <li><Link className="dropdown-item" to="/Defi-Lending">DeFi Lending and
                      Borrowing</Link></li>

                  </ul>




                </ul>
              </li>

              {/* ai */}

              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  AI
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <ul>
                    <li><Link className="dropdown-item" to="/AI Development">AI Development</Link></li>
                    <li><Link className="dropdown-item" to="/AIAnalysis"> AI Analysis</Link></li>
                    <li><Link className="dropdown-item" to="/AI-ProductMarketing">AI Product Marketing</Link></li>
                    <li><Link className="dropdown-item" to="/AI-Engineer">AI Prompt Engineering</Link></li>
                    <li><Link className="dropdown-item" to="/ChatGPT-Application-Development">ChatGPT Application Development
                    </Link></li>
                    <li><Link class="dropdown-item" to="/Chatgpt-integration"> ChatGPT Integration Services </Link>
                    </li>
                    <li><Link class="dropdown-item" to="/Generative-Ai-Solution"> Generative AI Solutions </Link></li>

                  </ul>
                </ul>
              </li>
              <li className="">
              <Link className="nav-link cus-link" to="/3D-Design" id="navbarDropdown" role="button">
                3D Design
              </Link>
              </li>

              {/* 

        <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
 3D Design
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
           <ul>
           <li><Link className="dropdown-item" to="/AI Development">AI Development</Link></li>
              <li><Link className="dropdown-item" to="/AIAnalysis"> AI Analysis</Link></li>
              <li><Link className="dropdown-item" to="/AI-ProductMarketing">AI Product Marketing</Link></li>
              <li><Link className="dropdown-item" to="/AI-Engineer">AI Engineering</Link></li>
              <li><Link className="dropdown-item" to="/ChatGPT-Application-Development">ChatGPT Application Development
                </Link></li>
              <li><Link class="dropdown-item" to="/Chatgpt-integration"> ChatGPT Integration Services </Link>
              </li>
              <li><Link class="dropdown-item" to="/Generative-Ai-Solution"> Generative AI Solutions </Link></li>
           
           </ul>
          </ul>
        </li> */}




              {/* CONTACT-US */}
              {/* ai */}

              <li className="nav-item dropdown">
                <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Contact
                </a>
                <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
                  <ul>
                    <li><Link className="dropdown-item" to="/Contact">Contact Us</Link></li>
                    {/* <li><Link className="dropdown-item"to="/blog">Blog</Link></li> */}

                    {/* <li><a className="dropdown-item" href="news.html">Newsroom</a></li>
            <li><a className="dropdown-item" href>
                Privacy Policy</a></li>
            <li><a className="dropdown-item" href>Terms &amp; Conditions
              </a></li> */}

                  </ul>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar;
