import React from 'react'
import Layout from '../Layout/Layout'
import '../Nft-Minting/Minting.css'

function Minting() {
  return (
    <Layout>
    <section className="banner4">
   
   <div className="container">
     <div className="row ">
       <div className="col-12 col-md-6">
         <div className="banner-caption2">
           <h1>NFT Minting Platform 
           </h1>
           <p>At Blockcoaster, we help you create, mint, and launch your NFTs effortlessly. Our NFT minting platform enables artists, businesses, and individuals to easily turn their creations into non-fungible tokens (NFTs) and capitalize on the rapidly growing blockchain industry. 


           </p>
         </div>
       </div>
   
       <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/NftMinting/minting1.png" className='img-fluid' alt="" />
   
         </div>
       </div>
     </div>
   </div>
   </section>
   
   <section className="bgright">
   
   <div className="container">
     <div className="row ">
     <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/NftMinting/2.png" className='img-fluid' alt="" />
   
         </div>
       </div>
   
   
       <div className="col-12 col-md-6">
         <div className="banner-caption2">
           <h1>Why Minting NFTs is a Better Investment Option

   </h1>
           <p>NFTs have revolutionized the digital asset space, allowing creators and collectors to establish ownership of unique digital items. By minting your NFT, you create a traceable, one-of-a-kind digital asset on the blockchain. The decentralized nature of NFTs ensures transparency and immutability, making them a long-term investment opportunity. Moreover, with the growing demand for digital assets like art, music, and gaming items, NFTs offer significant potential for future resale or value appreciation.


   
           </p>
         </div>
       </div>
   
      
     </div>
   </div>
   </section>
   
   {/* 2 */}
   
   
   <section className='bgleft'>
             <div className='container'>
             <div className='row'>
                  <div className='col-12'>
                  <div class="heading-box">
                       <h3> Features of Our NFT Minting Platform


   </h3>
   {/* <div class="line3"></div> */}
                       {/* <div class="line2"></div> */}
                     </div>   
                  </div>
             </div>
       
        <div className='row'>
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/NftMinting/11.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Multi-Blockchain Support
                         </h3>
                      </div>
   
                      <p>Mint NFTs across leading blockchain networks, including Ethereum, Binance Smart Chain, and Polygon, providing flexibility and choice based on your needs.


   
                      </p>
                </div>
            </div>
   
            {/* 2 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                <img src="./Assets/NftMinting/22.png" className='img-fluid' alt="" />
                      </div>
                         <h3>User-Friendly Interface
                         </h3>
                      </div>
   
                      <p>Our intuitive platform allows users to easily upload digital files and mint NFTs without any coding knowledge.
                      </p>
                </div>
            </div>
   
            {/* 3 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/NftMinting/3.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Gas Fee Optimization
                         </h3>
                      </div>
   
                      <p>We ensure efficient gas fee management through advanced algorithms, making minting affordable for all users.


   
                      </p>
                </div>
            </div>
   
            {/* 4 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/NftMinting/4.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Customization Options
                         </h3>
                      </div>
   
                      <p>Personalize your NFTs with metadata such as unique attributes, rarity levels, and unlockable content.


   
   
                      </p>
                </div>
            </div>
   
            {/* 5 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/NftMinting/5.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Security & Ownership
                         </h3>
                      </div>
   
                      <p>Each NFT minted on our platform is stored securely on the blockchain, guaranteeing immutable ownership and preventing duplication or forgery.
                      </p>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/NftMinting/6.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Integrated NFT Marketplace
                         </h3>
                      </div>
   
                      <p>Instantly list your NFTs for sale on leading marketplaces or our in-built marketplace once minted, increasing visibility and sales potential.


                      </p>
                </div>
            </div>

         
   
        </div>
             </div>
         </section>
   
   
        
         {/* 3 */}
   
       
         <section className='bgleft'>
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <div className="heading-box-sec text-center py-4">
                                   <h3>The NFT Minting Process
                                   </h3>
                               </div>
                           </div>
                       </div>
                       <div className="row">
                          
                           {/* 2 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardM">
                                   <img src="./Assets/NftMinting/7.png" alt="" />
                                   <h2>Sign Up and Connect Wallet</h2>
                                   <p>Begin by creating an account on our platform and connecting your cryptocurrency wallet to ensure secure transactions.


   
                                   </p>
                               </div>
                           </div>
                           {/* 3 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardM">
                                   <img src="./Assets/NftMinting/8.png" alt="" />
                                   <h2>Upload Digital Assets
                                   </h2>
                                   <p>Choose the digital file you want to mint, whether it's an image, video, audio, or document. You can also upload metadata like name, description, and rarity details.


                                   </p>
                               </div>
                           </div>
                           {/* 4 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardM">
                                   <img src="./Assets/NftMinting/9.png" alt="" />
                                   <h2>Select Blockchain & Pay Minting Fees
                                   </h2>
                                   <p> Choose from multiple blockchain options, such as Ethereum, Polygon, or Binance Smart Chain, and pay the minting fee.

                                   </p>
                               </div>
                           </div>
                           {/* 5 */}
                           <div className="col-md-4 col-12">
                               <div className="metacardM">
                                   <img src="./Assets/NftMinting/10.png" alt="" />
                                   <h2>Mint Your NFT
                                   </h2>
                                   <p>Once the fee is paid, the minting process begins. Your file is tokenized on the blockchain as an NFT with a unique identifier.


                                   </p>
                               </div>
                           </div>
                           <div className="col-md-4 col-12">
                               <div className="metacardM">
                                   <img src="./Assets/NftMinting/111.png" alt="" />

                                   <h2>List on Marketplaces
                                   </h2>
                                   <p>You can instantly list your NFT on popular marketplaces like OpenSea, or use our integrated platform to start selling.


                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
   
               {/* 4 */}
               <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3> Business Benefits of NFT Minting


                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/NftMinting/12.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Monetization Opportunities</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> NFTs allow artists, creators, and businesses to directly monetize their digital assets with complete ownership control.


                                </p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/NftMinting/13.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Global Reach</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> Once minted, NFTs can be listed on global marketplaces, reaching a worldwide audience and potential buyers.


                                </p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/NftMinting/14.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Increased Brand Visibility</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>NFT creation enhances brand visibility in the digital space, particularly within the fast-growing crypto community.


                                </p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/NftMinting/15.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Fractional Ownership</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>With the possibility of fractional NFT ownership, businesses can cater to smaller investors, offering more versatile sales options.


                                </p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/NftMinting/17.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Royalties on Resale</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> Our platform supports smart contract-based royalties, enabling creators to earn on every future sale of their NFTs.
                                </p>

                            </div>
                        </div>

                        {/* 6 */}
                       


                    </div>

                </div>
            </section>        






               <section className='bgright'>
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <div className="heading-box-sec text-center py-2">
                                   <h3> Why Choose Blockcoaster for NFT Minting?

                                   </h3>
                               </div>
                           </div>
                       </div>
                       <div className="row">
                     
                           <div className="col-12 col-md-8">
                               <div className="caption-list mt-md-5">
                      <p><strong>End-to-End Solutions:</strong> We offer a complete suite of services, from NFT creation and minting to marketplace integration and marketing.</p>
                        <p><strong>Blockchain Expertise:</strong> Our team comprises blockchain developers with in-depth knowledge of NFT standards and smart contract development, ensuring flawless execution.
                        </p>
                        <p><strong>Seamless User Experience:</strong> Whether you're new to NFTs or an experienced creator, our platform provides a user-friendly interface with easy-to-navigate features.
                        </p>
                        <p><strong>Security First Approach:</strong> Your NFTs and transactions are secured with top-tier blockchain technology and robust encryption protocols, ensuring the highest level of security.
                        </p>
                        <p><strong>Affordable & Efficient:</strong> With gas fee optimization, minting NFTs on Blockcoaster’s platform is cost-effective, offering value without compromising on speed or quality.
                        </p>





                        
                                       {/* 2 */}
   
                                       {/* 3 */}
                                       {/* 4 */}
                               </div>
                           </div>
                           <div className="col-12 col-md-4 text-center">
                         <div className="qstimg">
                            <img src="./Assets/NftMinting/minting2.png" className='img-fluid' alt="metaverse" />
                           
                      
                      
                         </div>
                           </div>
                       </div>
                   </div>
               </section>
   
   
               
       </Layout>
  )
}

export default Minting
