import React, { useState } from 'react'
 import '../header/navbar.css'
import { Link } from 'react-router-dom';

const Navbar = () => {

  const [isStarred, setIsStarred] = useState(false);

  const toggleIcon = ()=>{
    setIsStarred(prevState =>!prevState);
  }
  

  return (
    <>
<nav className="navbar navbar-expand-lg navbar-cus">
  <div className="container">
    <Link className="navbar-brand cus-logo" to="/">
        <img src="./Assets/logonew.png" className='img-fluid' alt="logo" />
    </Link>
    <button className="navbar-toggler border-0 shadow-none togle-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleIcon}>

    {isStarred? <i class="fa-solid fa-x"></i> :<i class="fa-solid fa-bars"></i>
    
  }

    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
         {/* blockchain */}
        <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Blockchain 
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
            <li >
               <ul>
               <li><Link className="dropdown-item" to="/blockchain-development-company"> Block Chain
               Development</Link></li>
          
              <p className='megamenu-heading'>Smart Contracts</p>
              <li><Link className="dropdown-item" to="/smart-contract-development">Smart
                    Contract Development</Link></li>
   
                <li><Link className="dropdown-item" to="/smart-contract-audit">Smart Contract Audit</Link></li>
                <li><Link className="dropdown-item" to="/cross-chain-bridge">Cross Chain Bridge</Link></li>

               </ul>
            </li>
            <li>
            
            <ul>
            <li><Link className="dropdown-item" to="/dapp-development">Dapp Development</Link></li>
           
                <li><Link className="dropdown-item" to="/decentralized-storage-solutions">Decentralized Storage</Link></li>
                <li><Link className="dropdown-item" to="/cloud-services">Cloud
                    Services</Link></li>

               </ul>
            </li>
       
         
          </ul>
        </li>
        {/* blockchain end */}

         {/* metaverse */}
        <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Metaverse
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
            <li >
               <ul>
               <li><Link className="dropdown-item" 
               to="/metaverse-development-service">Metaverse Development Services</Link>
               </li>
               <li><Link className="dropdown-item" 
               to="/metaverse-application-development">Metaverse Application Development Services</Link>
               </li>
            {/* <li><a className="dropdown-item" href="metaverse-game-devlopment">Metaverse Game
                Development</a></li> */}
          
            <li><Link className="dropdown-item" to="/metaverse-real-estate-platform-development">Metaverse Real Estate
                Platform Development</Link></li>
            <li><Link className="dropdown-item" to="/metaverse-healthcare-solution">Metaverse Healthcare Solutions</Link></li>
            <li><Link className="dropdown-item" to="/metaverse-avatar-development">Metaverse Avatar Development Services 
            </Link></li>
            <li><Link className="dropdown-item" to="/metaverse-virtual">Metaverse Virtual Working Space Solutions
            </Link></li>
            <li><Link className="dropdown-item" to="/metaverse-event-platform-development">Metaverse Virtual Event Platform Development Services

            </Link></li>
            <li><Link className="dropdown-item" to="/meta-nft-marketplace-development">Metaverse NFT Marketplace
                Development</Link></li>
            <li><Link className="dropdown-item" to="/metaverse-ecommerce-platform-development">Metaverse E-Commerce
                Platform Development</Link></li>
               </ul>
            </li>
          
            
          
         
          </ul>
        </li>
        {/* nft */}
        <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        NFT
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
            <li >
               <ul>
      
              <p className='megamenu-heading'> NFT Game Development</p>
              <li><Link className="dropdown-item" to="/nft-development">NFT Development</Link></li>
                {/* <li><hr class="dropdown-divider"></li> */}
                {/* <li><a className="dropdown-item" href="nft-game-devlopment">NFT Game Development</a></li> */}
                {/* <li><a className="dropdown-item" href="NFT-clone-game">NFT Clone games</a></li> */}
                 
                <p className='megamenu-heading'> NFT Marketplace Development</p>
                <li><Link className="dropdown-item" to="/nft-marketplace-development"> NFT Marketplace Development</Link>
                </li>
                <li><Link className="dropdown-item" to="/nft-marketplace-clone">NFT Marketplace Clone</Link></li>
                <li><Link className="dropdown-item" to="/whitelabel-market-nft-marketplace-development">White lable NFT marketplace Development</Link></li>
               </ul>
            </li>
            <li>
            
           
            <ul>
           <li><Link className="dropdown-item" to="/nft-token-development">NFT Token
                Development (ERC 721)</Link></li>
            <li><Link className="dropdown-item" to="/nft-minting-platform-development">NFT Minting Development</Link></li>
            <li><Link className="dropdown-item" to="/nft-launchpad-development-service">NFT Launchpad
                Development</Link></li>
            <li><Link className="dropdown-item" to="/nft-lending-platform-development">NFT
                Lending Platform Development</Link></li>
            <li><Link className="dropdown-item" to="/semi-funglibe-tokens-development">SFT Development (ERC 1155)</Link></li>
            {/* <li><a className="dropdown-item" href="NFT-Marketplace-support-maintenance">Marketplace Support
                &amp; Maintenance</a></li> */}
               </ul>
            </li>
       
         
          </ul>
        </li>
          {/* crypto */}
        
          <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Crypto
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
            <li >
               <ul>
               <li><Link className="dropdown-item" to="/cryptocurrency-wallet-development">Cryptocurrency Wallet Development
                (Decentralized)</Link></li>
            <li><Link className="dropdown-item" to="/crypto-exchange-platform-development">Cryptocurrency Exchange
                Platform Development</Link></li>
                <li><Link className="dropdown-item" to="/decentralised-exchange-development-company">Decentralized Exchange Development</Link></li>
                <li><Link className="dropdown-item" to="/decentralised-whitelabel-platform-development-company">White Label
                    Decentralized Exchange Development</Link></li>
                    <li><Link className="dropdown-item" to="/Crypto-Token-Development">Cryptocurrency Token
                Development</Link></li>
                <li><Link className="dropdown-item" to="/Memecoin-Development">Meme Coin Development</Link></li>

            {/* <li><a className="dropdown-item" href="WHITE-LABEL-CRYPTO-CURRENCY-EXCHANGE-PLATFORM-DEVELOPMENT">White Label Cryptocurrency Exchange Platform Development</a></li>
            <li><a className="dropdown-item" href="cryptocurrency-token-devlopment">Cryptocurrency Token
                Development</a></li> */}
            <li><Link className="dropdown-item" to="/p2p-crypto-exchange-development-company">P2P Cryptocurrency Exchange Platform Development</Link></li>
            <li><Link className="dropdown-item" to="/centralised-exchange-development-company">Centralized
                Cryptocurrency Exchange Platform Development</Link></li>
            {/* <li><a className="dropdown-item" href="crypto-crowdfunding"> Crypto Crowdfunding and Fund Raising
                Platform Development</a></li> */}
              <li><Link className="dropdown-item" to="/cryptocurrency-trading-bot-development">Cryptocurrency Trading Bot Development</Link></li>
           <li><Link className="dropdown-item" to="/cryptocurrency-arbitrage-bot-development-company">Cryptocurrency Arbitrage Bot Development Company</Link></li>

               </ul>
            </li>
         
          </ul>
        </li>
         {/* web 30 */}

         <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
         Web 3.0
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
           <ul> <li><Link className="dropdown-item" to="/web-3.0-development-company">Web 3.0 Development</Link></li>
           <li><Link className="dropdown-item" to="/web3-game-development">Web 3.0 Game Development</Link></li>
           </ul>
          
            
          
         
          </ul>
        </li>

        {/* DEFI */}
        
        <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Defi
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
           <ul>
           <li><Link className="dropdown-item" to="/defi-development-company">DeFi Development</Link></li>
            <li><Link className="dropdown-item" to="/defi-wallet-development">DeFi Crypto Wallet
                Development</Link></li>
            <li><Link className="dropdown-item" to="/defi-staking-platform-development">DeFi Staking Platform Development</Link></li>
            <li><Link className="dropdown-item" to="/defi-yield-farming-development">DeFi Yield Farming
                Platform Development</Link></li>
            <li><Link className="dropdown-item" to="/defi-crowdfunding-platform-development">DeFi Crowdfunding Platform Development</Link></li>
            <li><Link className="dropdown-item" to="/defi-lending-and-borrowing-platform-development">DeFi Lending and
                Borrowing</Link></li>
           
           </ul>
          
            
          
         
          </ul>
        </li>

          {/* ai */}
        
          <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    AI
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
           <ul>
           <li><Link className="dropdown-item" to="/ai-development-company">AI Development</Link></li>
              <li><Link className="dropdown-item" to="/ai-analysis"> AI Analysis</Link></li>
              <li><Link className="dropdown-item" to="/ai-product-marketing">AI Product Marketing</Link></li>
              <li><Link className="dropdown-item" to="/ai-engineering">AI Prompt Engineering</Link></li>
              <li><Link className="dropdown-item" to="/chatgpt-application-development">ChatGPT Application Development
                </Link></li>
              <li><Link class="dropdown-item" to="/chatgpt-integration-service"> ChatGPT Integration Services </Link>
              </li>
              <li><Link class="dropdown-item" to="/generative-ai-solution"> Generative AI Solutions </Link></li>
           
           </ul>
          </ul>
        </li>




        <li className="">
      <Link className="nav-link cus-link" to="/3d-design" id="navbarDropdown" role="button">
        3D Design
      </Link>
    </li>




         {/* CONTACT-US */}
             {/* ai */}
        
             <li className="nav-item dropdown">
          <a className="nav-link cus-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Contact
          </a>
          <ul className="dropdown-menu dropdown-menu-cus " aria-labelledby="navbarDropdown">
           <ul>
           <li><Link className="dropdown-item" to="/contact">Contact Us</Link></li>
            <li><Link className="dropdown-item"to="/blog">Blog</Link></li>
            {/* <li><Link className="dropdown-item" to="/BlogDetails">BlogDetail</Link></li> */}

            {/* <li><a className="dropdown-item" href="news.html">Newsroom</a></li>
            <li><a className="dropdown-item" href>
                Privacy Policy</a></li>
            <li><a className="dropdown-item" href>Terms &amp; Conditions
              </a></li> */}
           
           </ul>
          </ul>
        </li>
      </ul>
      
 

   </div>
  </div>
 </nav>



    </>
  )
}

export default Navbar;
