import React from 'react'
import Layout from '../Layout/Layout'
import '../Sft-development/Sft.css'
import { Link } from 'react-router-dom';
const Sft = () => {
  return (
    <Layout>
    <section className="banner4">
   
   <div className="container">
     <div className="row ">
       <div className="col-12 col-md-6">
         <div className="banner-caption2S">
           <h1>Semi-funglibe Tokens Development Solutions 
           </h1>
           <p>At Blockcoaster, we specialize in creating Semi-Fungible Token (SFT) development solutions that combine the advantages of fungible and non-fungible tokens. Our tailored platforms empower businesses to harness the full potential of blockchain technology while ensuring flexible asset management and transaction security.
           </p>
           <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  
         </div>
       </div>
   
       <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/sft/1.png" className='img-fluid' alt="" />
   
         </div>
       </div>
     </div>
   </div>
   </section>
   
   <section className="bgright">
   
   <div className="container">
     <div className="row ">
     <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/sft/2.png" className='img-fluid' alt="" />
         </div>
       </div>
   
   
       <div className="col-12 col-md-6">
         <div className="banner-caption2">
           <h1>Understanding Our SFT Development Platform
   </h1>
           <p>Our SFT development platform is designed for businesses looking to implement semi-fungible tokens on the blockchain. These tokens can operate like fungible tokens initially and later transition to non-fungible tokens, providing unique value propositions for various applications. Our expert team guides clients through every step of the development process, from initial concept to deployment, ensuring a tailored solution that meets your specific needs.
           </p>
         </div>
       </div>
     </div>
   </div>
   </section>
   

   <section className="bgleft">
   
   <div className="container">
     <div className="row ">
    
   
   
       <div className="col-12 col-md-6">
         <div className="banner-caption2">
           <h1>What Are Semi-Fungible Tokens (SFTs)?
   </h1>
           <p>Semi-Fungible Tokens (SFTs) are a unique class of digital assets that retain the properties of both fungible and non-fungible tokens. Initially, SFTs function as fungible tokens, allowing for easy interchangeability. However, they can later transform into unique non-fungible tokens when certain conditions are met (e.g., when a specific item is redeemed or upgraded). This flexibility makes SFTs ideal for applications in gaming, collectibles, and various financial models.
           </p>
         </div>
       </div>
       <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/sft/3.png" className='img-fluid' alt="" />
   
         </div>
       </div>
      
     </div>
   </div>
   </section>
   {/* 2 */}
   
   <section className='bgright'>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-box-sec text-center py-4">
              <h3>Key Uses of SFTs

              </h3>
              <div className='line3'></div>

            </div>


          </div>
        </div>

        <div className='row'>
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/sft/1s.png" alt="" />
              </div>
              <h3>Gaming
              </h3>

              <p>Represent in-game items or currencies that can be traded and later converted into unique collectibles.

              </p>
            </div>
          </div>
          {/* 2 */}  <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/sft/2s.png" alt="" />
              </div>
              <h3>Event Ticketing</h3>
              <p>Issue tickets as fungible tokens that can later represent individual, unique entry passes for specific events.
              </p>
            </div>
          </div>

          {/* 3 */}
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/sft/3s.png" alt="" />
              </div>
              <h3>Loyalty Programs</h3>
              <p>Create points that function as semi-fungible, allowing users to exchange them for rewards or unique items.
              </p>
            </div>
          </div>
          {/* 4 */}
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/sft/4.png" alt="" />
              </div>
              <h3>Digital Collectibles
              </h3>
              <p>Transition collectibles from a fungible state into unique NFTs as they gain value or rarity.

              </p>
            </div>
          </div>
          {/* 5 */}
   \
          {/* 6 */}
  
          {/* 7 */}
    
          {/* 8 */}
          
        </div>
      </div>
    </section>
    
         {/* 3 */}
         <section className='bgleft'>
         <div className='container-fluid'>
          <div className='row'>
               <div className='col-12'>
               <div class="heading-box">
                    <h3>Benefits of SFT Development
                    </h3>
                    {/* <div class="line2"></div> */}
                  </div>    
               </div>
          </div>
          <div className='row justify-content-center'>
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/21.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Flexibility
                          </h3>
                         <p>SFTs adapt to various use cases, transitioning between fungible and non-fungible states as needed.


                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 2 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/sft/5.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Enhanced User Experience
                          </h3>
                         <p>Users can seamlessly trade and upgrade assets, enhancing engagement and satisfaction.

                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
            

                {/* 3 */}
                <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/sft/6.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Increased Liquidity
                          </h3>
                         <p>The fungible nature of SFTs promotes easier trading in initial phases while allowing for unique item creation later.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 4 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/24.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Cost-Effectiveness
                          </h3>
                         <p>Reduces overhead costs associated with managing separate token types while providing diverse functionality.


                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 5 */}
 
               {/* 8 */}
              

               
               {/* 9 */}
         
          </div>
         </div>

        </section>
       
   
               {/* 4 */}
         





               <section className='bgright'>
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <div className="heading-box-sec text-center py-2">
                                   <h3> Why Choose Blockcoaster for SFTs?
                                   </h3>
                               </div>
                           </div>
                       </div>
                       <div className="row">
                     
                           <div className="col-12 col-md-8">
                               <div className="caption-list mt-md-5">
                      <p>Choosing Blockcoaster for your SFT development needs means partnering with a team of experienced blockchain developers and consultants dedicated to delivering tailored solutions. Our commitment to innovation, security, and customer satisfaction ensures that your SFT project is not only successful but also poised for future growth. With our expertise, you’ll navigate the complexities of blockchain technology with ease, unlocking new opportunities for your business.

                        </p>    
                                       {/* 2 */}
   
                                       {/* 3 */}
                                       {/* 4 */}
                               </div>
                           </div>
                           <div className="col-12 col-md-4 text-center">
                         <div className="qstimg">
                            <img src="./Assets/sft/1ss.png" className='img-fluid' alt="metaverse" />
                         </div>
                           </div>
                       </div>
                   </div>
               </section>
   
   
               
       </Layout>
  )
}

export default Sft
