import React from 'react'
import Layout from '../Layout/Layout'
import '../Defi-Lending/DLending.css'
import { Link } from 'react-router-dom';
function DLending() {
  return (
    <Layout>
          <section className="banner">
        <h1> DeFi Lending and Borrowing Platform Development</h1>
        <p className="mt-25">Blockcoaster’s DeFi lending and borrowing platform development solutions allow users to lend and borrow cryptocurrency assets without intermediaries. By leveraging smart contracts and decentralized protocols, we create platforms that enable secure, automated, and transparent financial transactions, where lenders earn interest on their deposits, and borrowers can access liquidity without traditional financial constraints.
        </p>
        <Link to="/Contact" className="rounded-button-link" >
  <button className="rounded-buttonII">Connect With Our Experts</button>
</Link> 
          
            
    </section>


    <div className="bgdark">
        <div className="bg-gradient"></div>

        <div className="container">
            <div className="about-block">
                <div className="block-1">
                    <div className="feature-thumb">
                        <img className="feature-thumb alltuchtopdown" src="Assets/Loan/8.png" alt="About Blockcoaster"/>
                    </div>
                </div>
                <div className="block-2">
                    <div className="section-title mb-75">
                        <h2 className="title style2">What is DeFi Lending and Borrowing?
                        </h2>
                        <p className="mt-25">DeFi lending and borrowing platforms enable users to lend their crypto assets or borrow against their holdings in a decentralized environment. The entire process is managed by smart contracts, ensuring that loans are secure, interest rates are determined by market dynamics, and collateral is protected. This peer-to-peer lending model eliminates the need for intermediaries like banks, offering users more control over their assets and greater financial freedom.
                        </p>
                        <p className="mt-25">Through decentralized lending, users can earn passive income, while borrowers can access liquidity without having to sell their assets, offering a more flexible financial system.
                        </p>
                    </div>
                    {/* <div className="about-grid-wrap">
                        <div className="about-wrap">
                            <div className="about-card">
                                <h3 className="about-card_number">63M</h3>
                                <p className="about-card_text">Blockchain users</p>
                            </div>
                        </div>
                        <div className="about-wrap">
                            <div className="about-card">
                                <h3 className="about-card_number">24%</h3>
                                <p className="about-card_text">Companies use blockchain</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </div>
    </div>


    <div className="bgblue">
    <section className="container">
        
        <div className="decentralized-storage">
            <img className="feature-img floating-img" src="Assets/Loan/7.png" alt="img"/>
            <div className="feature-wrap-4">
                <div className="section-title mb-0">
                    <span className="sub-title text-title"> DeFi Lending and Borrowing Platforms Continue to Disrupt Traditional Finance
</span>
                    <p className="text-title mt-20 mb-55">The growing adoption of DeFi lending and borrowing platforms is driving significant disruption in traditional financial services. With billions of dollars locked in DeFi protocols like Aave and Compound, decentralized lending is becoming a mainstream alternative to bank loans. More businesses and individuals are choosing to lend and borrow via DeFi, attracted by the benefits of transparency, global accessibility, and secure smart contracts.
                    </p>
                </div>
            </div>
        </div>
    </section>
    </div>

 
           
{/* 
    <div className="bgblue">
    <section className="container">
        <div className="decentralized-storage">
            <div className="row">
               
                <div className="col-sm-6">
                    <div>
                        <h3>Advantages of Decentralized Storage</h3>
                        <ul>
                            <li><b>Enhanced Security:</b> With encryption and decentralization, your data is safeguarded
                                against
                                cyber threats.
                            </li>
                            <li><b>Privacy-Focused:</b> No third-party intermediaries can access or control your data.</li>
                            <li><b>Scalability:</b> Effortlessly scale up your storage requirements as your business grows,
                                without
                                the
                                limitations of central servers.</li>
                            <li><b>Reduced Costs:</b> By leveraging a global network of nodes, decentralized storage often
                                results
                                in lower
                                storage costs compared to traditional providers.</li>
                            <li><b>Immutable Data Records:</b> Blockchain technology ensures the immutability of stored data,
                                offering
                                long-term integrity for records and files.</li>
                        </ul>
                    </div>
                </div>


                <div className="col-sm-6">
                    <div className="advantages-img text-center">
                        <img  src="Assets/blockchain/Decentralised/abt-2.png" alt="About Blockcoaster"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
 </div> */}

<section className='bgdark'>
         <div className='container-fluid'>
          <div className='row'>
               <div className='col-12'>
               <div class="heading-box">
                    <h3> DeFi Lending Process</h3>
                    {/* <div class="line2"></div> */}
                  </div>    
               </div>
          </div>
          <div className='row justify-content-center'>
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/Loan/21.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Deposit Assets
                          </h3>
                         <p>Lenders deposit their cryptocurrency into a liquidity pool, making their funds available for borrowing.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 2 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/Loan/22.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Activate Smart Contract
                          </h3>
                         <p>A smart contract is triggered to secure the assets and outline the lending terms, including interest rates.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
            

                {/* 3 */}
                <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/Loan/23.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Earn Interest
                          </h3>
                         <p>Lenders earn interest on their deposits based on market demand, with rates fluctuating according to supply and demand dynamics.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 4 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/Loan/24.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Withdraw Funds
                          </h3>
                         <p>Lenders can withdraw their assets and accrued interest whenever they choose, provided all terms are met.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

          </div>
         </div>

        </section>

        <section className='bgblue'>
          <div className='container-fluid'>
          <div className='row'>
               <div className='col-12'>
               <div class="heading-box">
                    <h3> DeFi Borrowing Process
                    </h3>
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
          </div>
          <div className='row justify-content-center'>
               <div className='col-12 col-md-5'>
                     <div className='cardL Industry-blockchainL'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                        <img src="./Assets/Loan/25.png" className='img-fluid' alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Provide Collateral
                          </h3>
                          <p> Borrowers deposit collateral, typically a cryptocurrency amount greater than the loan they wish to secure.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-5'>
                     <div className='cardL Industry-blockchainL'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                        <img src="./Assets/Loan/26.png" className='img-fluid' alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Smart Contract Engagement
                          </h3>
                          <p>The smart contract locks the collateral and issues the loan to the borrower.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-5'>
                     <div className='cardL Industry-blockchainL'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                        <img src="./Assets/Loan/27.png" className='img-fluid' alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Repay Loan
                          </h3>
                          <p>Borrowers repay the loan amount plus interest as specified in the smart contract.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-5'>
                     <div className='cardL Industry-blockchainL'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                        <img src="./Assets/Loan/28.png" className='img-fluid' alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Release Collateral
                          </h3>
                          <p>After full repayment, the smart contract releases the collateral back to the borrower.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
         
          </div>
          </div>
      </section>




    <section className='bgdark'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3> Benefits of DeFi Lending and Borrowing

                            </h3>
                          
                            </div>
                        </div>
                    </div>
                <div className='row mt-5  justify-content-center'>
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                      <div className='line-circle'>
                        <p>01</p>
                      </div>
                         <div className='s-line'></div>
                            {/* <div className='block-icon'>
                            <img src="./Assets/web30/Process/1.png" alt="" />
                            </div> */}
                          <h3>No Intermediaries
                          </h3>
                          <p>Skip traditional financial institutions and engage in direct peer-to-peer lending and borrowing.
                          </p>
                             


                     </div>
               </div>

               {/* 2 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>02</p>
                      </div>
                         <div className='s-line2'></div>
                           
                          <h3>Passive Income for Lenders
                          </h3>
                          <p>Lenders can earn interest on their crypto assets, creating new revenue streams.

                          </p>
                             


                     </div>
               </div>

               {/* 3 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>03</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Global Accessibility
                          </h3>
                          <p>DeFi lending platforms promote financial inclusion by being universally accessible online.

                          </p>
                             


                     </div>
               </div>
               {/* 4 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>04</p>
                      </div>
                         <div className='s-line2'></div>
                          
                          <h3>Flexible Loans
                          </h3>
                          <p>Borrowers can access liquidity without selling their assets, preserving their long-term investment strategies.

                          </p>
                             


                     </div>
               </div>
               {/* 5 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>05</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Security and Transparency
                          </h3>
                          <p>Smart contracts ensure that all transactions are secure, transparent, and automated.

                          </p>
                             


                     </div>
               </div>
               {/* 6 */}
            
        
          </div>   
                </div>

              </section>





 <section className='bgblue'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5">
                            <div className="sideimg">
                                <img src="./Assets/Loan/10.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="caption-list mt-md-5">
                                <h3 style={{color:"#fff", marginBottom:"30px"}}> Why Choose Blockcoaster for DeFi Lending and Borrowing Development?

                                </h3>
                                <p> <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Expert Development Team:</strong> We offer customized solutions tailored to your specific business needs, ensuring a secure and scalable DeFi lending and borrowing platform. </p>
                                <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong> Robust Security:</strong>Blockcoaster integrates advanced security protocols and smart contract audits to protect user assets and ensure platform integrity.
                                </p>
                                <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong> Seamless User Experience:</strong> Our platforms provide intuitive interfaces for both lenders and borrowers, ensuring ease of use and efficient financial transactions</p>
                                <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong> Global Integration:</strong> We build platforms that connect you to a global market, enabling lending and borrowing from users worldwide, with support for multiple cryptocurrencies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    





    </Layout>
  )
}

export default DLending
