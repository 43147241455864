// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .why-img2 img{
  
} */

.rounded-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #131212;
  background-color: #ece8eb; /* Button background color */
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.rounded-button:hover {
  background-color: #ca90f6; /* Background color on hover */
}
.why-img-banner img{
  width: 500px;
}

@media screen and (max-width:600px){
    .why-img-banner img{
        width:100%;
      }
      .metaoverlay-card img {
        width: 100% !important;
        height: 100% !important;
    
      }   
      .rounded-button {
        padding: 10px 20px;
        font-size: 16px;
        color: #0c0909;
        background-color: #f4e8f0; /* Button background color */
        border: none;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
       
      }
}`, "",{"version":3,"sources":["webpack://./src/components/AIDevelopment/aidevelopment.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,yBAAyB,EAAE,4BAA4B;EACvD,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,8BAA8B;AAC3D;AACA;EACE,YAAY;AACd;;AAEA;IACI;QACI,UAAU;MACZ;MACA;QACE,sBAAsB;QACtB,uBAAuB;;MAEzB;MACA;QACE,kBAAkB;QAClB,eAAe;QACf,cAAc;QACd,yBAAyB,EAAE,4BAA4B;QACvD,YAAY;QACZ,mBAAmB;QACnB,eAAe;QACf,sCAAsC;;MAExC;AACN","sourcesContent":["/* .why-img2 img{\n  \n} */\n\n.rounded-button {\n  padding: 10px 20px;\n  font-size: 16px;\n  color: #131212;\n  background-color: #ece8eb; /* Button background color */\n  border: none;\n  border-radius: 30px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  margin-top: 20px;\n}\n\n.rounded-button:hover {\n  background-color: #ca90f6; /* Background color on hover */\n}\n.why-img-banner img{\n  width: 500px;\n}\n\n@media screen and (max-width:600px){\n    .why-img-banner img{\n        width:100%;\n      }\n      .metaoverlay-card img {\n        width: 100% !important;\n        height: 100% !important;\n    \n      }   \n      .rounded-button {\n        padding: 10px 20px;\n        font-size: 16px;\n        color: #0c0909;\n        background-color: #f4e8f0; /* Button background color */\n        border: none;\n        border-radius: 10px;\n        cursor: pointer;\n        transition: background-color 0.3s ease;\n       \n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
