import React from 'react'
import Layout from '../Layout/Layout';
import '../Defi-Staking/Defi-Staking.css'

function DefiStaking() {
  return (
    <Layout>
         
    <section className="banner3">

<div className="container">
<div className="row ">
<div className="col-12 col-md-6">
 <div className="banner-caption2">
   <h1> <span className='block-clr'>DeFi Staking 
   </span> Platform Development</h1>
   <p>At Blockcoaster, we create tailored DeFi staking platforms that let you securely stake your crypto assets and maximize returns. Whether you’re an individual investor or a business, our solutions offer a seamless, high-performance experience that aligns with the fast-paced world of decentralized finance.

   </p>


 </div>
</div>

<div className="col-12 col-md-6 text-center">
 <div>
   <img src="./Assets/staking/1.png" className='img-fluid' alt="" />

 </div>
</div>
</div>
</div>
</section>


{/* 2 */}
<section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>What is DeFi Staking?
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>DeFi staking is revolutionizing how individuals and businesses earn passive income in the decentralized finance ecosystem. By locking your crypto assets in a decentralized protocol, you can earn staking rewards while contributing to the network’s security and efficiency. Whether you are staking popular coins like Ethereum or exploring new DeFi tokens, this mechanism opens the door to substantial financial gains with minimal effort.
                                </p>
                                <p>
                                As a key pillar of decentralized finance (DeFi), staking allows users to engage in crypto without needing advanced technical knowledge, making it a widely accessible solution within the decentralized finance community.

                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/staking/2.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
   <section className='bgright'>
    <div className='container-fluid'>
     <div className='row'>
          <div className='col-12'>
          <div class="heading-box">
               <h3>Our DeFi Staking Platform Development Services
               </h3>
               {/* <div class="line2"></div> */}
             </div>    
          </div>
     </div>
     <div className='row justify-content-center'>
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/staking/11.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Custom DeFi Staking Platforms
                     </h3>
                    <p> Design and develop secure and user-friendly staking platforms that support multiple cryptocurrencies.
                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          {/* 2 */}
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/staking/21.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Smart Contract Development
                     </h3>
                    <p>Build and audit robust smart contracts to automate staking rewards and ensure decentralized governance.</p>
               </div>
               </div>
               </div>
             </div>    
          </div>
       

           {/* 3 */}
           <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/staking/31.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Integration with DeFi Protocols
                     </h3>
                    <p>Seamless integration with leading DeFi protocols like Ethereum, Binance Smart Chain (BSC), and more.
                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          {/* 4 */}
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/staking/4.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>User Interface Design
                     </h3>
                    <p>Create intuitive dashboards that allow users to manage their staked assets, rewards, and track DeFi coin prices in real time.
                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>

          {/* 5 */}
          <div className='col-12 col-md-5'>
          <div class="card type-card">
               
               <div class="row">
               <div class="col-3">
               <div class="type-icon">
                     <img src="./Assets/staking/5.png" alt="" />
               </div>
               </div>
               <div class="col-9">
               <div class="type-caption">
                     <h3>Security Audits 

                     </h3>
                    <p>Ensure flawless security for your staking platforms, protecting against vulnerabilities and safeguarding user assets.
                    </p>
               </div>
               </div>
               </div>
             </div>    
          </div>
     </div>
    </div>

   </section>

    
    {/* 4 */}

    <section className='bgleft'>
     <div className='container'>
     <div className='row'>
          <div className='col-12 col-md-7 mx-auto'>
          <div class="heading-box">
               <h3>  Multiple Ways to Earn Rewards Through DeFi Staking Solutions
               </h3>
               {/* <div class="line2"></div> */}
             </div>   
          </div>
     </div>
     <div className='row'>
          <div className='col-12 col-md-3'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/staking/6.png" alt="" />
                       </div>
                     <h3>Staking Rewards
                     </h3>
                     <p> Earn a percentage of the transaction fees and new tokens generated by the network.
                     </p>
                        


                </div>
          </div>

          {/* 2 */}
          <div className='col-12 col-md-3'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/staking/7.png" alt="" />
                       </div>
                     <h3>Liquidity Mining
                     </h3>
                     <p>Provide liquidity to decentralized exchanges and earn rewards for enabling smooth trading operations.</p>
                </div>
          </div>

          {/* 3 */}
          <div className='col-12 col-md-3'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/staking/8.png" alt="" />
                       </div>
                     <h3>Yield Farming
                     </h3>
                     <p>Participate in DeFi yield farming protocols that offer higher returns by staking in multiple platforms.</p>
                        


                </div>
          </div>
          {/* 4 */}
          <div className='col-12 col-md-3'>
                <div className='cardS card-blockchainS'>
                       <div className='block-iconD'>
                          <img src="./Assets/staking/9.png" alt="" />
                       </div>
                     <h3>Governance Tokens
                     </h3>
                     <p>Earn governance tokens that provide voting rights on key protocol decisions, ensuring a say in the future direction of decentralized projects.
                     </p>
                </div>
          </div>
     </div>
     </div>
 </section>
 {/* 5 */}

<section className='bgright'>
 <div className="wrapper">
  <h1>Benefits of Choosing Blockcoaster Cloud Services
  </h1>
  <div class="cols">
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
                          
							<span>Passive Income
                            </span>
                         <img src="./Assets/staking/15.png" alt="" width="150px" />

						</div>
					</div>
					<div class="back">
						<div class="inner">
						  <span> Effortlessly earn rewards by staking crypto assets on decentralized platforms.

                          </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Enhanced Security
                            </span>
                            <img src="./Assets/staking/16.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span> Participate in securing blockchain networks and enjoy rewards for your contributions.

                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Decentralized Control
                            </span>
                            <img src="./Assets/staking/17.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span> Enjoy the freedom of decentralized finance, where users hold control over their assets and rewards.
                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Accessible for All
                            </span>
                            <img src="./Assets/staking/18.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Our platform simplifies the staking process, making it easy for beginners and experienced users to participate.

                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Diversified Earnings
                            </span>
                            <img src="./Assets/staking/19.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Stake across various tokens and DeFi apps, expanding your earning potential through diverse crypto defi projects.

                            </span>
						</div>
					</div>
				</div>
			</div>
		
		</div>
 </div>
</section>



 <section className='bgleft'>
     <div className='container-fluid'>
     <div className='row'>
          <div className='col-12 col-md-7 mx-auto'>
          <div class="heading-box">
               <h3> Our DeFi Staking Process
               </h3>
               {/* <div class="line2"></div> */}
             </div>   
          </div>
     </div>
     <div className='row justify-content-center'>
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/10.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Consultation & Requirement Analysis
                     </h3>
                     <p>Understand the client’s specific needs and staking goals.
                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 2 */}
       
          {/* 3 */}
    

    
          {/* 6 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/12.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Platform Design & Development
                     </h3>
                     <p>Develop a custom platform that aligns with your business vision, focusing on user experience and security.
                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 7 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/111.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Smart Contract Deployment
                     </h3>
                     <p>Create and audit staking smart contracts to automate processes like reward distribution.
                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 8 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/31.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Platform Integration
                     </h3>
                     <p>Integrate the staking solution with existing DeFi protocols and wallets, ensuring smooth functionality.

                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>
           {/* 9 */}
           <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/13.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Testing & Security Audits
                     </h3>
                     <p>Conduct rigorous testing and smart contract audits to eliminate vulnerabilities.
                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>

          {/* 10 */}
          <div className='col-12 col-md-5'>
                <div className='cardI Industry-blockchainI'>
                       <div className='row'>
                         <div className='col-3 text-center'>
                                <div className='indus-img'>
                                   <img src="./Assets/staking/14.png" className='img-fluid' alt="" />
                                </div>
                         </div>
                         <div className='col-9'>
                              <div className='indus-caption '>
                              <h3>Launch & Support
                     </h3>
                     <p>Successfully launch the platform and provide ongoing maintenance to ensure smooth operations.
                     </p>
                        
                              </div>
                         </div>
                       </div>
              


                </div>
          </div>
     </div>
     </div>
 </section>




   <section className="banner3">

<div className="container">
<div className="row ">
<div className="col-12 col-md-6">
 <div className="banner-caption2">
   <h1> <span className='block-clr'>Why Choose Blockcoaster for 
   </span>DeFi Staking Platform Development?
   </h1>
   <p>Expertise in DeFi Technologies <br/>
   Comprehensive Services <br/>
   Custom Solutions <br/>
   Top-Notch Security.<br/>
   Proven Success <br/>

   </p>


 </div>
</div>

<div className="col-12 col-md-6 text-center">
 <div>
   <img src="./Assets/staking/3.png" className='img-fluid' alt="" />

 </div>
</div>
</div>
</div>
</section>

</Layout>
  )
}

export default DefiStaking
