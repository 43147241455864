import React from 'react'
import Layout from '../Layout/Layout'
import'../Generative-Ai-Solution/GenerativeAi.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
function GenerativeAi() {
  return (
    <Layout>
        <section className="bannerSC">
                {/* <div className='video_fadeoverlay'></div> */}

                <div className="container">
                    <div className="row ">
                        <div className="col-12 col-md-6">
                            <div className="banner-caption2">
                                <h1>Generative AI Solutions
                                </h1>
                                <p>
                                Harness the potential of generative AI to elevate creativity, streamline operations, and deliver bespoke solutions that address your unique business challenges.

                                </p>
                                <Link to="/Contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>

                            </div>
                        </div>

                        <div className="col-12 col-md-6 text-center">
                            <div>
                                <img src="./Assets/ai-solution/I1.png" className='img-fluid' alt="not" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* 2 */}
            <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Custom Generative AI Solutions
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                    <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/ai-solution/I2.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>At Blockcoaster, we specialize in creating custom generative AI solutions tailored to meet the unique needs of your business. Leveraging AI development, machine learning, and deep learning technologies, we design models capable of generating high-quality content, automating workflows, and solving complex problems. Whether it’s generating text, images, music, or code, our generative AI systems deliver creative outputs that drive innovation and operational efficiency.
                                Our expertise spans multiple AI domains, from conversational AI to AI analytics, helping businesses enhance creativity, productivity, and automation with advanced generative AI applications.
                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>


            {/* 3 */}
            <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-12 mx-auto'>
                            <div class="heading-box">
                                <h3>Key Functions of Generative AI
                                </h3>
                                {/* <p>We provide a full suite of DeFi development services, ensuring you stay ahead in the world of decentralized finance. Explore our offerings:</p> */}
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/ai-solution/13.png" className='img-fluid' alt="not" />

                                <h3>Content Generation</h3>
                                <p> Automate the creation of articles, blogs, reports, and marketing content using AI-driven algorithms.
                                </p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/ai-solution/14.png" className='img-fluid' alt="not" />

                                <h3>Image and Video Creation</h3>
                                <p>Generate visual content for marketing, design, and media using generative models.
                                </p>
                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/ai-solution/15.png" className='img-fluid' alt="not" />

                                <h3>Code Generation</h3>
                                <p>Automate the development of code for various applications, saving time and resources for software development.
                                </p>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/ai-solution/16.png" className='img-fluid' alt="not" />

                                <h3>Data Analytics</h3>
                                <p>Utilize AI to generate insights from large datasets, enabling better decision-making with data-driven results.
                                </p>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/ai-solution/17.png" className='img-fluid' alt="not" />

                                <h3>Automation and Process Optimization</h3>
                                <p> Automate repetitive tasks, from customer service responses to inventory management, using generative AI.
                                </p>
                            </div>
                        </div>
                        {/* 6 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/ai-solution/18.png" className='img-fluid' alt="not" />

                                <h3>Natural Language Processing (NLP)</h3>
                                <p> Implement conversational AI and chatbot AI for real-time customer engagement and interaction.</p>
                            </div>
                        </div>

                        {/* 7 */}
                        

                        {/* 8 */}
                 

                        {/* 9 */}
                       


                    </div>
                </div>
            </section>

            {/* 4 */}
          

            {/* 5 */}
            <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Industries We Serve with Generative AI
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/ai-solution/19.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Retail and E-Commerce</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Automate content generation for product descriptions, customer support via conversational AI chatbots, and personalized recommendations.
                                </p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/ai-solution/20.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Healthcare</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Generate medical reports, summarize patient data, and assist in research using AI in healthcare applications.
</p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/ai-solution/21.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Finance</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Automate report generation, financial forecasting, and fraud detection with AI in the finance sector.
                                </p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/ai-solution/22.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Manufacturing</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> Improve design processes and optimize supply chain management with generative AI models.
                                </p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/ai-solution/25.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Entertainment and Media</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> Create personalized content, automate video generation, and streamline creative production processes using AI tools.
                                </p>

                            </div>
                        </div>

                        {/* 6 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/ai-solution/24.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Marketing and Advertising</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Use generative AI to create engaging ad campaigns, personalized content, and dynamic media assets.

                                </p>

                            </div>
                        </div>


                    </div>

                </div>
            </section>



            {/* 6 */}
            <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Why Should You Choose Blockcoaster for Generative AI Solutions?
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row  align-items-center'>

                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/ai-solution/I3.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>At Blockcoaster, we deliver customized generative AI solutions tailored to your specific business needs. Our expert team utilizes the latest advancements in AI and machine learning to enhance productivity and foster innovation. With a focus on scalability and ongoing support, we ensure your generative AI systems adapt as your business grows. Partner with us to unlock the true potential of generative AI and elevate your operations.


                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 7 */}
            

            {/* 8 */}
           

            {/* 9 */}
         
           
            {/* faq */}

            {/* faq */}
       

            {/* 
             new */}
 
  {/* timeline */}



{/* <!-- Timeline end --> */}

    </Layout>
  )
}

export default GenerativeAi
