import React from 'react'
import Layout from '../Layout/Layout'
import '../AI-ProductMarketing/AiProductMarket.css'

function AiProductMarket() {
  return (
    <Layout>
    <section className="banner4">
   
   <div className="container">
     <div className="row ">
       <div className="col-12 col-md-6">
         <div className="banner-caption2">
           <h1>AI Product Marketing</h1>
           <p>Transform your product’s market impact with Blockcoaster’s AI Product Marketing. Our AI-driven strategies enhance targeting, optimize campaigns, and maximize engagement for superior results.
           </p>
   
   
         </div>
       </div>
   
       <div className="col-12 col-md-6 text-center">
         <div className="why-img-banner">
           <img src="./Assets/AIProductMarketing/banner.png" className='img-fluid' alt="" />
   
         </div>
       </div>
     </div>
   </div>
   </section>
   
   {/* 2 */}
   <section className='bgright'>
             <div className='container'>
             <div className='row'>
                  <div className='col-12'>
                  <div class="heading-box">
                       <h3>Our AI Product Marketing Services
   </h3>
   {/* <div class="line3"></div> */}
                       {/* <div class="line2"></div> */}
                     </div>   
                  </div>
             </div>
       
        <div className='row'>
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/AIProductMarketing/services/1.png" className='img-fluid' alt="" />
                      </div>
                         <h3>AI-Driven Market Research</h3>
                      </div>
   
                      <p>Utilize advanced AI to analyze market trends, consumer behavior, and competitive landscapes, providing you with actionable insights for strategic decision-making.
                      </p>
                </div>
            </div>
   
            {/* 2 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                <img src="./Assets/AIProductMarketing/services/2.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Personalized Campaigns</h3>
                      </div>
   
                      <p>Leverage AI algorithms to create highly targeted and personalized marketing campaigns that engage your audience effectively and drive conversions.
   
                      </p>
                </div>
            </div>
   
            {/* 3 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/AIProductMarketing/services/3.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Predictive Analytics:</h3>
                      </div>
   
                      <p>Apply AI predictive models to forecast market trends, customer preferences, and campaign performance, ensuring you stay ahead of the competition.
   
                      </p>
                </div>
            </div>
   
            {/* 4 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/AIProductMarketing/services/4.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Dynamic Content Creation</h3>
                      </div>
   
                      <p>Use AI to generate optimized marketing content tailored to different audience segments, enhancing engagement and relevance.
                      </p>
                </div>
            </div>
   
            {/* 5 */}
            <div className='col-12 col-md-4'>
                <div className='card marketing-card'>
                
                      <div className='marketing-head'>
                      <div className='marketing-img'>
                      <img src="./Assets/AIProductMarketing/services/5.png" className='img-fluid' alt="" />
                      </div>
                         <h3>Automated Ad Optimization</h3>
                      </div>
   
                      <p>mplement AI-powered tools to continuously monitor and adjust advertising strategies, maximizing ROI and improving ad performance.
                      </p>
                </div>
            </div>
   
        </div>
             </div>
         </section>
   
   
   
         {/* 3 */}
   
         <section className='bgleft'>
                   <div className='container-fluid'>
                       <div className='row'>
                           <div className='col-12'>
                               <div class="heading-box">
                                   <h3>Key Features of Our AI Product Marketing</h3>
                                   {/* <div class="line2"></div> */}
                               </div>
                           </div>
                       </div>
                       <div className='row justify-content-center'>
                           <div className='col-12 col-md-5'>
                               <div class="card type-card">
   
                                   <div class="row">
                                       <div class="col-3">
                                           <div class="type-icon">
                                           <img src="./Assets/AIProductMarketing/marketing/6.png" className='img-fluid' alt="" />
                                           </div>
                                       </div>
                                       <div class="col-9">
                                           <div class="type-caption">
                                               <h3>Targeted Marketing Campaigns
                                               </h3>
                                               <p>We design campaigns that pinpoint your ideal customer base, utilizing tools like Google AI and OpenAI to enhance targeting accuracy.</p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
   
                           {/* 2 */}
                           <div className='col-12 col-md-5'>
                               <div class="card type-card">
   
                                   <div class="row">
                                       <div class="col-3">
                                           <div class="type-icon">
                                           <img src="./Assets/AIProductMarketing/marketing/7.png" className='img-fluid' alt="" />
                                           </div>
                                       </div>
                                       <div class="col-9">
                                           <div class="type-caption">
                                               <h3>Brand Differentiation
                                               </h3>
                                               <p>Our strategies emphasize the unique benefits of your AI products, whether it's a chatbot AI, AI assistant, or AI for business solutions, setting you apart from competitors.</p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
   
   
                           {/* 3 */}
                        
   
                           {/* 4 */}
                           <div className='col-12 col-md-5'>
                               <div class="card type-card">
   
                                   <div class="row">
                                       <div class="col-3">
                                           <div class="type-icon">
                                           <img src="./Assets/AIProductMarketing/marketing/8.png" className='img-fluid' alt="" />
                                           </div>
                                       </div>
                                       <div class="col-9">
                                           <div class="type-caption">
                                               <h3>Multichannel Outreach
                                               </h3>
                                               <p>We employ a diverse mix of channels, including social media, SEO, and PPC, to maximize visibility and drive conversions.</p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
   
                           {/* 5 */}
   
                           <div className='col-12 col-md-5'>
                               <div class="card type-card">
   
                                   <div class="row">
                                       <div class="col-3">
                                           <div class="type-icon">
                                           <img src="./Assets/AIProductMarketing/marketing/9.png" className='img-fluid' alt="" />
                                           </div>
                                       </div>
                                       <div class="col-9">
                                           <div class="type-caption">
                                               <h3>Data-Driven Insights:
                                               </h3>
                                               <p>Our analytics tools, like AI ops, provide detailed insights into campaign performance, helping us refine strategies for better results.</p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
   
                       </div>
   
                   </div>
   
               </section>
   
               {/* 4 */}
{/*    
               <section className='bgright'>
                   <div className='container-fluid'>
                       <div className='row'>
                           <div className='col-12'>
                               <div class="heading-box">
                                   <h3>Simplified Product Marketing Process</h3>
                                 
                               </div>
                           </div>
                       </div>
                       <div className="row gutters">
       <div className="col-12 col-md-8 mx-auto">
         <div className="card timeline-card">
           <div className="card-body">
           
             <div className="timeline">
               <div className="timeline-row">
                
                 <div className="timeline-dot fb-bg" />
                 <div className="timeline-content">
                 <img src="./Assets/AIProductMarketing/roadmap/10.png" className='img-fluid' alt="" />
                   <h4>Market Research</h4>
                   <p>Identify target audience and analyze competitors. - Gather customer insights.</p>
                 
                 </div>
               </div>
               <div className="timeline-row">
                
                 <div className="timeline-dot green-one-bg" />
                 <div className="timeline-content green-one">
                 <img src="./Assets/AIProductMarketing/roadmap/11.png" className='img-fluid' alt="" />
                   <h4>Define Value Proposition</h4>
                   <p>
                   Craft a clear Unique Value Proposition (UVP). - Position your product in the market.
                   </p>
                  
                 </div>
               </div>
               <div className="timeline-row">
                
                 <div className="timeline-dot green-two-bg" />
                 <div className="timeline-content green-two">
                 <img src="./Assets/AIProductMarketing/roadmap/12.png" className='img-fluid' alt="" />
                   <h4>Develop Strategy</h4>
                   <p>Set clear marketing objectives. - Choose channels and create key messaging.</p>
                 
                 </div>
               </div>
               <div className="timeline-row">
                 
                 <div className="timeline-dot green-three-bg" />
                 <div className="timeline-content green-three">
                 <img src="./Assets/AIProductMarketing/roadmap/13.png" className='img-fluid' alt="" />
                   <h4>Execute Plan</h4>
                   <p>
                   Create content and design marketing campaigns. Implement launch activities.
                   </p>
                 
                 </div>
               </div>
               <div className="timeline-row">
                
                 <div className="timeline-dot green-four-bg" />
                 <div className="timeline-content green-four">
                 <img src="./Assets/AIProductMarketing/roadmap/14.png" className='img-fluid' alt="" />
                   <h4>Monitor Performance</h4>
                   <p className="no-margin">Track key metrics and gather feedback. Analyze results to assess effectiveness.</p>
                   
                 </div>
               </div>
   
   
               
               <div className="timeline-row">
                 
                 <div className="timeline-dot green-three-bg" />
                 <div className="timeline-content green-three">
                 <img src="./Assets/AIProductMarketing/roadmap/15.png" className='img-fluid' alt="" />
                   <h4>Optimize and Adjust</h4>
                   <p>
                   Refine strategies and update messaging based on data.
                   </p>
                
                 </div>
               </div>
               <div className="timeline-row">
                
                 <div className="timeline-dot green-four-bg" />
                 <div className="timeline-content green-four">
                 <img src="./Assets/AIProductMarketing/roadmap/16.png" className='img-fluid' alt="" />
                   <h4>Review and Report</h4>
                   <p className="no-margin">Generate performance reports and review outcomes. Plan future marketing steps based on insights.</p>
                   
                 </div>
               </div>
         
             </div>
           </div>
         </div>
       </div>
     </div>
   
   
   
                   </div>
   
   
   
   
               </section> */}
   
   
   
               <section className='bgleft'>
             <div className='container'>
             <div className='row'>
                  <div className='col-12 col-md-12 mx-auto'>
                  <div class="heading-box">
                       <h3>Why Choose Us for AI Product Marketing?
                       </h3>
                    
                     </div>   
                  </div>
             </div>
             <div className='row justify-content-center'>
             <div className='col-12 col-md-4'>
                  <div class="code-box3">
                           <div className='row align-items-center'>
                               <div className='col-4'>
                               <div className='codeboxicon'>
                <img src="./Assets/AIProductMarketing/why/17.png" className='img-fluid' alt="" />
                </div>
                               </div>
                               <div className='col-8'>
                               <div className='fontbox'>
                               <p> Expertise in AI Technology</p>
              
                </div>
                               </div>
                           </div>
                    
                     </div>   
                  </div>
                  {/* 2 */}
                  <div className='col-12 col-md-4'>
                  <div class="code-box3">
                           <div className='row align-items-center'>
                               <div className='col-4'>
                               <div className='codeboxicon'>
                               <img src="./Assets/AIProductMarketing/why/18.png" className='img-fluid' alt="" />
                </div>
                               </div>
                               <div className='col-8'>
                               <div className='fontbox'>
                               <p>Data-Driven Insights</p>
              
                </div>
                               </div>
                           </div>
                    
                     </div>   
                  </div>
            
   
            {/* 3 */}
            <div className='col-12 col-md-4'>
                  <div class="code-box3">
                           <div className='row align-items-center'>
                               <div className='col-4'>
                               <div className='codeboxicon'>
                               <img src="./Assets/AIProductMarketing/why/19.png" className='img-fluid' alt="" />
                </div>
                               </div>
                               <div className='col-8'>
                               <div className='fontbox'>
                               <p> Personalized Strategies</p>
              
                </div>
                               </div>
                           </div>
                    
                     </div>   
                  </div>
                  {/* 3 */}
            {/* 4 */}
            <div className='col-12 col-md-4'>
                  <div class="code-box3">
                           <div className='row align-items-center'>
                               <div className='col-4'>
                               <div className='codeboxicon'>
                               <img src="./Assets/AIProductMarketing/why/20.png" className='img-fluid' alt="" />
                </div>
                               </div>
                               <div className='col-8'>
                               <div className='fontbox'>
                               <p> Real-Time Optimization</p>
              
                </div>
                               </div>
                           </div>
                    
                     </div>   
                  </div>
   
                  {/* 4 */}
                
                  <div className='col-12 col-md-4'>
                  <div class="code-box3">
                           <div className='row align-items-center'>
                               <div className='col-4'>
                               <div className='codeboxicon'>
                               <img src="./Assets/AIProductMarketing/why/21.png" className='img-fluid' alt="" />
                </div>
                               </div>
                               <div className='col-8'>
                               <div className='fontbox'>
                               <p> Proven Results</p>
              
                </div>
                               </div>
                           </div>
                    
                     </div>   
                  </div>
   
               
                
             </div>
         
             </div>
         </section>
   
   
               <section className='bgright'>
                 <div className="container py-3">
     <div className="heading-box text-center ">
       <h3 className="mb-3">FAQ</h3>
     </div>
     {/* 1 */}
     <div className="accordion accordion-flush" id="accordionFlushExample">
       <div className="accordion-item rounded-3 border-0 shadow mb-2">
         <h2 className="accordion-header">
           <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
           What is AI Product Marketing?
           </button>
         </h2>
         <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
           <div className="accordion-body">
             <p>AI Product Marketing uses artificial intelligence to enhance marketing strategies through data analysis, personalized content, and optimized ad performance.</p>
           </div>
         </div>
       </div>
       {/* 2 */}
       <div className="accordion-item rounded-3 border-0 shadow mb-2">
         <h2 className="accordion-header">
           <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
           How can AI improve my marketing campaigns?
           </button>
         </h2>
         <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
           <div className="accordion-body">
             <p>AI boosts campaigns by enabling precise targeting, automating content creation, and optimizing ad spend for better engagement and ROI.</p>
           </div>
         </div>
       </div>
       {/* 3 */}
    
       <div className="accordion-item rounded-3 border-0 mb-2 shadow">
         <h2 className="accordion-header">
           <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
           What are the benefits of using AI for product marketing?
           </button>
         </h2>
         <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
           <div className="accordion-body">
             <p>Benefits include improved targeting, higher engagement, better ROI, real-time insights, and faster adaptation to market trends.</p>
           </div>
         </div>
       </div>
       {/* 5 */}
       <div className="accordion-item rounded-3 border-0 mb-2 shadow">
         <h2 className="accordion-header">
           <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
            How does AI personalize marketing content?
           </button>
         </h2>
         <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
           <div className="accordion-body">
             <p>AI personalizes content by analyzing user data to tailor recommendations and messaging to individual preferences..</p>
           </div>
         </div>
       </div>
       {/* 6*/}
       <div className="accordion-item rounded-3 border-0 mb-2 shadow">
         <h2 className="accordion-header">
           <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
           Who can benefit from AI Product Marketing?
           </button>
         </h2>
         <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
           <div className="accordion-body">
             <p>Businesses of all sizes and industries can benefit from AI Product Marketing to enhance their marketing effectiveness and drive growth.</p>
           </div>
         </div>
       </div>
    
     </div>
   </div>
                 </section>
       </Layout>
  )
}

export default AiProductMarket
