import React from 'react'
import Layout from '../Layout/Layout'
import '../Web3-Game-Development/Web3GameDevelopment.css'

const Web3GameDevelopment = () => {
  return (
    <Layout>  
    <section className="bannerSC">
               {/* <div className='video_fadeoverlay'></div> */}
               <video autoplay="true" muted="true" loop="true" style={{backgroundColor:" rgba(0, 0, 0, 0.5)"}}>
     <source src="./Assets/web3.0dev/Templedragon (2)-1.m4v" type="video/mp4" />
     <source type="#" src="video.mp4"></source>
   </video>
   
                   <div className="container">
                       <div className="row">
                           <div className="col-12 col-md-6">
                               <div className="banner-caption2">
                                   <h1> <span className=''>Web3 
                                   </span> Game Development Company</h1>
                                   {/* <p style={{wordWrap:"break-word",}}>At Blockcoaster, we are redefining the gaming experience with Web3.0 technology. By merging blockchain, NFTs, and decentralized ecosystems, we empower players and developers to shape a new era of gaming.
                                   </p> */}
                               </div>
                           </div>
   {/*                       */}
                       </div>
                   </div>
               </section>
   
               <section className='bgleftH'>
      
      <div className="container">
        <div className="row "  >
        <div className="col-12 col-md-6 text-center">
           <div >
            {/* <video autoplay="true" muted="true" loop="true">
            <source src="./Assets/web3.0dev/Prince.m4v" type="video/mp4" />
            </video> */}
              <img src="./Assets/web3.0dev/avatar8.png" className='img-fluid' alt=""  />
   
            </div>
          </div>
      
      
          <div className="col-12 col-md-6">
            <div className="banner-caption2">
              <h1>Understanding Web3 Gaming
   
              </h1>
              <p>Web3 gaming represents the next phase of the gaming industry, powered by blockchain technology. Unlike traditional games, Web3 games leverage decentralized networks, ensuring transparency, security, and player empowerment. Gamers can own in-game assets, trade them freely as NFTs, and participate in decentralized economies, making gaming not just fun but financially rewarding.
   
   
   
              </p>
            </div>
          </div>
      
         
        </div>
      </div>
      </section>
   
   
   
      <section className='bgleftH'>
      
      <div className="container">
        <div className="row ">
        <div className="col-12 col-md-6">
            <div className="banner-caption2">
              <h1>The Shift Towards Web3 In The Gaming Industry </h1>
              <p> <li><img src="Assets/sword.png" width="30px" height="30px" style={{marginTop:"10px" , padding:"5px"}}/>Players have true ownership of their in-game assets as NFTs.</li>
           
              <li><img src="Assets/sword.png" width="30px" height="30px" style={{marginTop:"10px" , padding:"5px"}}/> Decentralized economies allow players to earn and trade cryptocurrencies. </li>
              <li>
              <img src="Assets/sword.png" width="30px" height="30px" style={{marginTop:"10px" , padding:"5px"}}/>   Blockchain ensures fair gameplay and secure transactions. </li>
              <li>  <img src="Assets/sword.png" width="30px" height="30px" style={{marginTop:"10px" , padding:"5px"}}/>Community-driven governance gives players a voice in development and policies.
              </li>
              <li> <img src="Assets/sword.png" width="30px" height="30px" style={{marginTop:"10px" , padding:"5px"}}/>Interoperable assets can be used across multiple games and platforms. </li>
             <li> <img src="Assets/sword.png" width="30px" height="30px" style={{marginTop:"10px" , padding:"5px"}}/>Enhanced monetization opportunities attract both developers and gamers.</li>
               </p>
            </div>
          </div>
      
        <div className="col-12 col-md-6 text-center">
            <div className="why-img-banner">
            <img src="./Assets/web3.0dev/avatar3.png" className='img-fluid' alt=""  style={{marginTop:"20%"}} />
          </div>
          </div>
        </div>
      </div>
      </section>
   
      <section className="bgleftH">
      <div className="heading-box"> 
     <h1 style={{color:"white"}}>Series of Games we have Developed so far 
     </h1>
     </div>
   <div  className="containerthrill">
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="Assets/web3.0dev/bhairava the saviour. 2.png" className="cover-image" />
         <p>Bhairava The Saviour</p>
       </div>
       
       {/* <img src="https://ggayane.github.io/css-experiments/cards/dark_rider-title.png" className="title" /> */}
       <img src="Assets/web3.0dev/1.png" className="character" />
     </div>
   
   
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Cricket stadium.png" className="cover-image" />
         <p>Cricket Stadium</p>
       </div>
      
       {/* <img src="https://ggayane.github.io/css-experiments/cards/force_mage-title.png" className="title" /> */}
       <img src="./Assets/web3.0dev/1ppp.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Flap Quest.png" className="cover-image" />
         <p>Flap Quest</p>
       </div>
      
       {/* <img src="https://ggayane.github.io/css-experiments/cards/force_mage-title.png" className="title" /> */}
       <img src="./Assets/web3.0dev/3www.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Ludo.png" className="cover-image" />
         <p>Ludo</p>
       </div>
      
       {/* <img src="https://ggayane.github.io/css-experiments/cards/force_mage-title.png" className="title" /> */}
       <img src="./Assets/web3.0dev/2.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Nexus.png" className="cover-image" />
         <p>Nexus</p>
       </div>
       <img src="./Assets/web3.0dev/4game.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Match mania.png" className="cover-image" />
         <p>
         Match Mania
         </p>
       </div>
       <img src="./Assets/web3.0dev/5.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Mystical maze.png" className="cover-image" />
         <p>Mystical Maze</p>
       </div>
       <img src="./Assets/web3.0dev/6.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Dragon Vs Tiger.png" className="cover-image" />
         <p>Dragon Vs Tiger</p>
       </div>
       <img src="./Assets/web3.0dev/7.png" className="character" />
     </div>
   
     <div className="mythrill-card">
       <div className="mythrill-wrapper">
         <img src="./Assets/web3.0dev/Lord of space-1.png" className="cover-image" />
         <p>Lord of space</p>
       </div>
       <img src="./Assets/web3.0dev/8.png" className="character" />
     </div>
   </div>
   </section>
   
   {/* <section >
   <div className='card_custom'>
   <div className='row'>
   <div className="col-6">
   <h1>
     hello
   </h1>
   <p> 
   </p>
   </div>
   <div className='col-6'>
   <img src="../Assets/Farming/4.png" />
   </div>
   </div>
   
   </div>
   </section> */}
   
   
   {/* <div>
     <figure className="alt">
       <img src="https://picsum.photos/id/593/300/300" alt="Tiger" />
       <figcaption>Tiger</figcaption>
     </figure>
     <figure>
       <img src="https://picsum.photos/id/659/300/300" alt="Wolf" />
       <figcaption>Wolf</figcaption>
     </figure>
   </div> */}
   
   
    <section className='bgleftH'>
   <div className="container">
   <div className="heading-box"> 
     <h1>Services We Offer
     </h1>
     <p>
     Our Web3 game development services include
     </p>
     </div>
     
   <div className="animal-container">
     <div className='row justify-content-center'>
   <div className="col-md-3 col-12">
     <a className="animal-card" href="#">
       <img alt="Owl" height={40} src="https://assets.codepen.io/221808/owl.svg" width={40} />
       <h2>Web3.0 Game Development</h2>
       <p>
       Creating immersive blockchain-based games.
   
       </p>
     </a>
     </div>
     <div className="col-md-3 col-12">
     <a className="animal-card" href="#">
       <img alt="Rabbit" height={40} src="https://assets.codepen.io/221808/rabbit.svg" width={40} />
       <h2>Web3.0 Marketing</h2>
       <p>
       CPromoting games with tailored Web3 marketing strategies. </p>
     </a>
   </div>
   
   <div className="col-md-3 col-12">
     <a className="animal-card" href="#">
       <img alt="Raven" height={40} src="https://assets.codepen.io/221808/raven.svg" width={40} />
       <h2>Web3.0 Consultation</h2>
       <p>
       Offering expert guidance for transitioning to Web3 gaming.</p>
     </a>
   </div>
   
   
   <div className="col-md-3 col-12">
     <a className="animal-card" href="#">
       <img alt="Raven" height={40} src="https://assets.codepen.io/221808/raven.svg" width={40} />
       <h2>Blockchain Integration</h2>
       <p>
       Incorporating blockchain networks like Ethereum, Binance Smart Chain, and Polygon for decentralized functionality.
    </p>
     </a>
     </div>
   
     <div className="col-md-3 col-12">
     <a className="animal-card" href="#">
       <img alt="Raven" height={40} src="https://assets.codepen.io/221808/raven.svg" width={40} />
       <h2>NFT Development</h2>
       <p>
       Creating unique in-game assets as NFTs for ownership and trading.</p>
     </a>
     </div>
   <div className="col-md-3 col-12">
     <a className="animal-card" href="#">
       <img alt="Raven" height={40} src="https://assets.codepen.io/221808/raven.svg" width={40} />
       <h2>Play-to-Earn Models</h2>
       <p>
       Developing games where players can earn real-world value through gameplay.</p>
     </a>
     </div>
     </div>
   
     </div>
   
   </div>
   
   </section>
   
   <section className="amazing_feature">
     <div className="container">
       <div className="row">
         <div className="col-md-12 text-center heading-main">
           <h2 className="heading-box">Unparalleled Features of Web3 Games
           </h2>
         </div>
       </div>      
       <div className="row justify-content-center">         
         <div className="col-md-4 col-sm-6 col-xs-12">
           <div className="single_feature">
             <div className="feature_icon"><i className="far fa-heart" /></div>
             <h3>True Asset Ownership</h3>
             <p> Players own their in-game items as NFTs.
             </p>  
           </div>
         </div>{/* END COL*/}               
         <div className="col-md-4 col-sm-6 col-xs-12">
           <div className="single_feature">
             <div className="feature_icon"><i className="fa fa-magic" /></div>
             <h3>Interoperability</h3>
             <p>Assets can be used across multiple games and platforms.
             </p>    
           </div>
         </div>{/* END COL*/}         
         <div className="col-md-4 col-sm-6 col-xs-12">
           <div className="single_feature">
             <div className="feature_icon"><i className="fas fa-location-arrow" /></div>
             <h3>Decentralized Economies</h3>
             <p>Gamers can earn cryptocurrency rewards.</p>  
           </div>
         </div>{/* END COL*/}             
         <div className="col-md-4 col-sm-6 col-xs-12">
           <div className="single_feature">
             <div className="feature_icon"><i className="far fa-money-bill-alt" /></div>
             <h3>Enhanced Security</h3>
             <p>Blockchain technology ensures secure and transparent transactions.
             </p>  
           </div>
         </div>{/* END COL*/}         
         <div className="col-md-4 col-sm-6 col-xs-12">
           <div className="single_feature">
             <div className="feature_icon"><i className="fa fa-cog" /></div>
             <h3>Player-Centric Ecosystem</h3>
             <p>Communities have a voice in game development and governance.
             </p>  
           </div>
         </div>{/* END COL*/}         
           
       </div>{/*- END ROW */}     
     </div>{/*- END CONTAINER */}   
   </section>
   
   
   <section className='bgleftH'>
   <div className="container">
        <div className="row ">
   <div className="col-12 col-md-6">
            <div className="banner-caption2">
              <h1>Introducing Gaming DAOs</h1>
              <p>Gaming DAOs (Decentralized Autonomous Organizations) are reshaping the gaming landscape. These blockchain-powered entities allow players to participate in decision-making processes, fund game development, and even co-own games. DAOs empower communities by giving them control over game updates, monetization models, and governance.</p>
            </div>
          </div>
   
          <div className="col-12 col-md-6">
          <div className="banner-caption2">
          <img src="./Assets/web3.0dev/avatar7.png" className='img-fluid' alt=""  style={{width:""}} />
   </div>
   </div>
      </div>
      </div>
   </section>
   
   
   <section className='bgleftH'>
   <div className='container'>
   <div className="heading-box">
     <h1>Diverse Types of Gaming DAOs
     </h1>
     </div>
   <div className="custom-section">
     <div className="custom-box">
       <img src="./Assets/web3.0dev/game1.png" className="custom-image" />
       <div className="custom-overlay">
         <h3 className="custom-Ttext" style={{fontSize:"25px"}}>Development DAOs</h3>
         <p className="custom-Stext"> Focus on funding and collaboratively building new games.</p>      
       </div>
     </div>
     <div className="custom-box">
       <img src="./Assets/web3.0dev/game2.png" className="custom-image" />
       <div className="custom-overlay">
         <h3 className="custom-Ttext" style={{fontSize:"25px"}}>Investment DAOs</h3>
         <p className="custom-Stext">Pool funds to invest in promising Web3 games and NFTs.</p>      
       </div>
     </div>
     <div className="custom-box">
       <img src="./Assets/web3.0dev/game3.png" className="custom-image" />
       <div className="custom-overlay">
         <h3 className="custom-Ttext" style={{fontSize:"25px"}}>E-sports DAOs</h3>
         <p className="custom-Stext"> Manage decentralized e-sports teams and tournaments.
         </p>      
       </div>
     </div>
     <div className="custom-box">
       <img src="./Assets/web3.0dev/4.png" className="custom-image" />
       <div className="custom-overlay">
         <h3 className="custom-Ttext" style={{fontSize:"25px"}}>Community DAOs</h3>
         <p className="custom-Stext">Govern in-game policies and community-driven initiatives.
         </p>      
       </div>
     </div>
   </div>
   </div>
   </section>
   
   
       </Layout>
  )
}

export default Web3GameDevelopment