// import React from 'react'
import React, { useState } from 'react';
import Layout from '../Layout/Layout'
import '../Contact/Contact.css'
import emailjs from 'emailjs-com';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
      const [loading, setLoading] = useState(false);
      const [successMessage, setSuccessMessage] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
    
        // Replace with your EmailJS service and template IDs
        emailjs.send("service_o9sulbj", "template_6octzvb", formData, "e0amD1LcCVO_5dR9A")
          .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            setSuccessMessage('Your message has been sent successfully!');
            setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form
          })
          .catch((error) => {
            console.error('Failed to send email:', error);
            setErrorMessage('There was an error sending your message. Please try again later.');
          })
          .finally(() => {
            setLoading(false);
          });
      };
    
  return (
    <Layout>
<section class="contact-page-section">
<div class="container">
    <div class="sec-title">
        <div class="title">Contact Us</div>
          {/* <h2>Let's Get in Touch.</h2> */}
      </div>
      <div class="inner-container">
        <div class="row clearfix">
          
            
              <div class="form-column col-md-7 col-sm-12 col-xs-12">
                <div class="inner-column">
                    
                      <div class="contact-form">
                      <form onSubmit={handleSubmit} id="contact-form">
                      <div className="row clearfix">
                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            placeholder="Full Name"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            placeholder="Email"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                          <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            placeholder="Phone"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                          <textarea
                            name="message"
                            value={formData.message}
                            placeholder="Message"
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                          <button type="submit" className="theme-btn btn-style-one" disabled={loading}>
                            {loading ? 'Sending...' : 'Send Now'}
                          </button>
                        </div>
                      </div>
                    </form>

                    {successMessage && <p className="success-message">{successMessage}</p>}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                      </div>
                    
                      
                  </div>
              </div>
              

              <div class="info-column col-md-5 col-sm-12 col-xs-12">
                <div class="inner-column">
                    <h2>Contact Info</h2>
                      <ul class="list-info">
                        <li><i class="fas fa-globe"></i>IFZA Properties, Dubai Silicon Oasis</li>
                          <li><i class="far fa-envelope"></i>info@blockcoaster.com</li>
                          {/* <li><i class="fas fa-phone"></i>1-234-567-890 <br/> 1-234-567-890</li> */}
                      </ul>
                      <ul class="social-icon-four">
                          <li class="follow">Follow on: </li>
                          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                          <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                          <li><a href="#"><i class="fab fa-dribbble"></i></a></li>
                          <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                      </ul>
                  </div>
              </div>
              
          </div>
      </div>
  </div>
</section>



    </Layout>
  )
}

export default Contact