import React from 'react'
import Layout from '../Layout/Layout'
import '../Nft-marketplace/NMarket.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

function NMarket() {
  return (
    <Layout>

    <section className="bannerSC">

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="banner-caption2">
              <h1> <span className=''>NFT </span> Marketplace Development </h1>
              <p>Our NFT platforms are designed to empower businesses and individuals to buy, sell, and trade non-fungible tokens (NFTs) seamlessly, using secure blockchain wallets and smart contracts.

              </p>
              <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  

            </div>
          </div>

          <div className="col-12 col-md-6 text-center">
            <div className="banner-img-nft4">
              <img src="./Assets/marketplace/1.png" className='img-fluid'  alt="" />

            </div>
          </div>
        </div>
      </div>
    </section>


    {/* 2 */}
    <section className='bgright py-2'>
      <div className="container">
        <div className="row align-items-center">
        <div className="col-12 col-md-5">
            <div className='why-img'>
              <img src="./Assets/marketplace/2.png" className='img-fluid'width="200px" alt="" />


            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="heading-box-sec  py-4">
              <h3>Our Expertise in Creating Full-Fledged NFT Marketplace Solutions
              </h3>
              <p>At Blockcoaster, our expert team has years of experience in blockchain programming and development, making us proficient in building highly scalable, user-friendly NFT sites. Our comprehensive solutions cover everything from NFT minting to integrating crypto wallets and handling blockchain transactions securely. Our robust knowledge extends across various blockchain technologies, including Hyperledger, Binance NFT, and Tron Crypto, ensuring the development of high-performance platforms tailored to your specific business model.
              </p>

            </div>
          </div>
        

        </div>


      </div>
    </section>
    {/* 3 */}
    {/* 4*/}
    <section className='bgleft'>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-box-sec text-center py-4">
              <h3>Standout Features of Our NFT Marketplace Development
              </h3>
              <div className='line3'></div>

            </div>


          </div>
        </div>

        <div className='row justify-content-center'>
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/111.png" alt="" />
              </div>
              <h3>Customizable Storefronts
              </h3>

              <p>Personalize your marketplace's look and feel to reflect your brand and attract more users.
              </p>
            </div>
          </div>
          {/* 2 */}  <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/222.png" alt="" />
              </div>
              <h3>Multi-Blockchain Support</h3>
              <p>Seamlessly integrate with leading blockchains like Ethereum, Binance Smart Chain, Tron, and Hyperledger for enhanced scalability and lower fees.
              </p>
            </div>
          </div>

          {/* 3 */}
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/333.png" alt="" />
              </div>
              <h3>Lazy Minting</h3>
              <p>Creators can mint NFTs without upfront gas fees, making it easier to onboard new users.</p>
            </div>
          </div>
          {/* 4 */}
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/4.png" alt="" />
              </div>
              <h3>Auction Mechanism
              </h3>
              <p>Enable both timed and reserve price auctions, giving sellers more options to monetize their assets.
              </p>
            </div>
          </div>
          {/* 5 */}
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/5.png" alt="" />
              </div>
              <h3>Gas Optimization</h3>
              <p> Efficient transaction processing that reduces gas fees for users, improving the platform’s appeal.</p>
            </div>
          </div>
          {/* 6 */}
          <div className="col-12 col-md-3">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/6.png" alt="" />
              </div>
              <h3>Built-in Royalty System</h3>
              <p>Allow creators to earn royalties on every secondary sale of their NFTs, ensuring recurring revenue.
              </p>
            </div>
          </div>
          {/* 7 */}
          <div className="col-12 col-md-3 ">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/marketplace/1666.png" className='bgimg' alt=""/>
              </div>
              <h3>NFT Fractionalization</h3>
              <p>Enables fractional ownership of high-value NFTs, allowing multiple users to co-own a single asset.
              </p> 
            </div>
          </div>
          {/* 8 */}
          
        </div>
      </div>
    </section>


    {/* 4*/}

    <section className='bgright py-2'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center  py-4">
                <h3>
                Workflow of Our NFT Marketplace Development

                </h3>
                <div className='line3'></div>
              </div>
            </div>


          </div>

       <div className='row py-4 justify-content-center'>
       <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                  <img src="./Assets/marketplace/8.png" alt="" />
                </div>
                <h3>Requirement Analysis
                </h3>
              </div>
            </div>

            {/* 2 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                <img src="./Assets/marketplace/9.png" alt="" />
                </div>
                <h3>Prototyping
                </h3>
              </div>
            </div>

            {/* 3 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                <img src="./Assets/marketplace/10.png" alt="" />
                </div>
                <h3>UI/UX Development
                </h3>
              </div>
            </div>

            {/* 4 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                <img src="./Assets/marketplace/11.png" alt="" />
                </div>
                <h3>Integration of Wallets and Smart Contracts
                </h3>
              </div>
            </div>
            {/* 5 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                <img src="./Assets/marketplace/12.png" alt="" />
                </div>
                <h3>Testing
                </h3>
              </div>
            </div>
            {/* 6 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                <img src="./Assets/marketplace/13.png" alt="" />
                </div>
                <h3>Deployment</h3>
              </div>
            </div>

            {/* 7 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Deverse'>
                {/* <div className='top_line'></div>
                <div className='right_line'></div> */}
                <div className="">
                <img src="./Assets/marketplace/14.png" alt="" />
                </div>
                <h3>Maintenance and Support
                </h3>
              </div>
            </div>
            {/* 8 */}
            

            {/* 9 */}
          
       </div>
       {/* 2 */}
       
         
        </div>
      </section>

    {/* 4 hide */}
    <section className='bgleft samia py-2'>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-box-sec text-center  py-4">
              <h3>Our NFT Development Workflow </h3>
              <div className='line3'></div>
            </div>
          </div>


        </div>
        <div className='row'>

          <div className='col-12'>
            <div className='card Workflow-card'>
              <div className='txt-card'>
                <h3> 01. Consultation</h3>
                <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
              </div>



            </div>
          </div>

          {/* 2 */}

          <div className='col-12'>
            <div className='card Workflow-card'>
              <div className='txt-card'>
                <h3> 02. Design & Development</h3>
                <p>Our team designs and develops your NFT platform with custom features like decentralized storage and user-friendly interfaces.</p>
              </div>



            </div>
          </div>
          {/* 3 */}
          <div className='col-12'>
            <div className='card Workflow-card'>
              <div className='txt-card'>
                <h3> 03. Smart Contract Integration</h3>
                <p>We integrate smart contracts to manage the minting, buying, and selling of NFTs.</p>
              </div>



            </div>
          </div>

          {/* 4 */}
          <div className='col-12 '>
            <div className='card Workflow-card'>
              <div className='txt-card'>
                <h3> 04. Testing & Optimization</h3>
                <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
              </div>



            </div>
          </div>

          {/* 5 */}
          <div className='col-12'>
            <div className='card Workflow-card'>
              <div className='txt-card'>
                <h3> 05. Launch & Marketing</h3>
                <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
              </div>



            </div>
          </div>


          {/* 6 */}
          <div className='col-12'>
            <div className='card Workflow-card'>
              <div className='txt-card'>
                <h3> 06. Support & Maintenance</h3>
                <p>We assist with launching your NFT platform and implementing strategies to attract users.</p>
              </div>



            </div>
          </div>

        </div>


      </div>
    </section>

    {/* 5 */}

    {/* <section className="content-cards">
      <div className="content-card-container">
        <div className="content-card-number">01</div>
        <a
          className="content-card"
          href="https://codetheweb.blog/2017/10/06/html-syntax/"
          style={{ '--bg-img': 'url(https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&resize_w=1500&url=https://codetheweb.blog/Assets/img/posts/html-syntax/cover.jpg)' }}
        >
          <div className="content-card-details">
            <h1>Introduction to Syntax</h1>
            <p>The syntax of a language is how it works. Learn how to write code effectively…</p>
            <div className="content-card-tags">
              <span className="content-card-tag">Syntax</span>
            </div>
          </div>
        </a>
      </div>

      <div className="content-card-container">
        <div className="content-card-number">02</div>
        <a
          className="content-card"
          href="https://codetheweb.blog/2017/10/09/basic-types-of-html-tags/"
          style={{ '--bg-img': 'url(https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&resize_w=1500&url=https://codetheweb.blog/Assets/img/posts/basic-types-of-html-tags/cover.jpg)' }}
        >
          <div className="content-card-details">
            <h1>Basic Code Structures</h1>
            <p>Learn about some of the most fundamental code structures…</p>
            <div className="content-card-date">9 Oct 2017</div>
            <div className="content-card-tags">
              <span className="content-card-tag">Basics</span>
            </div>
          </div>
        </a>
      </div>

      <div className="content-card-container">
        <div className="content-card-number">03</div>
        <a
          className="content-card"
          href="https://codetheweb.blog/2017/10/14/links-images-about-file-paths/"
          style={{ '--bg-img': 'url(https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&resize_w=1500&url=https://codetheweb.blog/Assets/img/posts/links-images-about-file-paths/cover.jpg)' }}
        >
          <div className="content-card-details">
            <h1>Working with Resources</h1>
            <p>Learn how to work with external resources, like images and links…</p>
            <div className="content-card-date">14 Oct 2017</div>
            <div className="content-card-tags">
              <span className="content-card-tag">Resources</span>
            </div>
          </div>
        </a>
      </div>

      <div className="content-card-container">
        <div className="content-card-number">04</div>
        <a
          className="content-card"
          href="https://codetheweb.blog/2017/10/14/links-images-about-file-paths/"
          style={{ '--bg-img': 'url(https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&resize_w=1500&url=https://codetheweb.blog/Assets/img/posts/links-images-about-file-paths/cover.jpg)' }}
        >
          <div className="content-card-details">
            <h1>Working with Resources</h1>
            <p>Learn how to work with external resources, like images and links…</p>
            <div className="content-card-date">14 Oct 2017</div>
            <div className="content-card-tags">
              <span className="content-card-tag">Resources</span>
            </div>
          </div>
        </a>
      </div>

      <div className="content-card-container">
        <div className="content-card-number">05</div>
        <a
          className="content-card"
          href="https://codetheweb.blog/2017/10/14/links-images-about-file-paths/"
          style={{ '--bg-img': 'url(https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&resize_w=1500&url=https://codetheweb.blog/Assets/img/posts/links-images-about-file-paths/cover.jpg)' }}
        >
          <div className="content-card-details">
            <h1>Working with Resources</h1>
            <p>Learn how to work with external resources, like images and links…</p>
            <div className="content-card-date">14 Oct 2017</div>
            <div className="content-card-tags">
              <span className="content-card-tag">Resources</span>
            </div>
          </div>
        </a>
      </div>

    </section> */}


    <section className='bgleft py-2'>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-box-sec text-center  py-4">
              <h3>Benefits of Choosing Blockcoaster's NFT Marketplace Development
              </h3>
              <div className='line3'></div>
            </div>
          </div>


        </div>

        <div className='row'>
          <div className='col-12 col-md-4'>
            <div className='card eco-card'>
              <div className='row'>
                <div className='col-3'>
                  <div className='ecos-icon'>
                    <img src="./Assets/marketplace/15.png" className='img-fluid' alt="" />
                  </div>
                </div>
                <div className='col-9'>
                  <div className='eco-caption'>
                    <h3>Cost-Effective Solutions</h3>
                    <p>Our gas optimization techniques and smart contract automation help reduce operational costs for both platform owners and users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className='col-12 col-md-4'>
            <div className='card eco-card'>
              <div className='row'>
                <div className='col-3'>
                  <div className='ecos-icon'>
                    <img src="./Assets/marketplace/16.png" className='img-fluid' alt="" />
                  </div>
                </div>
                <div className='col-9'>
                  <div className='eco-caption'>
                    <h3>Creator-Friendly</h3>
                    <p> With lazy minting and built-in royalty features, creators can easily onboard and continue earning from secondary sales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 3 */}
        

          {/* 4 */}  <div className='col-12 col-md-4'>
            <div className='card eco-card'>
              <div className='row'>
                <div className='col-3'>
                  <div className='ecos-icon'>
                    <img src="./Assets/marketplace/17.png" className='img-fluid' alt="" />
                  </div>
                </div>
                <div className='col-9'>
                  <div className='eco-caption'>
                    <h3>Cross-Chain Compatibility</h3>
                    <p>Our platforms allow NFTs to be transferred across multiple blockchains, giving users greater flexibility and liquidity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 5 */}
          <div className='col-12 col-md-4'>
            <div className='card eco-card'>
              <div className='row'>
                <div className='col-3'>
                  <div className='ecos-icon'>
                    <img src="./Assets/marketplace/18.png" className='img-fluid' alt="" />
                  </div>
                </div>
                <div className='col-9'>
                  <div className='eco-caption'>
                    <h3>Advanced Security Protocols</h3>
                    <p>Leveraging top security practices, including blockchain cryptography and multi-layer authentication, we ensure your marketplace is resilient against hacks and fraud.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 6 */}
          <div className='col-12 col-md-4'>
            <div className='card eco-card'>
              <div className='row'>
                <div className='col-3'>
                  <div className='ecos-icon'>
                    <img src="./Assets/marketplace/19.png" className='img-fluid' alt="" />
                  </div>
                </div>
                <div className='col-9'>
                  <div className='eco-caption'>
                    <h3>Revenue Maximization</h3>
                    <p>With features like auctions, royalties, and NFT fractionalization, you can explore diverse revenue streams.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-4'>
            <div className='card eco-card'>
              <div className='row'>
                <div className='col-3'>
                  <div className='ecos-icon'>
                    <img src="./Assets/marketplace/20.png" className='img-fluid' alt="" />
                  </div>
                </div>
                <div className='col-9'>
                  <div className='eco-caption'>
                    <h3>Seamless User Experience</h3>
                    <p> The easy-to-use interface, combined with a highly responsive design, ensures a smooth experience across all devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>



      </div>
    </section>



    <section className='bgright'>
         <div className='container-fluid'>
          <div className='row'>
               <div className='col-12'>
               <div class="heading-box">
                    <h3>Exploring the Use Cases of Our NFT Marketplace Development</h3>
                    {/* <div class="line2"></div> */}
                  </div>    
               </div>
          </div>
          <div className='row justify-content-center'>
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/21.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Digital Art
                          </h3>
                         <p>Showcase and sell artwork as non-fungible tokens on your own marketplace.

                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 2 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/22.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Gaming
                          </h3>
                         <p>Enable in-game purchases, characters, and skins as NFTs, which can be traded or sold.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
            

                {/* 3 */}
                <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/23.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Music & Entertainment
                          </h3>
                         <p>Artists can mint their work as NFTs, offering fans exclusive ownership of music, albums, or performances.

                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 4 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/24.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Real Estate
                          </h3>
                         <p>Tokenize real estate assets and list them on an NFT platform for fractional ownership or entire property sales.

                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 5 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/marketplace/25.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Fashion
                          </h3>
                         <p> Sell and trade virtual clothing or accessories as NFTs in the metaverse.
                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 8 */}
              

               
               {/* 9 */}
         
          </div>
         </div>

        </section>

    {/* 6 */}

    <section className='bgleft'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Exploring the Use Cases of Our NFT Marketplace Development

                            </h3>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
               <div className='col-12 col-md-4'>
                     <div className='card Industry-blockchain1'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/marketplace/26.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Virtual Real Estate
                          </h3>
                          <p>Tokenize virtual lands and properties for metaverse platforms. Users can buy, sell, and rent virtual spaces, allowing for creative monetization of digital landscapes.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-4'>
                     <div className='card Industry-blockchain1'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/marketplace/27.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Licensing and Intellectual Property
                          </h3>
                          <p>Companies can tokenize their intellectual property rights as NFTs, providing a secure and transparent way to manage, trade, and license digital assets.
                          .
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-4'>
                     <div className='card Industry-blockchain1'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/marketplace/28.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Supply Chain and Logistics
                          </h3>
                          <p> NFTs can represent unique items in a supply chain, tracking their movement and verifying authenticity across distributed ledgers for industries like luxury goods or pharmaceuticals.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-4'>
                     <div className='card Industry-blockchain1'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/marketplace/29.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Gaming Items
                          </h3>
                          <p> Gamers can trade in-game assets like skins, characters, or weapons as NFTs, creating a robust digital economy within games.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                {/* 9 */}
                <div className='col-12 col-md-4'>
                     <div className='card Industry-blockchain1'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/marketplace/30.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>NFT-Based Crowdfunding
                          </h3>
                          <p>Raise funds for new projects or startups by issuing fractional NFTs that represent ownership or future benefits.
                          .
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
               
                {/* 10 */}
                     {/* 9 */}
            
             
          </div>
        
                </div>

              </section>



              <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>NFT Marketplace Trends to Watch
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-md-4 col-12'>
                            <div className='cardNm core-cardNm'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/marketplace/31.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>NFTs in DeFi</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> The integration of NFTs with DeFi (Decentralized Finance) protocols is on the rise, enabling users to stake their NFTs, use them as collateral, or participate in yield farming with NFTs.
                                </p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardNm core-cardNm'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/marketplace/32.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Soulbound Tokens (SBTs)</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> These non-transferable NFTs are emerging as a way to represent long-term identity traits like achievements, qualifications, or memberships on the blockchain.
                                </p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardNm core-cardNm'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/marketplace/33.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Green NFTs</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> With concerns around the environmental impact of blockchain mining, eco-friendly solutions using energy-efficient blockchains like Tezos or Polygon are gaining traction.
                                </p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12' >
                            <div className='cardNm core-cardNm'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/marketplace/34.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>NFT Subscription Models</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> New platforms are exploring subscription-based models where users gain access to exclusive NFTs on a recurring basis, revolutionizing the way content creators engage with their audience.
                                </p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='cardNm core-cardNm'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/marketplace/35.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>AI-Generated NFTs</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Combining artificial intelligence with NFTs, platforms are offering unique digital assets that evolve or change based on AI algorithms, making each piece one-of-a-kind and dynamically mutable.
                                </p>

                            </div>
                        </div>

                        {/* 6 */}
                       


                    </div>

                </div>
            </section>




    <section className='bgleft'>
    <div className="wrapper">
  <h1>Revenue Streams From an NFT Marketplace

  </h1>
  <div class="cols">
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
                          
							<span>Transaction Fees
                            </span>
                         <img src="./Assets/marketplace/36.png" alt="" width="150px" />

						</div>
					</div>
					<div class="back">
						<div class="inner">
						  <span>A percentage of every sale or trade made on the platform.

                          </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Listing Fees
                            </span>
                            <img src="./Assets/marketplace/37.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Charging creators to list their nfts for sale.

                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Premium Memberships
                            </span>
                            <img src="./Assets/marketplace/38.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Offering advanced features or exclusive access for a monthly or yearly subscription fee.

                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Auction Fees
                            </span>
                            <img src="./Assets/marketplace/39.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span> Earning from high-value auctions or premium NFT sales.

                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Advertising
                            </span>
                            <img src="./Assets/marketplace/40.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Allowing brands to advertise their blockchain investment products or services within the marketplace.

                            </span>
						</div>
					</div>
				</div>
			</div>
		
		</div>
 </div>
 </section>
    {/* 7 */}
  

    {/* slider */}


    {/* <section className='bgright py-2'>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-box-sec text-center  py-4">
              <h3>
                NFT Development on Various Blockchain Networks  (pending)
              </h3>
              <div className='line3'></div>
            </div>
          </div>


        </div>


       
      </div>
    </section> */}

            <section className='bgright'>
          <div className='container'>
         
          <div className='row align-items-center'>
          <div className='col-12 col-md-6'>
          <h4 style={{fontSize:"35px", color:"#fff"}}>Why Should You Choose Blockcoaster for NFT Marketplace Development?</h4>

               {/* <div class="why-img2">
               <img src="./Assets/smart-contract/smarcontract-dev/needservice.png" className='img-fluid' alt="" />
                  </div>    */}
               </div>
               <div className='col-12 col-md-6 pt-3'>
               <div class="heading-box1">
               
                    <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>Blockchain Expertise <br/>
                    <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/> Proven Track Record<br/>
                    <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>  Comprehensive Support<br/>
                    <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/> Security and Compliance<br/>
</p>
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
             
          </div>
          </div>
      </section>
     {/* 8 */}
  
   {/* 9 */}
   <section className='bgleft'>
  
  <div className="container py-3">
 <div className="heading-box text-center ">
   <h3 className="mb-3">FAQ</h3>
 </div>
 {/* 1 */}
 <div className="accordion accordion-flush" id="accordionFlushExample">
   <div className="accordion-item rounded-3 border-0 shadow mb-2">
     <h2 className="accordion-header">
       <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
       What is an NFT marketplace?

       </button>
     </h2>
     <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
       <div className="accordion-body">
         <p>An NFT marketplace is a platform where users can buy, sell, and trade non-fungible tokens (NFTs) through blockchain technology.
         </p>
       </div>
     </div>
   </div>
   {/* 2 */}
   <div className="accordion-item rounded-3 border-0 shadow mb-2">
     <h2 className="accordion-header">
       <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
       Which blockchain is best for NFT marketplaces?

       </button>
     </h2>
     <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
       <div className="accordion-body">
         <p>Ethereum is the most widely used blockchain for NFTs, but Binance Smart Chain and Tron are also popular due to lower transaction costs.
         </p>
       </div>
     </div>
   </div>
   {/* 3 */}
   {/* <div className="accordion-item rounded-3 border-0 mb-2 shadow">
     <h2 className="accordion-header">
       <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
         How can I contact Blockcoaster for business inquiries?
       </button>
     </h2>
     <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
       <div className="accordion-body">
         <p>You can contact us through our contact form on Blockcoaster or via email blockcoasterrr@gmail.com.</p>
       </div>
     </div>
   </div> */}
   {/* 4*/}
   <div className="accordion-item rounded-3 border-0 mb-2 shadow">
     <h2 className="accordion-header">
       <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
       How long does it take to develop an NFT marketplace?

       </button>
     </h2>
     <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
       <div className="accordion-body">
         <p>The development timeline depends on the complexity and features required. On average, it can take 4-8 weeks.</p>
       </div>
     </div>
   </div>
   {/* 5 */}
   <div className="accordion-item rounded-3 border-0 mb-2 shadow">
     <h2 className="accordion-header">
       <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
       What are the costs involved in creating an NFT marketplace?

       </button>
     </h2>
     <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
       <div className="accordion-body">
         <p>Costs vary based on platform features, blockchain choice, and customizations. Contact us for a detailed quote.
         </p>
       </div>
     </div>
   </div>
   {/* 6*/}
   <div className="accordion-item rounded-3 border-0 mb-2 shadow">
     <h2 className="accordion-header">
       <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
       Can I customize the NFT marketplace?

       </button>
     </h2>
     <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
       <div className="accordion-body">
         <p>Yes, our solutions are fully customizable to meet your specific business needs and goals.
         </p>
       </div>
     </div>
   </div>
   {/* 7--*/}
 </div>
</div>

</section>

  </Layout>
  )
}

export default NMarket
