import React from 'react'
import Layout from '../Layout/Layout'
import '../Metaverse-Virtual/MetaVirtualevent.css'
export const MetaVirtualevent = () => {
  return (
    <Layout>
    <section className="bannerMV">
      {/* <div class="video_fadeoverlay"></div> */}
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-6">
            <div className="banner-caption2">
              <h1> <span className=''></span>Metaverse Virtual Working Space Solutions

              </h1>
              <p>Step into the future of work with our Metaverse Virtual Working Space solutions. Create a seamless, immersive environment where your team can collaborate, innovate, and connect, regardless of physical location. Experience the evolution of work with the power of the Metaverse, driving productivity and engagement to new heights.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 text-center">
            <div className="why-img-banner">
              <img src="./Assets/metaverse/meta-virtual/22222.png" className='img-fluid' alt="" />

            </div>
          </div>
        </div>
      </div>
    </section>


    {/* 3 */}
    <section className='bgrightMV'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div class="heading-box">
              <h3>Our Services

              </h3>
              {/* <div class="line2"></div> */}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 col-md-4">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/metaverse/meta-virtual/1.png" alt="" />
              </div>
              <h3>Custom Virtual Office Design</h3>

              <p>Tailored virtual spaces to reflect your company’s unique culture and objectives.

              </p>
            </div>
          </div>
          {/* 2 */}  <div className="col-12 col-md-4">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/metaverse/meta-virtual/2.png" alt="" />
              </div>
              <h3>Collaborative Tools Integration</h3>
              <p>Empower your team with advanced tools for communication, meetings, project management, and brainstorming within a virtual environment.

              </p>
            </div>
          </div>

          {/* 3 */}
          <div className="col-12 col-md-4">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/metaverse/meta-virtual/3.png" alt="" />
              </div>
              <h3>Immersive Environment Creation</h3>
              <p>Develop 3D virtual offices, meeting rooms, and communal areas that enhance interaction and foster collaboration.

              </p>
            </div>
          </div>
          {/* 4 */}
          <div className="col-12 col-md-4">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/metaverse/meta-virtual/4.png" alt="" />
              </div>
              <h3>Blockchain Security Integration
              </h3>
              <p>Secure virtual working spaces using blockchain technology, ensuring data integrity and secure transactions in a decentralized environment.

              </p>
            </div>
          </div>
          {/* 5 */}
          <div className="col-12 col-md-4">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/metaverse/meta-virtual/5.png" alt="" />
              </div>
              <h3>VR & AR Integration
              </h3>
              <p> Leverage virtual reality (VR) and augmented reality (AR) tools to enrich the virtual workspace experience.


              </p>
            </div>
          </div>
          {/* 6 */}
          <div className="col-12 col-md-4">
            <div className='card nft-card-Dev'>
              <div className='top_line'></div>
              <div className='right_line'></div>
              <div className="nftdev-img">
                <img src="./Assets/metaverse/meta-virtual/6.png" alt="" />
              </div>
              <h3>Employee Training & Support
              </h3>
              <p> Offering training modules and continuous support to help teams navigate and maximize the potential of virtual workspaces.


              </p>
            </div>
          </div>

        </div>
      </div>
    </section>

    {/* 4 */}
    <section className='bgleftMV'>
         <div className='container-fluid'>
          <div className='row'>
               <div className='col-12'>
               <div class="heading-box">
                    <h3>Key Features
                    </h3>
                    {/* <div class="line2"></div> */}
                  </div>    
               </div>
          </div>
          <div className='row justify-content-center'>
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/7.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Virtual Collaboration Rooms
                          </h3>
                         <p>Create meeting rooms, discussion zones, and brainstorming spaces that simulate real-world office environments.



                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 2 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/8.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Real-time Interaction
                          </h3>
                         <p>Employees can communicate and collaborate seamlessly in real-time using avatars and live chat.


                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
            

                {/* 3 */}
                <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/9.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Customizable Avatars
                          </h3>
                         <p>Personalize avatars to reflect the individuality of employees, making interactions more engaging and dynamic.

                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 4 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/10.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Cross-platform Access
                          </h3>
                         <p> Access your virtual workspace from any device, whether it’s VR headsets, desktop, or mobile.


                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>

               {/* 5 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/11.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Secure Virtual Transactions
                          </h3>
                         <p> Using blockchain technology, our spaces ensure secure, encrypted interactions within the workspace.



                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
               {/* 8 */}
              

               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/12.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>3D Spatial Audio
                          </h3>
                         <p>Realistic spatial sound for immersive meetings, making the virtual experience as close to reality as possible.



                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
               {/* 9 */}
               <div className='col-12 col-md-5'>
               <div class="card type-card">
                    
                    <div class="row">
                    <div class="col-3">
                    <div class="type-icon">
                          <img src="./Assets/metaverse/meta-virtual/13.png" alt="" />
                    </div>
                    </div>
                    <div class="col-9">
                    <div class="type-caption">
                          <h3>Digital Whiteboards and Meeting Tools
                          </h3>
                         <p> Interactive tools for collaboration, note-taking, and sharing documents within virtual spaces.



                         </p>
                    </div>
                    </div>
                    </div>
                  </div>    
               </div>
          </div>
         </div>

        </section>
       
 
        <section className='bgrightMV'>
                    <div className='container'>
                    <div className="row">
                            <div className="col-12">
                                <div className="heading-box-sec text-center py-5">
                                <h3>Why Build a Virtual Workspace in the Metaverse?


                                </h3>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/metaverse/meta-virtual/144.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Access Global Talent
                              </h3>
                              <p>Hire and collaborate with top talent from anywhere, without geographical barriers.


                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
    
                   {/* 2 */}
                
                   {/* 3 */}
             
    
             
                   {/* 6 */}
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/metaverse/meta-virtual/155.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Reduce Overheads
                              </h3>
                              <p>Cut down on physical office expenses and maintenance while still offering a productive work environment.


                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
    
                   {/* 7 */}
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/metaverse/meta-virtual/16.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Enhance Work-Life Balance

                              </h3>
                              <p> Create a flexible, immersive environment that adapts to various work styles, promoting work-life balance.


                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
    
                   {/* 8 */}
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/metaverse/meta-virtual/17.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Boost Employee Engagement
                              </h3>
                              <p>Virtual workspaces enhance employee engagement and team bonding through dynamic virtual environments.

                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
                    {/* 9 */}
                    <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/metaverse/meta-virtual/18.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Foster Innovation
                              </h3>
                              <p>Metaverse-based workspaces promote creativity and innovation by offering new ways to collaborate and engage.


                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
                   
                    {/* 10 */}
                         {/* 9 */}
                
                 
              </div>
            
                    </div>
    
                  </section>
    {/* 5 */}
    <section className='bgleftMV'>
    <div className="container">
    <div className="row">
          <div className="col-12">
            <div className="heading-box-sec text-center py-4">
              <h3>Key Benefits of Metaverse Virtual Working Spaces

              </h3>
            </div>
          </div>
        </div>
      <ul className="grid-testimonials">
          <li>
          <span className="grid-testimonials-citation"></span>
              <div><p>Increased productivity through immersive tools and environments.

             </p><span className="quote-arrow"></span></div>
          </li>
          <li>
          <span className="grid-testimonials-citation"></span>
              <div><p>Global accessibility for seamless collaboration with remote teams and clients.

              </p><span className="quote-arrow"></span></div>
          </li>
          <li>
          <span className="grid-testimonials-citation"></span>
              <div><p>Scalable solutions that grow with your business.

              </p><span className="quote-arrow"></span></div>
          </li>
          <li>
          <span className="grid-testimonials-citation"></span>
              <div><p> Sustainability by reducing office energy consumption and carbon footprint.

              </p><span className="quote-arrow"></span></div>
          </li>
          <li>
          <span className="grid-testimonials-citation"></span>
              <div><p>Immersive learning experiences for employee training and development.

              </p><span className="quote-arrow"></span></div>
          </li>
          <li>
          <span className="grid-testimonials-citation"></span>
              <div><p> Enhanced collaboration with customizable meeting rooms and real-time tools.

              </p><span className="quote-arrow"></span></div>
          </li>                                                                      
      </ul>
  </div>  
</section>

<section className='bgrightMV'>
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <div className="heading-box-sec text-center py-2">
                                   <h3>Why Choose Us for Your Metaverse Virtual Workspace Development?

                                   </h3>
                               </div>
                           </div>
                       </div>
                       <div className="row">
                     
                           <div className="col-12 col-md-6">
                               <div className="caption-list mt-md-5">
                      <p>
                      <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>Expertise in Virtual World Development<br/>
                      <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>  End-to-End Solutions<br/>
                      <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/> Customized Solutions <br/>
                      <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>Cutting-edge Technology <br/>
                      <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>Scalable Platforms <br/>
                      <img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/>Comprehensive Support

                      </p>
   
                                       {/* 2 */}
   
                                       {/* 3 */}
                                       {/* 4 */}
                               </div>
                           </div>
                           <div className="col-12 col-md-6 text-center">
                         <div className="qstimg">
                            <img src="./Assets/metaverse/meta-virtual/irtual.png" className='img-fluid' alt="metaverse" />
                         </div>
                           </div>
                       </div>
                   </div>
               </section>
  </Layout>
  )
}
