import React from 'react'
import { Link } from 'react-router-dom';
// import Navbar from '../header/Navbar'
// import Footer from '../Footer/Footer'
// import icon from '../Assets/icon_1.png'
import '../MainPage/main.css'
import TypingEffect from '../typetext/Typetext'
import Layout from '../Layout/Layout'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

// import icon1 from "../Assets/icon_1.png"

const Main = () => {

  return (
    <>
      <Layout>

        <div className="main-box">
          {/* banner2 */}

          
             <section className="banner2">
             <video autoplay="true" muted="true" loop="true">
  <source src="./Assets/metaverse/VrVideo1.mp4" type="video/mp4" />
  <source type="./Assets/metaverse/VrVideo1.mp4" src="video.mp4"></source>

</video>
               <div className="container">
                 <div className="row">
                   <div className="col-12 col-md-6">
                         <div className="banner-caption">
                           <h1>Building a Decentralized Ecosystem with Blockchain and AI Innovation</h1>
                           <p>Empowering businesses with advanced digital <br/>
                           tools and innovative solutions to lead in a rapidly <br/>
                              evolving world.</p>

                               <div className="banner-service">
                                    <div className="banner-service-box">
                                       <p>08+  </p>
                                        <p>  Years  in Blockchain
                                        </p>
                                    </div>
                                     <div className="banner-line"></div>
                                    {/* 2 */}
                                    <div className="banner-service-box">
                                       <p>100+  </p>
                                        <p> Employees
                                        </p>
                                    </div>
                                    <div className="banner-line"></div>
                                    {/* 3 */}
                                    <div className="banner-service-box">
                                       <p>80+  </p>
                                        <p>Projects
                                        </p>
                                    </div>
                                    <div className="banner-line"></div>
                                    {/* 4 */}
                                    <div className="banner-service-box">
                                       <p>50+  </p>
                                        <p>Blockchain Experts
                                        </p>
                                    </div>
                               </div>
                               <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>                 
                         </div>
                        
  </div>

                    {/* <div className="col-12 col-md-6 text-center">
                      <div className="banner-img">
                        <img src="./Assets/banner-sideimg.png" className='img-fluid' alt="" />
                        
                      </div>
                    </div> */}
                 </div>
               </div>
             </section>
          

          <div>
            {/* social-fixed */}
            <div className="icon-bar">
              <a href="https://www.facebook.com/people/Blockcoaster/61555764877706/" className="facebook"><i className="bx bxl-facebook" /></a>
              <a href="https://twitter.com/blockcoasterrr" className="twitter"><i className="bx bxl-twitter" /></a>
              {/* <a href="" class="google"><i class='bx bxl-linkedin'></i></a>  */}
              <a href="https://www.instagram.com/blockcoasterrr/" className="linkedin"><i className="bx bxl-instagram" /></a>
              <a href="https://www.youtube.com/channel/UC5QMgZo8zFNLDu3juwzMuaQhttps://www.instagram.com/blockcoasterrr/" className="youtube"><i className="bx bxl-youtube" /></a>
            </div>
            {/* quick btn */}
            <div className="icon-bar-c" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <p>Quick Contact</p>
            </div>
            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="cus-header-modal">
                    {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body cus-modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="card cus-contact-card p-4 border-0">
                          {/* <div class="content-form card p-md-4 p-4"> */}
                          <form method="post" action name="contactform" id="contactform">
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">Full Name</label>
                              <input type="text" className="form-control cus-form" name="fullname" id="fullname" aria-describedby />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                              <input type="email" className="form-control cus-form" name="email" id="email" aria-describedby />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">Phone Number</label>
                              <input type="text" className="form-control cus-form" name="phonenumber" id="phonenumber" aria-describedby />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlTextarea1" className="form-label">Message
                              </label>
                              <textarea className="form-control cus-form" name="message" id="message" rows={3} defaultValue={""} />
                            </div>
                            <div className="text-center">
                              <input type="submit" defaultValue="send message" className="btn cus-btnsubmit" id="submit" data-bs-dismiss="modal" />
                            </div>
                          </form>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  <button type="button" class="btn btn-primary">Save changes</button>
</div> */}
                </div>
              </div>
            </div>
            {/* end */}
          </div>

          {/* end */}

          {/* 2  About Blockchain*/}

          <section className='bggrd'>
            <div class="container py-5">
              <div class="row">
                <div class="col-12">
                  <div class="heading-boxBM">
                    <h3>About Blockcoaster </h3>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-12">
                  <div class="block-box">
                    <p>Blockcoaster is your trusted partner in navigating the complexities of blockchain technology. We specialize in delivering comprehensive solutions across AI development, metaverse platforms, Web3 integration, and DeFi ecosystems. From building secure blockchain networks to crafting immersive virtual reality experiences, our expertise spans the digital landscape. Whether you’re looking to develop a crypto exchange, implement decentralized finance, or launch a next-gen crypto wallet, Blockcoaster combines cutting-edge artificial intelligence with blockchain to drive your business forward. We’re not just a service provider—we’re pioneers in creating the future of cryptocurrency, blockchain development, and decentralized innovation.
                    </p>
                  </div>


                </div>
              </div>
            </div>
          </section>



{/* slider */}

<section className='bggr2'>

   <div className="container">
   <div class="row py-1">
                <div class="col-12">
                  <div class="heading-box">
                    <h3> Range of Services</h3>
                    <div class="line2"></div>
                  </div>
                </div>
              </div>
   <Swiper
     modules={[Navigation, Pagination, Scrollbar, A11y]}
     spaceBetween={-40}
     slidesPerView={3}
     centeredSlides={true}
     navigation
     pagination={{ clickable: true }}
     scrollbar={{ draggable: true }}
     onSwiper={(swiper) => console.log(swiper)}
     loop={true}  // Enable looping
     onSlideChange={() => console.log('slide change')}
     breakpoints={{
      320: {  // Screens with a width of 320px or more
        slidesPerView: 1,  // Show 1 slide
        centeredSlides: true,  // Center the slide
      },
      768: {  // Screens with a width of 768px or more (e.g., tablets)
        slidesPerView: 2,  // Show 2 slides
      },
      1024: {  // Screens with a width of 1024px or more (e.g., desktops)
        slidesPerView: 3,  // Show 3 slides
      },
    }}
      
     
>
<SwiperSlide className='my-sliderD'style={{backgroundColor:"#210842" , height:"400px"}}>
        
        <div className="our-service" >
                 <div className="our-img-box">
                   <img src="./Assets/our-icon/1.png" alt />
                 </div>
                 <div className="card-body cus-card-body text-justify">
                   <h3>Blockchain Development</h3>
                   <p>Blockcoaster specializes in end-to-end blockchain development. We build secure, decentralized solutions for businesses, driving innovation and enhancing trust in the digital ecosystem.
                   </p>
                 </div>
                </div>
        </SwiperSlide>
      <SwiperSlide className='my-slider'  style={{backgroundColor:"#210842", height:"400px"}}>
      <div className="our-service">
                  
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/2.png" alt />
                  </div>
                  <div className="card-body cus-card-body text-justify">
                    <h3>NFT Development </h3>
                    <p>We offer top-notch NFT development services. Aimed to create and facilitate NFT platforms purely robust
                      and secure with a wealth of unparalleled experience with the added advantage of launching your own NFT
                      business, powered by our team of experts.
                    </p>
                  </div>
                </div>
      </SwiperSlide>
  
        

        <SwiperSlide className='my-slider'  style={{backgroundColor:"#210842", height:"400px"}}>
        <div className="our-service">
                   
                   <div className="our-img-box " >
                     <img src="./Assets/our-icon/3.png" alt />
                   </div>
                   <div className="card-body cus-card-body text-justify">
                     <h3>Cryptocurrency Development</h3>
                     <p>At Blockcoaster, we spend significant time creating a custom cryptocurrency, developing a secure
                       wallet, or implementing blockchain-based applications which are lined up with industry principles and
                       best practices.
                     </p>
                   </div>
                 </div>
        
        </SwiperSlide>

        <SwiperSlide className='my-slider' style={{backgroundColor:"#210842", height:"400px"}}>
        
        <div className="our-service">
                  
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/4.png" alt />
                  </div>
                  <div className="card-body cus-card-body text-justify">
                    <h3>Metaverse Development </h3>
                    <p>Our expert team crafts immersive metaverse experiences by integrating virtual reality, augmented reality, and blockchain. From concept to execution, we create unique digital ecosystems that drive engagement, creativity, and opportunities.
                    </p>
                  </div>
                </div>
        </SwiperSlide>

        <SwiperSlide className='my-slider' style={{backgroundColor:"#210842", height:"400px"}}>
        
        <div className="our-service" >
                 
                 <div className="our-img-box">
                   <img src="./Assets/our-icon/5.png" alt />
                 </div>
                 <div className="card-body cus-card-body text-justify">
                   <h3>Web3 Development</h3>
                   <p>We harness blockchain and decentralized technologies to develop secure web platforms, specializing in dApps, smart contract integration, and seamless user experiences, empowering organizations to maximize blockchain’s potential.
                   </p>
                 </div>
               </div>
        </SwiperSlide>

        <SwiperSlide className='my-slider' style={{backgroundColor:"#210842", height:"400px"}}>
        
        <div className="our-service" >
                  
                    <div className="our-img-box">
                      <img src="./Assets/our-icon/6.png" alt />
                    </div>
                    <div className="card-body cus-card-body text-justify">
                      <h3>DEFI Development</h3>
                      <p>Our services offer businesses and individuals access to novel technology in intelligent ways with
                        decentralized finance solutions. With our DEFI services, users can explore a wide range of decentralized
                        financial instruments and unlock new opportunities in the circulated finance landscape.
                      </p>
                    </div>
                  </div>
        </SwiperSlide>

         {/* 7 */}
         <SwiperSlide className='my-slider'  style={{backgroundColor:"#210842", height:"400px"}}>
         <div className="our-service">
                    
                    <div className="our-img-box">
                      <img src="./Assets/our-icon/7.png" alt />
                    </div>
                    <div className="card-body cus-card-body text-justify">
                      <h3>DAO Services </h3>
                      <p>Our team has practical experience in creating and developing smart contracts, voting mechanisms, and
                        DAO frameworks, ensuring transparent decision-making and efficient collaboration within the
                        organization, and fostering forward-thinking solutions.</p>
                    </div>
                  </div>
        </SwiperSlide>


        {/* 8 */}



        {/* 9 */}
        <SwiperSlide className='my-slider' style={{backgroundColor:"#210842", height:"400px"}}>
        
        <div className="our-service">
                 
                 <div className="our-img-box">
                   <img src="./Assets/our-icon/8.png" alt />
                 </div>
                 <div className="card-body cus-card-body text-justify">
                   <h3>DApp Solutions</h3>
                   <p>Revolutionize your business and enhance user experiences with our DApp solutions, where clients can
                     create secure, transparent, and efficient applications. We also assist companies with actionable
                     insights to have data-driven outcomes.
                   </p>
                 </div>
                </div>
        </SwiperSlide>
        {/* 10 */}

        <SwiperSlide className='my-slider'style={{backgroundColor:"#210842", height:"400px"}}>
        
        <div className="our-service">
                 
                 <div className="our-img-box">
                   <img src="./Assets/our-icon/9.png" alt />
                 </div>
                 <div className="card-body cus-card-body text-justify">
                   <h3>Smart Contract Development</h3>
                   <p>
                   We create reliable and self-executing smart contracts for various applications. Blockcoaster ensures automation, transparency, and security in all your blockchain transactions.
                   </p>
                 </div>
                </div>
        </SwiperSlide>
     
      ...
    </Swiper>
   </div>
</section>


<section className='bggrd'>
           <div className="container">
              <div className="row justify-content-center"> 
                <div className="col-12 col-md-6">
                  <div className="cardt pramotion-card">
                      <div className="pramotion-img">
                             <img src="./Assets/icon-blockchain/Marketing-p.png" alt="" />
                      </div>

                       <h3>Marketing & Promotion</h3>
                       <p>Blockcoaster provides end-to-end marketing solutions to blockchain, DeFi, metaverse, gaming, and cryptocurrency companies. We blend a deep understanding of blockchain business with winning growth strategies, seamless execution, and measurable results that amplify your brand and rapidly build community. Striking the right balance between budget and quality results is our forte. We can be your trusted guide in the terrain of blockchain and cryptocurrency marketing and promotion.</p>
                     
                </div>
                </div>
                {/* 2 */}
                <div className="col-12 col-md-6">
                  <div className="cardt pramotion-card">
                      <div className="pramotion-img">
                             <img src="./Assets/icon-blockchain/Marketing-c.png" alt="" />
                      </div>

                       <h3>Connecting with Investors</h3>
                       <p>Are you looking for seed funding, venture capital, or want to raise funds through an IDO? We have the network. Among investors, fundraising teams, and fundraising platforms, our team of experts will refine presentations of your business model and design the right fundraising strategy for your project. We believe funding is the key to transforming a solid concept into a globally successful platform.</p>
                     
                </div>
                </div>
              </div>
           </div>
         </section>


          {/* <!-- 3 -->
          <!-- our service --> */}


          {/* <!-- end --> */}

          {/* 4 */}
       

        </div>

        {/* 5 */}
        {/* 5        Trend of NFT Marketplace */}
        {/* <section className="bggrd py-4">
          <div className="container">
            <div className="row ">
              <div className="col-12">
                <div className="heading-box-sect mb-3">
                  <h3>Sectors That Evolve with The</h3>
                  <h1>Trend of NFT Marketplace</h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-6 col-md-3">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR SPORTS.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Sports
                    </p>
                  </div>
                </div>
              </div>
      
              <div className="col-md-3 col-6">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR MUSIC.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Music
                    </p>
                  </div>
                </div>
              </div>
              {
              <div className="col-md-3 col-6">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR FASION.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Fashion
                    </p>
                  </div>
                </div>
              </div>
      
              <div className="col-md-3 col-6">
                <div className="card cus-card-th ">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR VIDEOS.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Videos
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-6 col-md-3">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR MEMES.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Memes
                    </p>
                  </div>
                </div>
              </div>
       
              <div className="col-md-3 col-6">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR DOMAIN.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Domain
                    </p>
                  </div>
                </div>
              </div>
       
              <div className="col-md-3 col-6">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR REAL ESTATE.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Real Estate
                    </p>
                  </div>
                </div>
              </div>
            \
              <div className="col-md-3 col-6">
                <div className="card cus-card-th">
                  <div className="our-img-box">
                    <img src="./Assets/our-icon/NFT FOR CONTENT.png" alt />
                  </div>
                  <div className="card-body card-body-th">
                    <p>NFT for Content
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}



        {/* < end- -5-> */}

        {/* 6 */}
        {/* <section className="grow-b">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <div className="gro-img-box">
                  <div className="ofs-img">
                    <img src="./Assets/our-icon/image 1.png" className="img-fluid" alt />
                  </div>
                  <div className="ofs-img-t">
                    <img src="./Assets/our-icon/image 2.png" className="img-fluid" alt />
                    <div className="ofs-content">
                      <p>Accelerate Your Business Growth in the Digital Age with Blockcoaster: The Ultimate Solution for
                        Success!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}



        {/* 7 *  Watch Our NFT Demo
/}
        {/* <section>
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-12 p-0 text-center">
                <div className="gro-img-box">
                  <div className="ofs-img-tm">
                    <img src="./Assets/our-icon/image 4.png" className="img-fluid" alt />
                    <div className="ofs-contentt">
                      <h3>Watch Our NFT Demo</h3>
                    </div>
                  </div>
                  <div className="ofs-img-tt">
                    <img src="./Assets/our-icon/image 3.png" className="img-fluid w-75" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* 
  7 end */}
        {/* 8 */}
          {/* new  */}
          <section className='bggrd'>
            <div class="container py-3">
              <div class="row">
                <div class="col-12">
                  <div class="heading-box">
                    <h3>Our Blockchain Tech Expertise</h3>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-12">
                   <div className="logoexchange">
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/eth.png" alt="" />
                            <p>Ethereum</p>
                      </div>
                      {/* 2 */}
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/Binance-1.png.png" alt="" />
                            <p>BNB Chain</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/tron.svg" alt="" />
                            <p>Tron</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/Solana-3.png.png" alt="" />
                            <p>Solana</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/ton.png" alt="" />
                            <p>TON</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/Cardano.png" alt="" />
                            <p>Cardano</p>
                      </div>
                      
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/polkadot-dot-logo-ED40B3633D-seeklogo.com_.png" alt="" />
                            <p>Polkadot</p>
                      </div>
                   {/* </div>

                </div> */}

                {/* 2 */}
                {/* <div class="col-12"> */}
                   {/* <div className="logoexchange"> */}
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/polygon-matic-logo-1.png" alt="" />
                            <p></p>Polygon
                      </div>
                      {/* 2 */}
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/optimism-ethereum-op-logo.png" alt="" />
                            <p>Optimism</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/arbitrum-arb-logo.png" alt="" />
                            <p>Arbitrum
                            </p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/Chainlink-Symbol-Blue.png" alt="" />
                            <p>Chainlink</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/Hyperledger.png.png" alt="" />
                            <p>Hyperledger</p>
                      </div>
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/uniswap-uni-logo.png" alt="" />
                            <p>Uniswap</p>
                      </div>
                      
                      <div className="logoexchangebox">
                           <img src="./Assets/icon-blockchain/ethereum-evm.png" alt="" />
                            <p>Ethereum Virtual Machine</p>
                      </div>
                   </div>

                </div>
              </div>
     </div>
          </section>

           {/* end */}
        
        













        {/* 9 */}

        {/* why choose us */}
        <section className="bggr2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 ">
                <div className="why-img">
                  <img src="./Assets/our-icon/1pp.png" style={{marginTop:"70px"}} className="img-fluid" alt />
                </div>
              </div>
              <div className="col-12 col-md-8 ">
                 <div className="heading-box">
                 <h3>Why Choose Us?</h3>
                 </div>
                <div className="why-caption-box">
               
                  <p>Banking on legacy industry networks with a stand-out creative direction as we intend to provide tailored
                    solutions that align with their business goals. </p>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img src="./Assets/our-icon/101.png" className="li-box-img" alt="..." />
                    </div>
                    <div className="flex-grow-1 ms-3 media-caption">
                      <p className="mb-0">Technical Expertise</p>
                      <p>We specialize in advanced blockchain, AI, and Web3 solutions, ensuring your project is built with precision, scalability, and top-tier security.
                      </p>
                    </div>
                  </div>
                  {/* 2 */}
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img src="./Assets/our-icon/101.png" className="li-box-img" alt="..." />
                    </div>
                    <div className="flex-grow-1 ms-3 media-caption">
                      <p className="mb-0">Proven Strategies</p>
                      <p>Our winning strategies combine innovation with practical execution, ensuring measurable results that align with your goals.
                      </p>
                    </div>
                  </div>
                  {/* 3 */}
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img src="./Assets/our-icon/101.png" className="li-box-img" alt="..." />
                    </div>
                    <div className="flex-grow-1 ms-3 media-caption">
                      <p className="mb-0">
                      End-to-End Support</p>
                      <p>From concept to launch, we provide full-spectrum support, guiding you every step of the way with expertise in marketing, investor connections, and product development.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* 10 Industry Applications fro Blockchain Technology */}
        {/* <section className="my-4">
          <div className="container mb-5">
            <div className="row py-4">
              <div className="col-12">
                <div className="heading-box-new">
                  <h3>Industry Applications from Blockchain Technology</h3>
                  <p>The versatility and inherent security of blockchain make it a powerful tool for innovation and disruption
                    across multiple<br /> sectors. Unlock the full potential of blockchain technology and cultivate a
                    competitive edge in the rapidly evolving digital space.
                  </p>
                </div>
              </div>
            </div>
         
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR HEALTHCARE.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Healthcare</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR BANK & FINANCIAL.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Bank &amp; Financial</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR GOVERNMENT.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Government</p>
                  </div>
                </div>
              </div>
            </div>
       
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR INSURANCE.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Insurance</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR LOGISTICS.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Logistics</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/blockchain-for-agriculture.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Agriculture</p>
                  </div>
                </div>
              </div>
         
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR RETAILS.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Retails</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR ENTERTAINMENT.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Entertainment</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sm-4">
                <div className="card hover-box-card">
                  <img src="./Assets/our-icon/BLOCKCHAIN FOR PHARMA.png" className="img-fluid" alt />
                  <div className="overlay">
                    <p>Blockchain for Pharma</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <end> */}

        {/* 11 */} 

 

        <section className='bggrd'>
    
    <div className="container py-3">
   <div className="heading-box text-center ">
     <h3 className="mb-3">FAQ</h3>
   </div>
   {/* 1 */}
   <div className="accordion accordion-flush" id="accordionFlushExample">
     <div className="accordion-item rounded-3 border-0 shadow mb-2">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
           What is  Blockcoaster ?
         </button>
       </h2>
       <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Blockcoaster is a major blockchain solution provider contributing a wide range of services including AI
             development, Smart Contracts, DeFi solutions, and more.</p>
         </div>
       </div>
     </div>
     {/* 2 */}
     <div className="accordion-item rounded-3 border-0 shadow mb-2">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
           What services does Blockcoaster offer ?
         </button>
       </h2>
       <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>We serve in blockchain technology, AI development, smart contract creation, DeFi solutions, DApp
             development, and metaverse projects.</p>
         </div>
       </div>
     </div>
     {/* 3 */}
     {/* <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
           How can I contact Blockcoaster for business inquiries?
         </button>
       </h2>
       <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>You can contact us through our contact form on Blockcoaster or via email blockcoasterrr@gmail.com.</p>
         </div>
       </div>
     </div> */}
     {/* 4*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
           How does Blockcoaster ensure the security of blockchain data ?
         </button>
       </h2>
       <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p> We utilise cryptographic open-source code, decentralisation, and cold storage to provide the highest
             level of security for blockchain data.</p>
         </div>
       </div>
     </div>
     {/* 5 */}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
           What is DeFi and how does Blockcoaster support it ?
         </button>
       </h2>
       <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Blockchain technology powers DeFi, or decentralised finance, a financial service that does away with the
             need for conventional middlemen. Blockcoaster offers development and advisory services for a range of DeFi
             initiatives.</p>
         </div>
       </div>
     </div>
     {/* 6*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
           In what ways can AI be integrated into a Blockchain project?
         </button>
       </h2>
       <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p> AI can help blockchain projects by improving security, automating procedures, and analysing data
             intelligently. To learn how we can use AI to support your project, get in contact with us.</p>
         </div>
       </div>
     </div>
     {/* 7--*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSEVEN" aria-expanded="false" aria-controls="flush-collapseSEVEN">
           How can I stay updated with Blockcoaster's latest news and projects ?
         </button>
       </h2>
       <div id="flush-collapseSEVEN" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Follow us on social media to receive the latest updates, news, and perspectives on our projects.</p>
         </div>
       </div>
     </div>
     {/* 8-*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeaght" aria-expanded="false" aria-controls="flush-collapseeaght">
           What is token development, and how can Blockcoaster assist with creating and managing tokens ?
         </button>
       </h2>
       <div id="flush-collapseeaght" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p> The process of developing tokens on a blockchain for applications like trading or service access entails
             producing digital assets. Blockcoaster provides services such as managing token deployment, creating smart
             contracts, and designing tokenomics. We offer continuous assistance to ensure the smooth launch and token
             management.</p>
         </div>
       </div>
     </div>
   </div>
 </div>
 
 </section>

{/* 12 */}
<section className="bggr2 py-4">

    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="heading-box">
            <h4>Join Our Telegram Channel for Exclusive Updates!</h4>
            <div className="line2" />
          </div>
        </div>
      </div>
      <div className="row py-4">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <a href="https://t.me/+VoNcmPrCSS80ZDQ1" className="text-decoration-none">
              <button className="btn-tele">
                Telegram Link
                <span>
                
                   
                <i class="fa-brands fa-telegram"></i>
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

</section>




    </Layout >
    </>
  )
}

export default Main
