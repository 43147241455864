import React from 'react'
import Layout from '../Layout/Layout';
import '../Defi-Wallet/Wallet.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

function Wallet() {
  return (
    <Layout>
    <section className="banner3">
        {/* <div className='video_fadeoverlay'></div> */}

        <div className="container">
            <div className="row ">
                <div className="col-12 col-md-6">
                    <div className="banner-caption2">
                        <h1>DeFi Wallet Development Solutions
                        </h1>
                        <p>
                        Dive into the decentralized finance revolution with our tailored DeFi wallet development services. We craft secure, user-friendly wallets that integrate seamlessly with DeFi protocols, offering you control, transparency, and enhanced security for your digital assets.

                        </p>


                    </div>
                </div>

                <div className="col-12 col-md-6 text-center">
                    <div>
                        <img src="./Assets/Defi-wallet/4.png" className='img-fluid' alt="not" />

                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* 2 */}
    <section className='bgleft'>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div class="heading-box">
                        <h3>Expert DeFi Wallet Development Services
                        </h3>
                        {/* <div class="line2"></div> */}
                    </div>
                </div>
            </div>
            <div className='row align-items-center'>
                <div className='col-12 col-md-6 pt-3'>
                    <div class="heading-box-2">
                        <p>Our DeFi development services include the creation of advanced DeFi wallets designed to enhance your interaction with decentralized finance. We provide end-to-end wallet development, ensuring your solution is secure, efficient, and perfectly aligned with the latest DeFi technologies. Whether you need a wallet for trading, staking, or storing assets, our team delivers solutions that cater to your specific needs.

                        </p>
                        {/* <div class="line2"></div> */}
                    </div>
                </div>
                <div className='col-12 col-md-6 text-center'>
                    <div class="why-img">
                        <img src="./Assets/Defi-wallet/5.png" className='img-fluid' alt="not" />
                    </div>
                </div>
            </div>
        </div>
    </section>


 

    {/* 4 */}
    <section className='bgright'>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div class="heading-box">
                        <h3>The Vital Role of DeFi Wallets in the DeFi Ecosystem
                        </h3>
                        {/* <div class="line2"></div> */}
                    </div>
                </div>
            </div>
            <div className='row  align-items-center'>

                <div className='col-12 col-md-6 text-center'>
                    <div class="why-img">
                        <img src="./Assets/Defi-wallet/6.png" className='img-fluid' alt="not" />
                    </div>
                </div>
                <div className='col-12 col-md-6 pt-3'>
                    <div class="heading-box-2">
                        <p>DeFi wallets are crucial in the decentralized finance landscape, serving as the gateway for users to interact with DeFi applications and protocols. They facilitate secure storage and management of digital assets, enable seamless transactions, and provide access to various DeFi services like staking, yield farming, and liquidity provision. By integrating with DeFi protocols, these wallets ensure that users have full control and transparency over their assets, driving the growth and adoption of DeFi technologies.
                        </p>
                        {/* <div class="line2"></div> */}
                    </div>
                </div>
            </div>
        </div>
    </section>



    {/* <section className='bgleft'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <div class="heading-box">
                        <h3>Key Features of Our DeFi Wallet Solutions</h3>
                       
                    </div>
                </div>
            </div>
            <div className="row gutters">
<div className="col-12 col-md-8 mx-auto">
<div className="card timeline-card">
<div className="card-body">

  <div className="timeline">
    <div className="timeline-row">
     
      <div className="timeline-dot fb-bg" />
      <div className="timeline-content">
          <img src="./Assets/Defi-wallet/1.png" alt="" />
        <h4>Enhanced Security</h4>
        <p>Utilize advanced encryption and multi-signature features to protect user funds from unauthorized access.
        </p>
      
      </div>
    </div>
    <div className="timeline-row">
     
      <div className="timeline-dot green-one-bg" />
      <div className="timeline-content green-one">
      <img src="./Assets/Defi-wallet/2.png" alt="" />
        <h4>User-Friendly Interface</h4>
        <p>
        Intuitive design for easy management and interaction with DeFi applications.

        </p>
       
      </div>
    </div>
    <div className="timeline-row">
     
      <div className="timeline-dot green-two-bg" />
      <div className="timeline-content green-two">
      <img src="./Assets/Defi-wallet/3.png" alt="" />
        <h4>Multi-Asset Support</h4>
        <p>Manage a wide range of cryptocurrencies and tokens, including DeFi-specific assets.
        </p>
      
      </div>
    </div>
    <div className="timeline-row">
      
      <div className="timeline-dot green-three-bg" />
      <div className="timeline-content green-three">
      <img src="./Assets/Defi-wallet/44.png" alt="" />
        <h4>Seamless Integration</h4>
        <p>
        Connect effortlessly with various DeFi platforms for trading, staking, and lending.

        </p>
     
      </div>
    </div>
    <div className="timeline-row">
     
      <div className="timeline-dot green-four-bg" />
      <div className="timeline-content green-four">
      <img src="./Assets/Defi-wallet/55.png" alt="" />
        <h4>Backup & Recovery</h4>
        <p className="no-margin">Secure backup options and recovery processes to safeguard against data loss.
        </p>
        
      </div>
    </div>

  </div>
</div>
</div>
</div>
</div>
        </div>




    </section> */}


    {/* 6 */}
    <section className='bgright'>

        <div className="container">
            <div class="row py-1">
                <div class="col-12">
                    <div class="heading-box">
                        <h3>Popular DeFi Wallets in the Market</h3>
                        <div class="line2"></div>
                    </div>
                </div>
            </div>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={4}
                //   centeredSlides={true}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}  // Enable looping
                onSlideChange={() => console.log('slide change')}
                breakpoints={{
                    320: {  // Screens with a width of 320px or more
                        slidesPerView: 1,  // Show 1 slide
                        // Center the slide
                    },
                    768: {  // Screens with a width of 768px or more (e.g., tablets)
                        slidesPerView: 4,  // Show 2 slides
                    },
                    1024: {  // Screens with a width of 1024px or more (e.g., desktops)
                        slidesPerView: 4,  // Show 3 slides
                    },
                }}


            >
                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/122.png" className="img-fluid" alt="" />
                        <h3>MetaMask
                        </h3>
                    </div>

                </SwiperSlide>
                {/* 2 */}
                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/123.png" className="img-fluid" alt="" />
                        <h3>Trust Wallet
                        </h3>
                    </div>

                </SwiperSlide>

                {/* 3/ */}
                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/defi/iconlogo/synthetix.png" className="img-fluid" alt="" />
                        <h3>Ledger Nano X
                        </h3>
                    </div>

                </SwiperSlide>

                {/* 4 */}
                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/125.png" className="img-fluid" alt="" />
                        <h3>Exodus</h3>
                    </div>

                </SwiperSlide>
                {/* 5 */}
                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/121.png" className="img-fluid" alt="" />
                        <h3>Coinbase Wallet
                        </h3>
                    </div>

                </SwiperSlide>

                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/126.png" className="img-fluid" alt="" />
                        <h3>Mycelium
                        </h3>
                    </div>

                </SwiperSlide>


                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/127.jfif" className="img-fluid" alt="" />
                        <h3>Trezor Model T

                        </h3>
                    </div>

                </SwiperSlide>

                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/128.png" className="img-fluid" alt="" />
                        <h3>AlphaWallet

                        </h3>
                    </div>

                </SwiperSlide>

                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/129.png" className="img-fluid" alt="" />
                        <h3>Enjin Wallet
                        </h3>
                    </div>

                </SwiperSlide>

                <SwiperSlide className='my-slider3'>
                    <div className='network_card'>
                        <img src="./Assets/Defi-wallet/130.png" className="img-fluid" alt="" />
                        <h3>Coinomi
                        </h3>
                    </div>

                </SwiperSlide>


                ...
            </Swiper>
        </div>
    </section>


    {/* 9 */}
    <section className='bgright'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <div class="heading-box">
                        <h3>Different Types of DeFi Wallets
                        </h3>
                        {/* <div class="line2"></div> */}
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-12 col-md-5'>
                    <div class="card type-card">

                        <div class="row">
                            <div class="col-3">
                                <div class="type-icon">
                                    <img src="./Assets/Defi-wallet/66.png" alt="" />
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="type-caption">
                                    <h3>Software Wallets
                                    </h3>
                                    <p>Accessible via web or mobile apps, these wallets offer convenience and flexibility for daily transactions and interactions with DeFi apps.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 2 */}
                <div className='col-12 col-md-5'>
                    <div class="card type-card">

                        <div class="row">
                            <div class="col-3">
                                <div class="type-icon">
                                    <img src="./Assets/Defi-wallet/7.png" alt="" />
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="type-caption">
                                    <h3>Hardware Wallets
                                    </h3>
                                    <p> Physical devices providing high security for storing large amounts of crypto assets offline.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* 3 */}
                <div className='col-12 col-md-5'>
                    <div class="card type-card">

                        <div class="row">
                            <div class="col-3">
                                <div class="type-icon">
                                    <img src="./Assets/Defi-wallet/8.png" alt="" />
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="type-caption">
                                    <h3>Web Wallets
                                    </h3>
                                    <p> Browser-based wallets offering easy access to DeFi platforms and services without the need for local software.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 4 */}
                <div className='col-12 col-md-5'>
                    <div class="card type-card">

                        <div class="row">
                            <div class="col-3">
                                <div class="type-icon">
                                    <img src="./Assets/Defi-wallet/9.png" alt="" />
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="type-caption">
                                    <h3>Mobile Wallets
                                    </h3>
                                    <p> Apps designed for smartphones, combining convenience with robust security features for managing DeFi assets on the go.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

    </section>

</Layout>
  )
}

export default Wallet
