import React from 'react'
import Layout from '../Layout/Layout';
import '../defiDevelopment/defidevelopment.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

const Defidevelopment = () => {
    return (
        <Layout>
            <section className="bannerSC">
                {/* <div className='video_fadeoverlay'></div> */}

                <div className="container">
                    <div className="row ">
                        <div className="col-12 col-md-6">
                            <div className="banner-caption2">
                                <h1>  DeFi Development Company </h1>
                                <p>
                                    Step into the future of finance with our tailored DeFi solutions. We specialize in creating innovative decentralized finance applications that drive efficiency, transparency, and user empowerment. From blockchain-based lending platforms to advanced DeFi exchanges, our services are designed to elevate your financial projects to new heights.
                                </p>
                                <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  

                            </div>
                        </div>

                        <div className="col-12 col-md-6 text-center">
                            <div>
                                <img src="./Assets/defi/defi.gif" className='img-fluid' style={{marginTop:"50px"}} alt="not" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* 2 */}
            <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Comprehensive DeFi Solutions for User-Centric Financial Platforms</h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                    <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/defi/Financial.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>Launch advanced decentralized financial platforms with our holistic DeFi development services. Our expert team designs and develops secure, scalable, and high-performance decentralized finance applications, empowering you to deliver seamless user experiences and disruptive financial products. Whether you're building DeFi lending protocols or decentralized exchanges, we ensure your platform is tailored for maximum user engagement, security, and growth.

                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                  
                    </div>
                </div>
            </section>


            {/* 3 */}
            <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div class="heading-box">
                                <h3>Our Specialized DeFi Development Services</h3>
                                {/* <p>We provide a full suite of DeFi development services, ensuring you stay ahead in the world of decentralized finance. Explore our offerings:</p> */}
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/1.png" className='img-fluid' alt="not" />

                                <h3>DeFi Smart Contract Development</h3>
                                <p>Build secure and self-executing smart contracts for your DeFi applications, ensuring transparency and eliminating intermediaries.</p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/2.png" className='img-fluid' alt="not" />

                                <h3>DeFi Exchange Development</h3>
                                <p>  Create decentralized trading platforms (DEX) like Uniswap, empowering users with seamless token swaps and liquidity pools.</p>
                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/3.png" className='img-fluid' alt="not" />

                                <h3>DeFi Token Development</h3>
                                <p>  Launch your DeFi token with customizable features, ensuring security, scalability, and liquidity in the decentralized market.</p>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/4.png" className='img-fluid' alt="not" />

                                <h3>DeFi Wallet Development</h3>
                                <p>  Securely store and manage multiple crypto assets with customized DeFi wallet solutions for hassle-free transactions.</p>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/5.png" className='img-fluid' alt="not" />

                                <h3>DeFi DApp Development</h3>
                                <p>  Deliver intuitive and scalable decentralized applications (DApps) to provide unique financial services in the DeFi ecosystem.
                                </p>
                            </div>
                        </div>
                        {/* 6 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/6.png" className='img-fluid' alt="not" />

                                <h3>DeFi Staking Platform Development</h3>
                                <p>  Build staking platforms to allow users to earn rewards while supporting the network's security and operations.</p>
                            </div>
                        </div>

                        {/* 7 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/7.png" className='img-fluid' alt="not" />

                                <h3>DeFi Yield Farming Platform Development</h3>
                                <p>  Develop yield farming platforms that enable users to maximize returns by providing liquidity to DeFi pools.</p>
                            </div>
                        </div>

                        {/* 8 */}
                        <div className='col-12 col-md-3'>
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/8.png" className='img-fluid' alt="not" />

                                <h3>DeFi Crowdfunding Platform Development</h3>
                                <p>  Innovate decentralized crowdfunding solutions to raise capital in a transparent and secure manner.</p>
                            </div>
                        </div>

                        {/* 9 */}
                        <div className='col-12 col-md-3'    >
                            <div class="cardDefi defi-cardDefi">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/9.png" className='img-fluid' alt="not" />

                                <h3>DeFi Lending and Borrowing Platforms</h3>
                                <p>  Build trustless lending and borrowing platforms to eliminate traditional banking constraints and facilitate instant access to funds.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* 4 */}
            <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Global Decentralized Finance (DeFi) Market Overview</h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row  align-items-center'>

                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/defi/graph.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>The Growing Impact of Decentralized Finance on the Global Market
                                    According to recent reports, the global decentralized finance (DeFi) market was valued at USD 13 billion in 2023 and is expected to reach USD 507 billion by 2030, growing at a compound annual growth rate (CAGR) of 45.8%. The rapid adoption of DeFi applications, increasing interest in decentralized finance crypto projects, and the growing need for financial inclusivity are driving this unprecedented market growth.

                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 5 */}
            <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Core Features of our DeFi Software Development Services</h3>
                                <p>Our DeFi solutions are designed with these essential features to ensure security, scalability, and ease of use</p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/10.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Non-Custodial Design</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Users retain control of their private keys and funds, unlike centralized platforms where funds are held by third parties.</p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/11.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Modular Architecture</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>DeFi protocols are highly composable, allowing for integration and interoperability with various decentralized apps and platforms.</p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/12.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Liquidity Pools</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Automated market-making and liquidity provision enable continuous trading and staking without traditional order books.</p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/13.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Decentralized Governance</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Community members can vote on important protocol updates and changes, ensuring a democratic approach to platform management.</p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/14.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Yield Optimization</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Smart contracts automate yield farming strategies, helping users maximize returns across various DeFi protocols.</p>

                            </div>
                        </div>

                        {/* 6 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/15.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Customizable Protocols</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Flexible and customizable DeFi protocols that can be tailored to meet specific business needs and adapt to evolving market conditions.

                                </p>

                            </div>
                        </div>


                    </div>

                </div>
            </section>



            {/* 6 */}
            <section className='bgright'>

                <div className="container">
                    <div class="row py-1">
                        <div class="col-12">
                            <div class="heading-box">
                                <h3>Our Technical Expertise in DeFi Protocols</h3>
                                <div class="line2"></div>
                            </div>
                        </div>
                    </div>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={20}
                        slidesPerView={4}
                        //   centeredSlides={true}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        loop={true}  // Enable looping
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                            320: {  // Screens with a width of 320px or more
                                slidesPerView: 1,  // Show 1 slide
                                // Center the slide
                            },
                            768: {  // Screens with a width of 768px or more (e.g., tablets)
                                slidesPerView: 5,  // Show 2 slides
                            },
                            1024: {  // Screens with a width of 1024px or more (e.g., desktops)
                                slidesPerView: 4,  // Show 3 slides
                            },
                        }}


                    >
                        <SwiperSlide className='my-slider2'>
                            <div className='network_card'>
                                <img src="./Assets/defi/iconlogo/uniswap.png" className="img-fluid" alt="" />
                                <h3>Uniswap</h3>
                            </div>

                        </SwiperSlide>
                        {/* 2 */}
                        <SwiperSlide className='my-slider2'>
                            <div className='network_card'>
                                <img src="./Assets/defi/iconlogo/sushiswap.png" className="img-fluid" alt="" />
                                <h3>SushiSwap</h3>
                            </div>

                        </SwiperSlide>

                        {/* 3/ */}
                        <SwiperSlide className='my-slider2'>
                            <div className='network_card'>
                                <img src="./Assets/defi/iconlogo/synthetix.png" className="img-fluid" alt="" />
                                <h3>Synthetix</h3>
                            </div>

                        </SwiperSlide>

                        {/* 4 */}
                        <SwiperSlide className='my-slider2'>
                            <div className='network_card'>
                                <img src="./Assets/defi/iconlogo/compound.png" className="img-fluid" alt="" />
                                <h3>compound</h3>
                            </div>

                        </SwiperSlide>
                        {/* 5 */}
                        <SwiperSlide className='my-slider2'>
                            <div className='network_card'>
                                <img src="./Assets/defi/iconlogo/aave-aave.png" className="img-fluid" alt="" />
                                <h3>Aave</h3>
                            </div>

                        </SwiperSlide>




                        ...
                    </Swiper>
                </div>
            </section>

            {/* 7 */}
            <section className='bgleft'>

                <div className="container py-3">
                    <div className="heading-box text-center py-2">
                        <h3 className="mb-3">Key Differences : Centralized vs. Decentralized Finance </h3>
                    </div>
                    <div className="differ">
                    <img src="./Assets/defi/Table.png" className='img-fluid' alt="" />
                    </div>
                </div>

            </section>

            {/* 8 */}
            <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-12'>
                            <div class="heading-box">
                                <h3>Why Choose Us for Your DeFi Development Needs?
                                </h3>

                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-6 col-md-2'>
                            <div class="code-box2">
                                <img src="./Assets/defi/why/25.png" className="img-fluid" alt="" />
                                <p>Proven Track Record</p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-6 col-md-2'>
                            <div class="code-box2">
                                <img src="./Assets/defi/why/26.png" className="img-fluid" alt="" />
                                <p>Custom Solutions</p>
                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-6 col-md-2'>
                            <div class="code-box2">
                                <img src="./Assets/defi/why/27.png" className="img-fluid" alt="" />
                                <p>Security-First Approach</p>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className='col-6 col-md-2'>
                            <div class="code-box2">
                                <img src="./Assets/defi/why/28.png" className="img-fluid" alt="" />
                                <p>End-to-End Services</p>
                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-6 col-md-2'>
                            <div class="code-box2">
                                <img src="./Assets/defi/why/29.png" className="img-fluid" alt="" />
                                <p>Global Expertise</p>
                            </div>
                        </div>
                        {/* 6 */}

                    </div>

                </div>
            </section>

            {/* 9 */}
            <section className='bgleft'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Benefits of DeFi Solutions</h3>
                             
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-5'>
                            <div class="card type-card">

                                <div class="row">
                                    <div class="col-3">
                                        <div class="type-icon">
                                            <img src="./Assets/defi/Benefits/16.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="type-caption">
                                            <h3>Permissionless Access
                                            </h3>
                                            <p>No need for approvals or permissions from centralized entities, allowing anyone to participate in the financial ecosystem globally.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 2 */}
                        <div className='col-12 col-md-5'>
                            <div class="card type-card">

                                <div class="row">
                                    <div class="col-3">
                                        <div class="type-icon">
                                            <img src="./Assets/defi/Benefits/17.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="type-caption">
                                            <h3>Borderless Transactions
                                            </h3>
                                            <p>Seamless, instantaneous transactions across borders, removing traditional banking delays and fees.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* 3 */}
                        <div className='col-12 col-md-5'>
                            <div class="card type-card">

                                <div class="row">
                                    <div class="col-3">
                                        <div class="type-icon">
                                            <img src="./Assets/defi/Benefits/18.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="type-caption">
                                            <h3>Programmable Money
                                            </h3>
                                            <p>Automated, complex financial functions via smart contracts that streamline processes like lending, borrowing, and staking.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className='col-12 col-md-5'>
                            <div class="card type-card">

                                <div class="row">
                                    <div class="col-3">
                                        <div class="type-icon">
                                            <img src="./Assets/defi/Benefits/19.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="type-caption">
                                            <h3>Transparency & Security
                                            </h3>
                                            <p>Full visibility of all transactions on the blockchain, with cryptographic security ensuring data integrity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </section>
            {/* <section className='bgright'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Our DeFi Development Process</h3>
                             
                            </div>
                        </div>
                    </div>
                    <div className="row gutters">
    <div className="col-12 col-md-8 mx-auto">
      <div className="card timeline-card">
        <div className="card-body">
          
          <div className="timeline">
            <div className="timeline-row">
             
              <div className="timeline-dot fb-bg" />
              <div className="timeline-content">
                  <img src="./Assets/defi/process/20.png" alt="" />
                <h4>Discovery & Planning</h4>
                <p>Gather your requirements, analyze market needs, and create a detailed project plan and technical roadmap.</p>
              
              </div>
            </div>
            <div className="timeline-row">
             
              <div className="timeline-dot green-one-bg" />
              <div className="timeline-content green-one">
              <img src="./Assets/defi/process/21.png" alt="" />
                <h4>Design & Prototyping</h4>
                <p>
                 Develop intuitive UI/UX designs and prototypes to visualize the final product and gather feedback.
                </p>
               
              </div>
            </div>
            <div className="timeline-row">
             
              <div className="timeline-dot green-two-bg" />
              <div className="timeline-content green-two">
              <img src="./Assets/defi/process/22.png" alt="" />
                <h4>Smart Contract & Development</h4>
                <p>Build and deploy secure smart contracts, and integrate them with your DeFi application’s core functionalities.</p>
              
              </div>
            </div>
            <div className="timeline-row">
              
              <div className="timeline-dot green-three-bg" />
              <div className="timeline-content green-three">
              <img src="./Assets/defi/process/23.png" alt="" />
                <h4>Testing & Auditing</h4>
                <p>
                Conduct rigorous testing and security audits to ensure the platform is reliable, secure, and performs optimally.
                </p>
               
              </div>
            </div>
            <div className="timeline-row">
             
              <div className="timeline-dot green-four-bg" />
              <div className="timeline-content green-four">
              <img src="./Assets/defi/process/24.png" alt="" />
                <h4>Deployment & Support</h4>
                <p className="no-margin">Launch the platform, providing ongoing support and maintenance to ensure smooth operation and adapt to user feedback.</p>
                
              </div>
            </div>
      
          </div>
        </div>
      </div>
    </div>
  </div>



                </div>




            </section> */}
            {/* faq */}

            {/* faq */}
            <section className='bgright'>

                <div className="container py-3">
                    <div className="heading-box text-center ">
                        <h3 className="mb-3">FAQ</h3>
                    </div>

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item rounded-3 border-0 shadow mb-2">
                            <h2 className="accordion-header">
                                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    What is Decentralized Finance (DeFi)?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>  DeFi is a financial ecosystem built on blockchain technology that operates without intermediaries, using smart contracts to provide services like lending and trading.</p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item rounded-3 border-0 shadow mb-2">
                            <h2 className="accordion-header">
                                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    How does DeFi differ from traditional finance?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>  DeFi eliminates intermediaries by leveraging blockchain, allowing for direct, peer-to-peer financial transactions with enhanced transparency and lower fees.</p>
                                </div>
                            </div>
                        </div>


                        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                            <h2 className="accordion-header">
                                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                    What are the benefits of DeFi platforms?
                                </button>
                            </h2>
                            <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>  Benefits include lower fees, global accessibility, transparency, and opportunities for earning through staking and yield farming.</p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                            <h2 className="accordion-header">
                                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    How secure are DeFi platforms?
                                </button>
                            </h2>
                            <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>  DeFi platforms are secured by blockchain and smart contracts but depend on rigorous code audits to ensure safety and reliability.</p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                            <h2 className="accordion-header">
                                <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                    How can I get started with DeFi?
                                </button>
                            </h2>
                            <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>  Start by researching DeFi platforms, choosing one that fits your needs, and connecting your crypto wallet to begin using their services. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            {/* 
             new */}
 
  {/* timeline */}



{/* <!-- Timeline end --> */}


        </Layout>
    )
}

export default Defidevelopment;
