import React from 'react'
import Layout from "../Layout/Layout";
import '../Cloud-Services/CloudS.css';
import { Link } from 'react-router-dom';

function CloudS() {
  return (
    <Layout>
    <section className="bannerSC">
                {/* <div className='video_fadeoverlay'></div> */}

                <div className="container">
                    <div className="row ">
                        <div className="col-12 col-md-6">
                            <div className="banner-caption2">
                                <h1>  Cloud Services by Blockcoaster  </h1>
                                <p>
                                At Blockcoaster, we offer a comprehensive suite of cloud services designed to enhance your business's digital capabilities. Whether you're seeking to scale your infrastructure, improve collaboration, or increase operational efficiency, our cloud solutions provide the tools you need to succeed in today’s fast-paced digital environment.

                                </p>

                                <Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  
                            </div>
                        </div>

                        <div className="col-12 col-md-6 text-center">
                            <div className="banner-img-nft4">
                                <img src="./Assets/cloud/11.png" className='img-fluid' alt="not" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>What Are Cloud Services?
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                    
                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/cloud/22.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>Cloud services refer to a wide range of computing resources that are delivered over the internet, allowing businesses and individuals to access and use hardware, software, storage, and networking capabilities on a remote server, rather than relying on physical on-premises infrastructure. This means businesses can run applications, store data, and manage their IT needs from virtually anywhere, without the need for costly hardware investments.


                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bgright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div class="heading-box">
                                <h3>Key Features of Our Cloud Services
                                </h3>
                                {/* <p>We provide a full suite of DeFi development services, ensuring you stay ahead in the world of decentralized finance. Explore our offerings:</p> */}
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div class="cardCloud defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/cloud/111.png" className='img-fluid' alt="not" />

                                <h3>Scalability On-Demand
                                </h3>
                                <p>Effortlessly scale resources to meet the changing demands of your business. With cloud solutions, you can grow your infrastructure as needed, avoiding the limitations of physical hardware.
                                </p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardCloud defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/cloud/211.png" className='img-fluid' alt="not" />

                                <h3>Cost Efficiency
                                </h3>
                                <p> Reduce your capital and operational expenses by eliminating the need for expensive on-premises servers. Cloud solutions operate on a pay-as-you-go model, ensuring you only pay for what you use.
                                </p>
                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardCloud defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/cloud/311.png" className='img-fluid' alt="not" />

                                <h3>Global Access
                                </h3>
                                <p> Access your data and applications from any device, anywhere in the world, improving remote work capabilities and enhancing collaboration across your team.
                                </p>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardCloud defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/cloud/411.png" className='img-fluid' alt="not" />

                                <h3>Robust Security
                                </h3>
                                <p>Protect your business with state-of-the-art security features such as data encryption, firewalls, multi-factor authentication, and automated backups, ensuring your information is secure.</p>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardCloud defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/cloud/511.png" className='img-fluid' alt="not" />

                                <h3>Disaster Recovery
                                </h3>
                                <p> Ensure business continuity with our reliable disaster recovery solutions, designed to back up and restore data in the event of hardware failure or cyber threats, minimizing downtime.

                                </p>
                            </div>
                        </div>
                        {/* 6 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardCloud defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/cloud/6.png" className='img-fluid' alt="not" />

                                <h3>High Availability & Performance
                                </h3>
                                <p>Experience consistent, high-speed performance with minimal downtime, ensuring your applications and services are operational 24/7.
                                </p>
                            </div>
                        </div>

                        {/* 7 */}
                        {/* <div className='col-12 col-md-3'>
                            <div class="card defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/7.png" className='img-fluid' alt="not" />

                                <h3>DeFi Yield Farming Platform Development</h3>
                                <p>  Develop yield farming platforms that enable users to maximize returns by providing liquidity to DeFi pools.</p>
                            </div>
                        </div> */}

                        {/* 8 */}
                        {/* <div className='col-12 col-md-3'>
                            <div class="card defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/8.png" className='img-fluid' alt="not" />

                                <h3>DeFi Crowdfunding Platform Development</h3>
                                <p>  Innovate decentralized crowdfunding solutions to raise capital in a transparent and secure manner.</p>
                            </div>
                        </div> */}

                        {/* 9 */}
                        {/* <div className='col-12 col-md-3'>
                            <div class="card defi-card">
                                <div className='defi-card2'></div>
                                <img src="./Assets/defi/9.png" className='img-fluid' alt="not" />

                                <h3>DeFi Lending and Borrowing Platforms</h3>
                                <p>  Build trustless lending and borrowing platforms to eliminate traditional banking constraints and facilitate instant access to funds.</p>
                            </div>
                        </div> */}


                    </div>
                </div>
            </section>

            <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Categories of Cloud Services by Blockcoaster
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className='cardC core-cardC'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/cloud/7.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Infrastructure as a Service (IaaS)
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Blockcoaster’s IaaS solutions provide virtualized computing resources like virtual machines, storage, and networking, allowing you to run applications, store data, and scale as your business grows, without the need for physical hardware.
                                </p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-6 col-12'>
                            <div className='cardC core-cardC'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/cloud/8.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Platform as a Service (PaaS)
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Our PaaS solutions offer a complete development environment for developers to build, test, and deploy applications. Focus on innovation while we handle the infrastructure, providing you with a reliable platform for all your application development needs.
                                </p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-6 col-12'>
                            <div className='cardC core-cardC'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/cloud/9.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Software as a Service (SaaS)</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Blockcoaster’s SaaS offerings provide ready-to-use business applications, such as CRM, project management tools, and collaboration software. Enjoy hassle-free, subscription-based access to essential business tools without managing installations, updates, or maintenance.
                                </p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-6 col-12'>
                            <div className='cardC core-cardC'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/cloud/10.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Disaster Recovery as a Service (DRaaS)
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Our DRaaS solutions ensure business continuity by automatically backing up data and applications. In the event of a disaster, your business can quickly recover and resume operations, minimizing downtime and data loss.
                                </p>

                            </div>
                        </div>

                        {/* 5 */}
                        {/* <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/14.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Yield Optimization</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Smart contracts automate yield farming strategies, helping users maximize returns across various DeFi protocols.</p>

                            </div>
                        </div> */}

                        {/* 6 */}
                        {/* <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/defi/features/15.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Customizable Protocols</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Flexible and customizable DeFi protocols that can be tailored to meet specific business needs and adapt to evolving market conditions.

                                </p>

                            </div>
                        </div>
 */}

                    </div>

                </div>
            </section>


            <section className='bgright'>
            <div className="wrapper1">
  <h1>Benefits of Choosing Blockcoaster Cloud Services
  </h1>
  <div class="cols">
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
                          
							<span>Cost Savings
                            </span>
                         <img src="./Assets/cloud/1.png" alt="" width="150px" />

						</div>
					</div>
					<div class="back">
						<div class="inner">
						  <span>With our pay-as-you-go pricing model, businesses only pay for the resources they use, leading to significant savings on capital expenditures for hardware and ongoing maintenance costs.
                          </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Flexibility & Scalability
                            </span>
                            <img src="./Assets/cloud/2.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Easily scale your infrastructure up or down based on real-time business needs, without the limitations of physical hardware. Our cloud solutions offer unparalleled flexibility and agility.
                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Improved Collaboration
                            </span>
                            <img src="./Assets/cloud/3.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>With global access to cloud-based applications and data, your teams can collaborate more effectively, regardless of their physical location. This promotes better productivity and more efficient workflows.
                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Advanced Security
                            </span>
                            <img src="./Assets/cloud/4.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>We prioritize security in every aspect of our cloud services, utilizing cutting-edge encryption, multi-layer firewalls, regular security updates, and comprehensive monitoring to protect your data from breaches and cyber threats.
                            </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col" ontouchstart="this.classList.toggle('hover');">
				<div class="container">
					<div class="front" style={{background:"#bfa9eb"}}>
						<div class="inner">
							<span>Reduced Downtime
                            </span>
                            <img src="./Assets/cloud/5.png" alt="" width="150px" />
						</div>
					</div>
					<div class="back">
						<div class="inner">
							<span>Our high-availability architecture ensures that your systems and applications remain online and operational 24/7, reducing costly downtime and keeping your business running smoothly.
                            </span>
						</div>
					</div>
				</div>
			</div>
		
		</div>
 </div>
</section>

 <section className='bgleft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Why Choose Blockcoaster for Cloud Services?
                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row  align-items-center'>

                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/cloud/33.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>At Blockcoaster, we are committed to delivering cloud solutions that empower businesses to streamline their processes, scale effortlessly, and maintain robust security. Whether you're transitioning to the cloud for the first time or enhancing your existing infrastructure, we are your trusted partner in providing efficient, scalable, and cost-effective cloud services.


                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </Layout>
  )
}

export default CloudS

