// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .why-img2 img{
  
} */
.why-img-banner img{
  width: 500px;
}

@media screen and (max-width:600px){
    .why-img-banner img{
        width:100%;
      }
      .metaoverlay-card img {
        width: 100% !important;
        height: 100% !important;
    
      }   
}`, "",{"version":3,"sources":["webpack://./src/components/AIDevelopment/aidevelopment.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,YAAY;AACd;;AAEA;IACI;QACI,UAAU;MACZ;MACA;QACE,sBAAsB;QACtB,uBAAuB;;MAEzB;AACN","sourcesContent":["/* .why-img2 img{\n  \n} */\n.why-img-banner img{\n  width: 500px;\n}\n\n@media screen and (max-width:600px){\n    .why-img-banner img{\n        width:100%;\n      }\n      .metaoverlay-card img {\n        width: 100% !important;\n        height: 100% !important;\n    \n      }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
