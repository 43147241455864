import React from 'react'
import Layout from '../Layout/Layout'
import '../Defi-Farming/Dfarming.css'
import { Link } from 'react-router-dom';
function Dfarming() {
  return (
    <Layout>
    <div className="container-fluid bannerSC">
    
          <div className="jumbotron-content col-md-6 ">
            <h1> DeFi Yield Farming Development

            </h1>
            <p>Blockcoaster builds secure and efficient DeFi yield farming platforms to maximize crypto returns. Our solutions enable seamless participation in liquidity pools, unlocking passive income opportunities in decentralized finance.

            </p>
            <Link to="/Contact" className="rounded-button-link">  
  <button className="rounded-button">Connect With Our Experts</button>
</Link> 
          </div>
          <div className=" bannerimg col-md-6" >
                    <img src="./Assets/Farming/6.png" style={{marginTop:"50px"}} alt=""/>
                </div>
        </div>
      
      {/* </div> */}
    
    
      <div id="About" className="container-fluid jumbotron text-center bgleft" >
            <div className="container">
            <h1 style={{marginBottom: '50px'}}>DeFi Yield Farming Explained

            </h1>
            <div className="row text-left">
                {/* <div className=" sideimg col-md-6">
                    <img src="./Assets/Farming/2.png" alt=""/>
                </div> */}
                <div className="content1 col-md-12">
                  
                   <p>DeFi yield farming is an innovative financial strategy where users lend or stake their cryptocurrency in liquidity pools to earn rewards. These rewards often come in the form of interest, fees, or governance tokens, depending on the protocol. By providing liquidity to decentralized exchanges or DeFi protocols, yield farmers generate returns through the movement of assets in the decentralized finance space.

                   </p>
                   <p>Unlike traditional investments, yield farming in DeFi is highly dynamic and can provide higher returns, but it also comes with a degree of risk. Yield farming has become one of the most popular methods for users to maximize their crypto assets within decentralized finance platforms.</p>
                </div> 
            </div>
    
        </div>
        </div>
    
        <div id="Product" className="container-fluid jumbotron text-center bgright" >
        <div className="container">
        <h1 style={{marginbottom:' 50px'}}>Our Yield Farming Process
        </h1>
        <div className="row text-left">
            <div className="col-md-6">
                <img src="./Assets/Farming/11.png" alt="" width="30%"/>
            </div>
            <div className="conatiner_2 col-md-6">
               <h3>Platform Setup
               </h3>
               <p>We create a user-friendly DeFi yield farming platform tailored to your requirements, integrating with leading decentralized finance protocols.

               </p>
            </div> 
        </div>
        <div className="row text-left" style={{padding:'30px 0px'}}>
            
            <div className=" conatiner_2 col-md-6">
               <h3>Liquidity Pool Creation
               </h3>
               <p>Build custom liquidity pools that enable users to deposit their assets and start earning.
               </p>
            </div> 
            <div className="col-md-6">
                <img src="./Assets/Farming/21.png" alt="" width="30%"/>
            </div>
        </div>
        <div className="row text-left" style={{padding: '30px 0px'}}>
            <div className="col-md-6">
                <img src="./Assets/Farming/31.png" alt="" width="30%"/>
            </div>
            <div className="conatiner_2 col-md-6">
               <h3>Smart Contract Development
               </h3>
               <p> Our smart contracts automate reward distribution and ensure the integrity of the yield farming protocol.

               </p>
            </div> 
        </div> 
        <div className="row text-left" style={{padding:'30px 0px'}}>
            
            <div className=" conatiner_2 col-md-6">
               <h3>Yield Calculation Mechanism
    
               </h3>
               <p>Implement dynamic models that calculate rewards based on the liquidity provided and market conditions.

               </p>
            </div> 
            <div className="col-md-6">
                <img src="./Assets/Farming/4.png" alt="" width="30%"/>
            </div>
        </div>
        <div className="row text-left" style={{padding: '30px 0px'}}>
            <div className="col-md-6">
                <img src="./Assets/Farming/5.png" alt="" width="30%"/>
            </div>
            <div className="conatiner_2 col-md-6">
               <h3>Security Audits
               </h3>
               <p>Ensure the platform’s security through rigorous testing and auditing of smart contracts to protect user assets and minimize risks.

               </p>
            </div> 
        </div> 
    </div>
    </div>
    
    <section className='bgleft'>
                    <div className='container'>
                    <div className="row">
                            <div className="col-12">
                                <div className="heading-box-sec text-center py-5">
                                <h3>How Returns in Yield Farming Are Calculated

                                </h3>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/Farming/6.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Liquidity Provided
                              </h3>
                              <p> The amount of crypto assets deposited into a liquidity pool directly impacts the return on investment (ROI). Higher contributions generally result in higher rewards.

                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
    
                   {/* 2 */}
                
                   {/* 3 */}
             
    
             
                   {/* 6 */}
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/Farming/7.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Annual Percentage Yield (APY)
                              </h3>
                              <p>Yield farming platforms often offer APY as a key metric, which includes both interest and reinvested returns over the course of a year.

                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
    
                   {/* 7 */}
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/Farming/8.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Transaction Fees
                              </h3>
                              <p> As liquidity providers, users earn a share of the transaction fees generated by the platform.

                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
    
                   {/* 8 */}
                   <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/Farming/9.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Governance Tokens
                              </h3>
                              <p> Some platforms reward users with governance tokens, which can be traded or used to influence the direction of the protocol.
                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
                    {/* 9 */}
                    <div className='col-12 col-md-4'>
                         <div className='cardF Industry-blockchainF'>
                                <div className='row'>
                                  <div className='col-3 text-center'>
                                         <div className='indus-img'>
                                         <img src="./Assets/Farming/10.png" alt="" />
                                         </div>
                                  </div>
                                  <div className='col-9'>
                                       <div className='indus-caption '>
                                       <h3>Impermanent Loss
                              </h3>
                              <p>This occurs when the value of staked tokens fluctuates compared to holding them directly, potentially reducing returns.

                              </p>
                                 
                                       </div>
                                  </div>
                                </div>
                       
    
    
                         </div>
                   </div>
                   
                    {/* 10 */}
                         {/* 9 */}
                
                 
              </div>
            
                    </div>
    
                  </section>
    
    <section className='bgright'>
                    <div className='container'>
                    <div className="row">
                            <div className="col-12">
                                <div className="heading-box-sec text-center py-5">
                                <h3> Benefits of DeFi Yield Farming


                                </h3>
                              
                                </div>
                            </div>
                        </div>
                    <div className='row mt-5 justify-content-center'>
                   <div className='col-12 col-md-2'>
                         <div className='card card-blockchain2'>
                          <div className='line-circle'>
                            <p>01</p>
                          </div>
                             <div className='s-line'></div>
                                {/* <div className='block-icon'>
                                <img src="./Assets/web30/Process/1.png" alt="" />
                                </div> */}
                              <h3>High Earning Potential
                              </h3>
                              <p>  Compared to traditional investments, yield farming can provide significantly higher returns in a short time due to dynamic APY.
                              </p>
                                 
    
    
                         </div>
                   </div>
    
                   {/* 2 */}
                   <div className='col-12 col-md-2'>
                         <div className='card card-blockchain3'>
                         <div className='line-circle2'>
                            <p>02</p>
                          </div>
                             <div className='s-line2'></div>
                               
                              <h3>Liquidity Provision
                              </h3>
                              <p>Staking crypto assets ensures liquidity for DEXs and DeFi protocols, enabling smooth trading.

                              </p>
                                 
    
    
                         </div>
                   </div>
    
                   {/* 3 */}
                   <div className='col-12 col-md-2'>
                         <div className='card card-blockchain2'>
                         <div className='line-circle'>
                            <p>03</p>
                          </div>
                             <div className='s-line'></div>
                               
                              <h3>Earn Governance Tokens
                              </h3>
                              <p>Participate in governance decisions of the protocol, giving you a say in how the platform evolves.

                              </p>
                                 
    
    
                         </div>
                   </div>
                   {/* 4 */}
                   <div className='col-12 col-md-2'>
                         <div className='card card-blockchain3'>
                         <div className='line-circle2'>
                            <p>04</p>
                          </div>
                             <div className='s-line2'></div>
                              
                              <h3>Diversified Income Streams
                              </h3>
                              <p>Yield farming allows you to spread your investments across multiple liquidity pools, maximizing your potential returns.

                              </p>
                                 
    
    
                         </div>
                   </div>
                   {/* 5 */}
                   <div className='col-12 col-md-2'>
                         <div className='card card-blockchain2'>
                         <div className='line-circle'>
                            <p>05</p>
                          </div>
                             <div className='s-line'></div>
                               
                              <h3>Easy Integration with DeFi Apps
                              </h3>
                              <p>Blockcoaster enables yield farming integration into DeFi apps to boost earnings.

                              </p>
                                 
    
    
                         </div>
                   </div>
                   {/* 6 */}
                
            
              </div>   
                    </div>
    
                  </section>
    
    
    <div id="Service" className="services container-fluid jumbotron text-center" >
            <h1 style={{marginbottom: '50px'}}>DeFi Yield Farming Platforms and Protocols
            </h1>
     
      <div className="container">
        <div className="row Features-div-row">
          <div className="hover-div col-sm-6 col-md-4 col-lg-4">
            <h3>Uniswap
            </h3>
            <p>A decentralized exchange known for its extensive liquidity pools and easy-to-use interface for yield farming.

            </p>
          </div>
          <div className="hover-div col-sm-6 col-md-4 col-lg-4">
            <h3>SushiSwap
            </h3>
            <p>Another popular DEX offering attractive yield farming opportunities with governance tokens like SUSHI.
            </p>
          </div>
          <div className="hover-div col-sm-12 col-md-4 col-lg-4" >
            <h3>Aave
            </h3>        
            <p> A lending protocol that enables yield farming through liquidity provision and staking mechanisms.
            </p>
          </div>
        </div>
        <div className="row Features-div-row">
            <div className="hover-div col-sm-6 col-md-4 col-lg-4">
            <h3>PancakeSwap</h3>
            <p>A Binance Smart Chain-based DEX that offers high rewards for yield farmers.

            </p>
          </div>
          <div className="hover-div col-sm-6 col-md-4 col-lg-4">
            <h3>MakerDAO</h3>
            <p>MakerDAO's DAI token offers stability and liquidity for yield farmers through collateralized debt positions.

            </p>
          </div>
          <div className="hover-div col-sm-12 col-md-4 col-lg-4">
            <h3>Yearn Finance</h3>        
            <p> This platform aggregates liquidity from different protocols, optimizing yield farming strategies for maximum returns.

            </p>
          </div>
        </div>
        </div>
      </div>
    
    
    
          
                  <section className='bgright'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="heading-box-sec text-center py-2">
                                        <h3> Why Choose Blockcoaster?

                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                          
                                <div className="col-12 col-md-10">
                                    <div className="caption-list mt-md-5">
                                      <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Expertise in DeFi Development :</strong> With a strong focus on blockchain and DeFi technologies, we create high-performance platforms designed to thrive in the decentralized finance ecosystem.
                                      </p>
                                      <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Comprehensive Solutions :</strong> From platform development to smart contract creation and security audits, we offer end-to-end services to launch your DeFi yield farming platform successfully.</p>
                                      <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Unparalleled Security :</strong>We prioritize platform security with rigorous smart contract audits and real-time monitoring to safeguard user assets.</p>
                                      <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Seamless Integration:</strong> Our yield farming solutions integrate smoothly with leading DeFi protocols like Uniswap, MakerDAO, Aave, and PancakeSwap, giving users multiple ways to maximize their returns.

                                      </p>
                                      <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Custom Features :</strong>We develop user-friendly dashboards, detailed analytics, and personalized rewards mechanisms to enhance your platform’s appeal.
                                      </p>                
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-4 text-center">
                              <div className="qstimg">
                                 <img src="./Assets/Farming/3.png" className='img-fluid' alt="metaverse" />
                              </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
    
        </Layout>
  )
}

export default Dfarming
