import logo from './logo.svg';
import './App.css';
import Main from './components/MainPage/Main';
import { Route, Routes,  useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import PrivateBlockChainDevelopment from './components/PrivateBlockChainDevelopment/PrivateBlockChainDevelopment';
import BlockchainCunsalting from './components/blockchainCunsalting/BlockchainCunsalting';
import SmartContractArchiture from './components/SmartContractArchiture/SmartContractArchiture';
import SmartContractAudit from './components/SmartContractAudit/SmartContractAudit';
import MetaverseDevelopment from './components/MetaverseDevelopment/MetaverseDevelopment';
import Nftdevelopment from './components/Nftdevelopment/Nftdevelopment';
import Web3Development from './components/web30/Web3Development';
import Defidevelopment from './components/defiDevelopment/Defidevelopment';
import Aidevelopment from './components/AIDevelopment/Aidevelopment';
import AIAnalysis from './components/AIAnalysis/AiAnalysis';
import Blog from './components/Blog/blog';
import AiEngineer from './components/AI-Engineer/AI-Engineer';
import ChatgptApp from './components/Chatgpt-Application-Development/Chatgpt-Application';
import DStorage from './components/DStorage/index';
import Dcentralised from './components/Decentralised-whitelabel/Dcentralised';
import Cintegration from './components/Chatgpt-integration/Cintegration';
import GenerativeAi from './components/Generative-Ai-Solution/GenerativeAi';
import Dex from './components/Decentralised-Exchange/Dex';
import Dapp from './components/Dapp-Development/Dapp';
import CloudS from './components/Cloud-Services/CloudS';
import Wallet from './components/Defi-Wallet/Wallet';
import DefiStaking from './components/Defi-Staking/Defi-Staking';
import Dfarming from './components/Defi-Farming/Dfarming';
import Crowdfunding from './components/Defi-Crowdfunding/Crowdfunding';
import DLending from './components/Defi-Lending/DLending';
import NMarket from './components/Nft-marketplace/NMarket';
import Clone from './components/Nft-Clone/Clone';
import Token from './components/Nft-Token/Token';
import Lending from './components/Nft-Lending-Platform/Lending';
import Launchpad from './components/Nft-Launchpad/Launchpad';
import Minting from './components/Nft-Minting/Minting';
import AiProductMarket from './components/AI-ProductMarketing/AiProductMarket';
import SmartContractDevelopment from './components/Smartcontract-Dev/Smartcontract-Dev';
import Contact from './components/Contact/Contact';
import Sft from './components/Sft-development/Sft';
import Whitelabel from './components/Whitelabel-market/Whitelabel';
import MetaNft from './components/Meta-Nftmarket/MetaNft';
import MetaEcommerce from './components/Metaverse-Ecommerce/MetaEcommerce';
import MetaEstate from './components/Metaverse-Estate/MetaEstate';
import MetaAvatar from './components/Metaverse-Avatar/MetaAvatar';
import MetaHealthcare from './components/Metaverse-Healthcare/MetaHealthcare';
import { MetaVirtualevent } from './components/Metaverse-Virtual/MetaVirtualevent';
import MetaVirtualPlatform from './components/Meta-VirtualPlatform/MetaVirtualPlatform';
import { MetaApplication } from './components/Metaverse-Application/MetaApplication';
import Design from './components/3D-Design/Design';


function App() {
  const { pathname } = useLocation();

  // Scroll-to-top effect when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (

 
    // <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main/>} />
         <Route path="/PrivateBlockChainDevelopment" element={<PrivateBlockChainDevelopment/>} />
        <Route path="/BlockchainCunsalting" element={<BlockchainCunsalting />} />
        <Route path="/Smart Contract Architure" element={<SmartContractArchiture />} />
        <Route path="/Smart Contract Audit" element={<SmartContractAudit />} />
        <Route path="/SmartContract-Dev" element={<SmartContractDevelopment/>}/>
        <Route path="/Metaverse Development Service" element={<MetaverseDevelopment />} />
        <Route path="/Metaverse-Application" element={<MetaApplication/>}/>
        <Route path="/Meta-Nftmarket" element={<MetaNft/>}/>
        <Route path="/Metaverse-Ecommerce" element={<MetaEcommerce/>}/>
        <Route path="/Metaverse-Estate" element={<MetaEstate/>}/>
        <Route path="/Metaverse-Avatar" element={<MetaAvatar/>}/>
        <Route path="/Metaverse-Healthcare" element={<MetaHealthcare/>}/>
        <Route path="/Metaverse-Virtual" element={<MetaVirtualevent/>}/>
        <Route path="/Meta-VirtualPlatform" element={<MetaVirtualPlatform/>}/>
        <Route path="/NFT Development" element={<Nftdevelopment />} />
        <Route path="/Nft-marketplace" element={<NMarket/>}/>
        <Route path="/Nft-Clone" element={<Clone/>}/>
        <Route path="/Nft-Token" element={<Token/>}/>
        <Route path="/Nft-Lending-Platform" element={<Lending/>}/>
        <Route path="/Nft-Launchpad" element={<Launchpad/>}/>
        <Route path="/Nft-Minting" element={<Minting/>}/>
        <Route path="/Sft-development" element={<Sft/>}/>
        <Route path="/Whitelabel-market" element={<Whitelabel/>}/>
        <Route path="/Web 3.0 Development" element={<Web3Development/>} />
        <Route path="/Defi Development" element={<Defidevelopment/>} />
        <Route path="/Defi-Wallet" element={<Wallet/>}/>
        <Route path="/Defi-Staking" element={<DefiStaking/>}/>
        <Route path="/Defi-Farming" element={<Dfarming/>}/>
        <Route path="/Defi-Crowdfunding" element={<Crowdfunding/>}/>
        <Route path="/Defi-Lending" element={<DLending/>}/>
        <Route path="/AI Development" element={<Aidevelopment/>} />
        <Route path="/AIAnalysis" element={<AIAnalysis/>} />
        <Route path="/AI-Engineer" element={<AiEngineer/>} />
        <Route path="/AI-ProductMarketing" element={<AiProductMarket/>}/>
        <Route path="/Chatgpt-Application-Development" element={<ChatgptApp/>} />
        <Route path="/DStorage" element={<DStorage/>}/>
        <Route path="/blog" element={<Blog/>} />
        <Route path="/Decentralised-whitelabel" element={<Dcentralised/>}/>
        <Route path="/Chatgpt-integration" element={<Cintegration/>}/>
        <Route path="/Generative-Ai-Solution" element={<GenerativeAi />}/>
        <Route path="/Decentralised-Exchange" element={<Dex/>}/>
        <Route path="/Dapp-Development" element={<Dapp/>}/>
        <Route path="/Cloud-Services" element={<CloudS/>}/>
        <Route path="/3D-Design" element={<Design/>}/>
        <Route path="/Contact" element={<Contact/>}/>
        
       
      </Routes>

  );
}

export default App;
