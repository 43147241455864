import React from 'react';
import Layout from '../Layout/Layout';
import '../Whitelabel-market/Whitelabel.css'
import { Link } from 'react-router-dom';
const Whitelabel = () => {
    return (
        <Layout>
        <section className="bannerSC">
       
       <div className="container">
         <div className="row ">
           <div className="col-12 col-md-6">
             <div className="banner-caption2">
               <h1>White Label NFT Marketplace Development
               </h1>
               <p>Enter the thriving world of NFTs effortlessly! Leverage our white-label NFT marketplace development services to create a unique, fully branded platform, custom-tailored to your needs and ready for instant deployment.</p>
               <Link to="/Contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  
             </div>
           </div>
       
           <div className="col-12 col-md-6 text-center">
             <div className="why-img-banner" style={{marginTop:"60px"}}>
               <img src="./Assets/whitelabel/2.png" className='img-fluid' alt="" />
       
             </div>
           </div>
         </div>
       </div>
       </section>
       
       <section className="bgright">
       
       <div className="container">
         <div className="row ">
         <div className="col-12 col-md-6 text-center">
             <div className="why-img-banner">
               <img src="./Assets/whitelabel/3.png" className='img-fluid' alt="" />
       
             </div>
           </div>
       
       
           <div className="col-12 col-md-6">
             <div className="banner-caption2">
               <h1>Launch Your NFT Marketplace Instantly with Our White-Label Solution </h1>
               <p>In the fast-paced NFT market, timing is everything. With our white-label NFT marketplace solution, you can skip the lengthy development process and launch your NFT platform quickly. Customize your marketplace with advanced features, a unique interface, and seamless branding to captivate your audience from day one.</p>
             </div>
           </div>
       
          
         </div>
       </div>
       </section>
       
       {/* 2 */}
        
       <section className='bgleft'>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="heading-box-sec text-center py-4">
                    <h3>Why Choose White-Label over Custom-Built NFT Marketplaces?</h3>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/0001.png" alt="" />
                    <h2>Time-Intensive Development</h2>
                    <p>Developing an NFT marketplace from scratch can take several months, as it involves planning, designing, coding, and rigorous testing.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/0002.png" alt="" />
                    <h2>High Customization Potential</h2>
                    <p>Offers unlimited customization options, allowing you to create unique functionalities and features specific to your brand and user experience.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/003.png" alt="" />
                    <h2>Greater Resource Requirement</h2>
                    <p>Building from scratch requires a larger team of developers, designers, blockchain experts, and extensive budget allocation.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/4.png" alt="" />
                    <h2>Complete Control Over Features</h2>
                    <p>Allows full control over the choice and design of every feature, from wallet integration to advanced analytics and security measures.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/5.png" alt="" />
                    <h2>Scalability and Flexibility</h2>
                    <p>Designed to handle custom scalability needs, allowing you to structure the marketplace to grow with user demands.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/6.png" alt="" />
                    <h2>Longer Time-to-Market</h2>
                    <p>Due to the extensive development and testing phases, the time-to-market is generally longer, which could result in missed market opportunities.</p>
                </div>
            </div>
            <div className="col-md-3 col-12">
                <div className="metacardW">
                    <img src="./Assets/whitelabel/7.png" alt="" />
                    <h2>Higher Costs</h2>
                    <p>Requires a larger initial investment and higher ongoing maintenance costs, as every component is developed from scratch.</p>
                </div>
            </div>
        </div>
    </div>
</section>

       
       
                   <section className="bgright">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="heading-box-sec text-center py-4">
          <h3>White-Label Marketplace Solution</h3>
        </div>
      </div>
    </div>
  </div>

  <div className="container1">
    <div className="card1 orange-card">
      <p className="ourText titleText">Quick Deployment</p>
      <p className="ourText">White-label solutions are pre-built and can be launched within weeks, giving you a fast entry into the NFT marketplace space.</p>
    </div>

    <div className="card1 blue-card">
      <p className="ourText titleText">Cost-Effective</p>
      <p className="ourText">Since the core components are already developed, white-label solutions are more affordable, reducing development and deployment costs.</p>
    </div>

    <div className="card1 black-card">
      <p className="ourText titleText">Moderate Customization</p>
      <p className="ourText">Offers customization options like branding, UI/UX changes, and feature selection, though not as flexible as building from scratch.</p>
    </div>

    <div className="card1 green-card">
      <p className="ourText titleText">Tested and Reliable</p>
      <p className="ourText">White-label platforms are pre-tested and optimized for performance, reducing the risk of bugs or technical issues at launch.</p>
    </div>

    <div className="card1 orange-card">
      <p className="ourText titleText">Efficient Resource Allocation</p>
      <p className="ourText">Requires a smaller development team, as much of the backend work is pre-existing, allowing for efficient use of resources.</p>
    </div>

    <div className="card1 blue-card">
      <p className="ourText titleText">Faster Time-to-Market</p>
      <p className="ourText">Rapid launch timelines allow businesses to capitalize on emerging trends in the NFT market and attract early users.</p>
    </div>

    <div className="card1 green-card">
      <p className="ourText titleText">Pre-Integrated Features</p>
      <p className="ourText">Comes with built-in features like wallet integration, auction mechanisms, and multi-chain support, though additional custom features might be limited.</p>
    </div>
  </div>
</section>
    
    
    
    
       <section className='bgleft'>
                 <div className='container'>
                 <div className='row'>
                      <div className='col-12'>
                      <div class="heading-box">
                           <h3>Key Features of Our White-Label NFT Marketplace
                           </h3>
       {/* <div class="line3"></div> */}
                           {/* <div class="line2"></div> */}
                         </div>   
                      </div>
                 </div>
           
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <div className='card marketing-card'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/whitelabel/8.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Customizable Interface
                             </h3>
                          </div>
       
                          <p>Tailor the look and feel of your platform to align with your brand identity.</p>
                    </div>
                </div>
       
                {/* 2 */}
                <div className='col-12 col-md-4'>
                    <div className='card marketing-card'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                    <img src="./Assets/whitelabel/9.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Multi-Wallet Integration
                             </h3>
                          </div>
       
                          <p>Support various crypto wallets for secure and easy transactions.

    
       
                          </p>
                    </div>
                </div>
       
                {/* 3 */}
                <div className='col-12 col-md-4'>
                    <div className='card marketing-card'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/whitelabel/10.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Royalties Management 
                             </h3>
                          </div>
       
                          <p>Facilitate royalties for creators on secondary sales to enhance platform appeal.</p>
                    </div>
                </div>
       
                <div className='col-12 col-md-4'>
                    <div className='card marketing-card'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/whitelabel/11.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Secure Transaction Processing
                             </h3>
                          </div>
       
                          <p> Leverage blockchain’s security features to ensure trusted transactions.
                          </p>
                    </div>
                </div>

                <div className='col-12 col-md-4'>
                    <div className='card marketing-card'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/whitelabel/12.png" className='img-fluid' alt="" />
                          </div>
                             <h3>User-Friendly Dashboard
                             </h3>
                          </div>
       
                          <p> Enjoy an intuitive interface for streamlined administration and user interaction.

                          </p>
                    </div>
                </div>

                <div className='col-12 col-md-4'>
                    <div className='card marketing-card'>
                    
                          <div className='marketing-head'>
                          <div className='marketing-img'>
                          <img src="./Assets/whitelabel/13.png" className='img-fluid' alt="" />
                          </div>
                             <h3>Auction and Fixed Price Sales
                             </h3>
                          </div>
       
                          <p> Enable diverse sales models, from auctions to fixed-price listings, catering to various buyer preferences.


                          </p>
                    </div>
                </div>

            </div>
                 </div>
             </section>
       
    
             {/* 3 */}
             <section className='bgright'>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="heading-box-sec text-center py-4">
            <h3>Our White-Label NFT Marketplace Development Process

    
            </h3>
            <div className='line3'></div>
    
          </div>
    
    
        </div>
      </div>
    
      <div className='row justify-content-center'>
        <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/14.png" alt="" />
            </div>
            <h3>Requirement Analysis</h3>
    
            <p> Understanding your business needs and marketplace vision.

    
            </p>
          </div>
        </div>
        {/* 2 */}  <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/15.png" alt="" />
            </div>
            <h3>Customization & Design </h3>
            <p>Configuring branding, layout, and key features to reflect your goals.

    
            </p>
          </div>
        </div>
    
        {/* 3 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/16.png" alt="" />
            </div>
            <h3>Blockchain Integration </h3>
            <p>Integrating your chosen blockchain(s) for seamless transactions.

    
            </p>
          </div>
        </div>
        {/* 4 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/17.png" alt="" />
            </div>
            <h3>Backend & Frontend Development
            </h3>
            <p>Building a scalable, user-friendly, and secure interface.

    
            </p>
          </div>
        </div>
        {/* 5 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/18.png" alt="" />
            </div>
            <h3>Testing & Quality Assurance 
            </h3>
            <p> Conducting rigorous testing to ensure performance and security.

    
    
            </p>
          </div>
        </div>
        {/* 6 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/19.png" alt="" />
            </div>
            <h3>Launch & Deployment 
            </h3>
            <p> Deploying the marketplace to go live and making it accessible to users.

            </p>
          </div>
        </div>
        {/* 7 */}
        <div className="col-12 col-md-3">
          <div className='card nft-card-Dev'>
            <div className='top_line'></div>
            <div className='right_line'></div>
            <div className="nftdev-img">
              <img src="./Assets/whitelabel/20.png" alt="" />
            </div>
            <h3>Post-Launch Support 
            </h3>
            <p>  Providing ongoing maintenance and support for your marketplace’s success.

            </p>
          </div>
        </div>
        {/* 8 */}
       
      </div>
    </div>
    </section>
    
          


    <section className='bgleft'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Benefits of a White-Label NFT Marketplace Solution

                            </h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/whitelabel/21.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Rapid Market Entry
                          </h3>
                          <p>Launch quickly and capitalize on emerging trends without delay.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/whitelabel/22.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Cost Efficiency 
                          </h3>
                          <p>Save significantly on development costs compared to custom-built platforms.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/whitelabel/23.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Branding Flexibility
                          </h3>
                          <p>Fully customizable design to showcase your brand identity.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/whitelabel/24.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Scalability 
                          </h3>
                          <p> Built to grow with your user base and adapt to market demands.


                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                {/* 9 */}
                <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/whitelabel/25.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Robust Security
                          </h3>
                          <p>Enterprise-grade security features to protect transactions and user data.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
               
                {/* 10 */}

                <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/whitelabel/26.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Advanced Analytics 
                          </h3>
                          <p>Gain insights into user behavior and platform performance for data-driven decisions.


                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                     {/* 9 */}
            
             
          </div>
        
                </div>

              </section>
                   {/* 4 */}
                  
                
                   <section className='bgright'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Revenue Streams from Your NFT Marketplace

                            </h3>
                          
                            </div>
                        </div>
                    </div>
                <div className='row mt-5 justify-content-center'>
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                      <div className='line-circle'>
                        <p>01</p>
                      </div>
                         <div className='s-line'></div>
                            {/* <div className='block-icon'>
                            <img src="./Assets/web30/Process/1.png" alt="" />
                            </div> */}
                          <h3>Transaction Fees 
                          </h3>
                          <p>Earn a percentage from every transaction on the platform.
                          </p>
                             


                     </div>
               </div>

               {/* 2 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>02</p>
                      </div>
                         <div className='s-line2'></div>
                           
                          <h3>Listing Fees
                          </h3>
                          <p> Charge creators to list their NFTs on your marketplace.

                          </p>
                             


                     </div>
               </div>

               {/* 3 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>03</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Premium Account Features
                          </h3>
                          <p> Offer advanced features to premium users for an additional fee.

                          </p>
                             


                     </div>
               </div>
               {/* 4 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>04</p>
                      </div>
                         <div className='s-line2'></div>
                          
                          <h3>Advertising Space
                          </h3>
                          <p>Monetize the platform by allowing third-party ads.

                          </p>
                             


                     </div>
               </div>
               {/* 5 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>05</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Royalties on Secondary Sales 
                          </h3>
                          <p> Gain recurring revenue from secondary sales of listed NFTs.

                          </p>
                             


                     </div>
               </div>
               {/* 6 */}
            
        
          </div>   
                </div>

              </section>  
                   

              <section className='bgleft'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-box-sec text-center py-2">
                                    <h3>Why Choose Us for White-Label NFT Marketplace Development?

                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                      
                            <div className="col-12 col-md-8">
                                <div className="caption-list mt-md-5">
                                  <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Comprehensive Expertise :</strong> Skilled in blockchain, crypto, and NFT ecosystems. </p>
                                  <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Customization Capabilities:</strong>Tailor your marketplace to meet specific business objectives.
                                  </p>
                                  <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>End-to-End Support :</strong>From development to deployment, we’re with you every step of the way.

                                  </p>
                                  <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Post-Launch Maintenance :</strong>Ongoing technical support to optimize and update your platform.

                                  </p>
                                  <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Security Assurance :</strong>Implementing robust security measures for data and transactional safety.
                                  </p>                
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                          <div className="qstimg">
                             <img src="./Assets/whitelabel/1.png" className='img-fluid' style={{marginTop:"50px", width:"500px"}} alt="metaverse" />
                          </div>
                            </div>
                        </div>
                    </div>
                </section>
    
    
                <section className='bgright'>
                  <div className="container py-3">
      <div className="heading-box text-center ">
        <h3 className="mb-3">FAQ</h3>
      </div>
      {/* 1 */}
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item rounded-3 border-0 shadow mb-2">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            What is a white-label NFT marketplace?


            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>A white-label NFT marketplace is a pre-built platform that can be customized to align with your brand, allowing for quick deployment and cost-effective entry into the NFT market.


              </p>
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="accordion-item rounded-3 border-0 shadow mb-2">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Can I integrate multiple blockchains into my white-label marketplace?


    
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>Yes, our white-label NFT marketplace supports multi-chain integration, enabling you to offer users a choice of blockchain networks, such as Ethereum, BSC, or Polygon.


              </p>
            </div>
          </div>
        </div>
        {/* 3 */}
     
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
            How long does it take to launch a white-label NFT marketplace?

    
            </button>
          </h2>
          <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p> With a white-label solution, your marketplace can be ready to launch in as little as a few weeks, depending on the customization requirements.


              </p>
            </div>
          </div>
        </div>
        {/* 5 */}
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
            Is the white-label solution customizable?

    
            </button>
          </h2>
          <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p> Absolutely. Our white-label NFT marketplace can be customized in terms of design, features, and functionality to match your unique brand and vision.
              </p>
            </div>
          </div>
        </div>
        {/* 6*/}
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
            How do I earn revenue from a white-label NFT marketplace?


    
            </button>
          </h2>
          <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>Revenue is generated through transaction fees, listing fees, royalties, and premium features, among other options.

              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
                  </section>




           </Layout>
    );
}

export default Whitelabel;
