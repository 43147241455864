import React from 'react'
import '../Nft-Clone/Clone.css'
import Layout  from '../Layout/Layout'
import { Link } from 'react-router-dom';
function Clone() {
  return (
<Layout>
 <section className="bannerSC">

<div className="container">
  <div className="row ">
    <div className="col-12 col-md-6">
      <div className="banner-caption2">
        <h1>NFT Marketplace Clone
        </h1>
        <p>Build your own thriving digital marketplace with Blockcoaster’s NFT marketplace clone. Our customizable solution helps you launch a feature-rich marketplace in no time, empowering users to trade non-fungible tokens with ease.

        </p>
       
      </div>
      <Link to="/Contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  
    </div>

    <div className="col-12 col-md-6 text-center">
      <div className="why-img-banner3">
        <img src="./Assets/Nft-clone/4.png" className='img-fluid'  alt="" />

      </div>
    </div>
  </div>
</div>
</section>

<section className="bannerSC">

<div className="container">
  <div className="row ">
  <div className="col-12 col-md-6 text-center">
      <div className="why-img-banner3">
        <img src="./Assets/Nft-clone/5.png" className='img-fluid' alt="" />

      </div>
    </div>


    <div className="col-12 col-md-6">
      <div className="banner-caption2">
        <h1>What is NFT Marketplace Clone?

</h1>
        <p>An NFT marketplace clone is a ready-made solution that replicates the functionality of popular NFT platforms like OpenSea or Rarible. This allows businesses to quickly set up their own NFT marketplace without the need for ground-up development.

        </p>
      </div>
    </div>

   
  </div>
</div>
</section>

{/* 2 */}


<section className='bgright'>
          <div className='container'>
          <div className='row'>
               <div className='col-12'>
               <div class="heading-box">
                    <h3>NFT Marketplace Clone Features include

</h3>
{/* <div class="line3"></div> */}
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
          </div>
    
     <div className='row justify-content-center'>
         <div className='col-12 col-md-4'>
             <div className='card marketing-cardCL'>
             
                   <div className='marketing-head'>
                   <div className='marketing-img'>
                   <img src="./Assets/Nft-clone/121.png" className='img-fluid' alt="" />
                   </div>
                      <h3>Customizable UI
                      </h3>
                   </div>

                   <p> Tailor your marketplace to meet the needs of your brand and users.

                   </p>
             </div>
         </div>

         {/* 2 */}
         <div className='col-12 col-md-4'>
             <div className='card marketing-cardCL'>
             
                   <div className='marketing-head'>
                   <div className='marketing-img'>
             <img src="./Assets/Nft-clone/122.png" className='img-fluid' alt="" />
                   </div>
                      <h3>Multi-Blockchain Support
                      </h3>
                   </div>

                   <p> Enable trading on multiple blockchains such as Ethereum, Binance, and Polygon.

                   </p>
             </div>
         </div>

         {/* 3 */}
         <div className='col-12 col-md-4'>
             <div className='card marketing-cardCL'>
             
                   <div className='marketing-head'>
                   <div className='marketing-img'>
                   <img src="./Assets/Nft-clone/123.png" className='img-fluid' alt="" />
                   </div>
                      <h3>Smart Contract Integration
                      </h3>
                   </div>

                   <p> Automated and secure transactions powered by blockchain technology.

                   </p>
             </div>
         </div>

         {/* 4 */}
         <div className='col-12 col-md-4'>
             <div className='card marketing-cardCL'>
             
                   <div className='marketing-head'>
                   <div className='marketing-img'>
                   <img src="./Assets/Nft-clone/124.png" className='img-fluid' alt="" />
                   </div>
                      <h3>Search & Filter
                      </h3>
                   </div>

                   <p>Users can search for specific NFTs based on price, creator, and category.


                   </p>
             </div>
         </div>

         {/* 5 */}
         <div className='col-12 col-md-4'>
             <div className='card marketing-cardCL'>
             
                   <div className='marketing-head'>
                   <div className='marketing-img'>
                   <img src="./Assets/Nft-clone/125.png" className='img-fluid' alt="" />
                   </div>
                      <h3>Auction Features
                      </h3>
                   </div>

                   <p>Enable bidding and auction-style listings for NFT sales.


                   </p>
             </div>
         </div>

     </div>
          </div>
      </section>


      <section className="banner4">

<div className="container">
  <div className="row ">
    <div className="col-12 col-md-6">
      <div className="banner-caption2">
        <h1>Why is the NFT Marketplace Clone Good?

        </h1>
        <p>With an NFT marketplace clone, you can quickly launch a platform without the long and expensive development cycle. This solution allows you to tap into the booming NFT market and offer users a trusted space to buy and sell non-fungible tokens.


        </p>
      </div>
    </div>

    <div className="col-12 col-md-6 text-center">
      <div className="why-img-banner3">
        <img src="./Assets/Nft-clone/6.png" className='img-fluid' alt="" />

      </div>
    </div>
  </div>
</div>
</section>
      {/* 3 */}

    
      <section className='bgright'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-4">
                                <h3>Benefits of NFT Marketplace Clone

                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                       
                        {/* 2 */}
                        <div className="col-md-3 col-12">
                            <div className="metacard">
                                <img src="./Assets/Nft-clone/126.png" alt="" />
                                <h2>Faster Time to Market</h2>
                                <p>Skip the lengthy development process and launch your marketplace quickly.

                                </p>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="col-md-3 col-12">
                            <div className="metacard">
                                <img src="./Assets/Nft-clone/127.png" alt="" />
                                <h2>Scalability
                                </h2>
                                <p>Our NFT marketplace clone supports high volumes of transactions.
                                </p>
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="col-md-3 col-12">
                            <div className="metacard">
                                <img src="./Assets/Nft-clone/128.png" alt="" />
                                <h2>Revenue Generation
                                </h2>
                                <p>Earn commissions through NFT sales and listing fees.
                                </p>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="col-md-3 col-12">
                            <div className="metacard">
                                <img src="./Assets/aidevelopment/prompt2/10.png" alt="" />
                                <h2>Customizable
                                </h2>
                                <p>Easily add or modify features to fit your business model.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* 4 */}
           
            <section className='bgleft'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-2">
                                <h3>Why Choose Us?

                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                  
                        <div className="col-12 col-md-8">
                            <div className="caption-list mt-md-5">
                                <p> Blockcoaster offers top-tier NFT marketplace development solutions with a strong focus on security, user experience, and scalability. Our clone solutions help you get to market faster, with full customization to make your platform stand out from the competition.

                                </p>

                                    {/* 2 */}

                                    {/* 3 */}
                                    {/* 4 */}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 text-center">
                      <div className="qstimg">
                         <img src="./Assets/Nft-clone/7.png" className='img-fluid' alt="metaverse" />
                      </div>
                        </div>
                    </div>
                </div>
            </section>


            
    </Layout>
  )
}

export default Clone
