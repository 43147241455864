import React from 'react'
import Layout from '../Layout/Layout'
import '../Blog/blog.css'
const blog = () => {
    return (
        <Layout>
            <div className='container maincontainer'>
                <div class="row subcontainer">
                <div class="card col-lg-3">
                <img src="./Assets/aidevelopment/industry/4.png"></img>
                <h5>
                    Aug 29,2024
                </h5>
                <div class="icon">
                <img src="./Assets/aidevelopment/right-arrow.png"></img>
                <span>share</span> 
                </div>
                <div class="icon1">
                <img src="./Assets/icon_2.png"></img>
                 <span>200+</span>
                </div>
                <div class="text1">
                    <p>
                    Top 5 Investment Opportunities in Decentrawood: Land, 3D Models, and More                    </p>
                </div>
                <div class="link">
                   <a href="#" >Read more..</a>
                </div>
               </div>


               <div class="card col-lg-3">
                <img src="./Assets/aidevelopment/industry/5.png"></img>
                <h5>
                    July 08,2024
                </h5>
                <div class="icon">
                <img src="./Assets/aidevelopment/right-arrow.png"></img>
                <span>share</span> 
                </div>
                <div class="icon1">
                <img src="./Assets/icon_2.png"></img>
                 <span>200+</span>
                </div>
                <div class="text1">
                    <p>
                    Discover Metaverse: Journey Through Digital Realms                    </p>
                </div>
                <div class="link">
                   <a href="#" >Read more..</a>
                </div>
               </div>

               <div class="card col-lg-3">
                <img src="./Assets/aidevelopment/industry/6.png"></img>
                <h5>
                    Apr 07, 2024
                </h5>
                <div class="icon">
                <img src="./Assets/aidevelopment/right-arrow.png"></img>
                <span>share</span> 
                </div>
                <div class="icon1">
                <img src="./Assets/icon_2.png"></img>
                 <span>200+</span>
                </div>
                <div class="text1">
                    <p>
                    Breaking News: DEOD Token Going Multichain Soon!                    </p>
                </div>
                <div class="link">
                   <a href="#" >Read more..</a>
                </div>
               </div>
               <div class="card col-lg-3">
                <img src="./Assets/aidevelopment/industry/6.png"></img>
                <h5>
                    Apr 07, 2024
                </h5>
                <div class="icon">
                <img src="./Assets/aidevelopment/right-arrow.png"></img>
                <span>share</span> 
                </div>
                <div class="icon1">
                <img src="./Assets/icon_2.png"></img>
                 <span>200+</span>
                </div>
                <div class="text1">
                    <p>
                    Breaking News: DEOD Token Going Multichain Soon!                    </p>
                </div>
                <div class="link">
                   <a href="#" >Read more..</a>
                </div>
               </div>
                </div>
            </div>
        </Layout>
    )
}

export default blog
