import React from 'react'
import Layout from '../Layout/Layout';
import '../Nftdevelopment/Nftdevelopment.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';


const Nftdevelopment = () => {

  return (


    <Layout>

      <section className="bannerSC">

        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="banner-caption2">
                <h1> <span className=''>NFT</span> Development Company </h1>
                <p>Elevate Your Digital Assets with BlockCoaster's Expert NFT Development Services.
                  Whether you're looking to buy, sell, or create NFTs, we offer cutting-edge solutions tailored to your needs. Dive into the future of digital ownership with us!
                </p>

                <Link to="/Contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  

              </div>
            </div>

            <div className="col-12 col-md-6 text-center">
              <div className="banner-img-nft">
                <img src="./Assets/nft/nftbanner1.png" className='img-fluid' alt="" />

              </div>
            </div>
          </div>
        </div>
      </section>


      {/* 2 */}
      <section className='bgright py-2'>
        <div className="container">
          <div className="row align-items-center">
          <div className="col-12 col-md-5">
              <div className=''>
                <img src="./Assets/nft/nft.png" className='img-fluid' alt="" />


              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="heading-box-sec  py-4">
                <h3>Why Choose Us For Custom NFT Development ?</h3>
                <p>At BlockCoaster, we combine innovation with expertise to deliver unparalleled NFT development services. Our tailored solutions ensure that your project is unique, leveraging advanced blockchain technology for security, transparency, and scalability. Our team of seasoned developers works closely with you to bring your vision to life, offering end-to-end support from design to launch. With cross-blockchain compatibility and a commitment to excellence, we make sure your NFT platform not only meets but exceeds your expectations. Whether you're developing an NFT marketplace, minting new digital assets, or integrating smart contracts, BlockCoaster provides the expertise and tools you need to succeed in the rapidly evolving world of NFTs.</p>

              </div>
            </div>
         

          </div>


        </div>
      </section>
      {/* 3 */}
      {/* 4*/}
      <section className='bgleft'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center py-4">
                <h3>Our NFT Development Services</h3>
                <div className='line3'></div>

              </div>


            </div>
          </div>

          <div className='row justify-content-center'>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/1.png" alt="" />
                </div>
                <h3>NFT Marketplace Development</h3>

                <p>Our marketplace solutions are framed to serve a seamless and custom-made experience, we incorporate highly advanced features like royalty management, and decentralized storages also ensure complete transparency and improve scalability.</p>
              </div>
            </div>
            {/* 2 */}  <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/2.png" alt="" />
                </div>
                <h3>NFT Game Development</h3>
                <p>NFT games are becoming increasingly popular, and BlockCoaster is at the forefront of this trend, providing a user-friendly platform for developers to create innovative and engaging games, also develops a game engine, smart contract integration, and a marketplace for buying and selling NFTs.</p>
              </div>
            </div>

            {/* 3 */}
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/3.png" alt="" />
                </div>
                <h3>NFT and SFT Token Development (ERC 721)</h3>
                <p>BlockCoaster provides a smart contract integration, and a marketplace for buying and selling these tokens. With the popularity of blockchain-based games on the rise, BlockCoaster is a valuable resource for developers looking to create the next big thing in gaming.</p>
              </div>
            </div>
            {/* 4 */}
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/4.png" alt="" />
                </div>
                <h3>NFT Minting Development
                </h3>
                <p>As we pioneer blockchain technology development, specialized in NFT minting development. Our robust development solutions provide organizations with the tool to mint digital assets on blockchain networks, opening up new avenues for digital ownership and monetization.</p>
              </div>
            </div>
            {/* 5 */}
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/5.png" alt="" />
                </div>
                <h3>NFT Launchpad Development</h3>
                <p>Taking a steady forefront in NFT Launchpad development, we provide an array of development services like community engagement tools through which creators can effectively present their digital assets and gain exposure.</p>
              </div>
            </div>
            {/* 6 */}
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/6.png" alt="" />
                </div>
                <h3>NFT Lending Development</h3>
                <p>We create secure and efficient lending platforms that help users to utilize the value of their NFT assets. Through this, the users can now leverage their digital assets for liquidity, and investments. We ensure seamless integration with DeFi ecosystems and trade NFTs in a decentralized manner.</p>
              </div>
            </div>
            {/* 7 */}
            <div className="col-12 col-md-6 col-lg-4 mb-4" >
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/7.png" className='bgimg' alt="" />
                </div>
                <h3>NFT Exchange Platform Development</h3>
                <p>The crypto trends have now reached new outreaches. NFT exchange is one of the widespread applications that holds a remarkable position generating high returns for its owner. Blockcoatser develops highly advanced platforms that will drive more profits for the organizations.</p>
              </div>
            </div>
            {/* 8 */}
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div className='card4 nft-card-DevN'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className="nftdev-img">
                  <img src="./Assets/nft/8.png" alt="" />
                </div>
                <h3>NFT Support and Maintenance</h3>
                <p>Blockcoaster offers overall support and maintenance services for NFT platforms, we ensure smooth operations of NFT marketplaces and other applications. We provide ongoing support to address any technical issue or bug that may arise. We also offer regular updates and security patches to maintain the integrity and security of the platform</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* 4*/}

      <section className='bgright jaddo py-2'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center  py-4">
                <h3>Our NFT Development Workflow </h3>
                <div className='line3'></div>
              </div>
            </div>


          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='card Workflow-card'>
              <div className='txt-card'>
                  <h3> 01. Consultation</h3>
                  <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                </div>
               


              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4 box'>
              <div className='card Workflow-card'>
            
            
              <div className='txt-card'>
                      <h3> 06. Support & Maintenance</h3>
                      <p>We assist with launching your NFT platform and implementing strategies to attract users.</p>
                    </div>


              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card Workflow-card'>
                

                    <div className='txt-card'>
                    <h3> 05. Launch & Marketing</h3>
                    <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                  </div>



                  </div>
                </div>

              </div>
            </div>
            <div className='col-12 col-md-4 d-none d-md-block text-center  '>
              <div className='circle-boximg'>
                <img src="./Assets/nft/circle.png" className='img-fluid' alt="" />
              </div>
            </div>

            {/* 2 */}
            <div className='col-12 col-md-4 box'>
              <div className='col-12'>
                <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 02. Design & Development</h3>
                  <p>Our team designs and develops your NFT platform with custom features like decentralized storage and user-friendly interfaces.</p>
                </div>
                


                </div>
              </div>


              <div className='col-12 '>
                <div className='card Workflow-card'>
                <div className='txt-card'>
                    <h3> 03. Smart Contract Integration</h3>
                    <p>We integrate smart contracts to manage the minting, buying, and selling of NFTs.</p>
                  </div>




                </div>
              </div>

            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='card Workflow-card'>
                <div className='txt-card'>
                    <h3> 04. Testing & Optimization</h3>
                    <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                  </div>



                </div>
              </div></div>
          </div>


          <div className='row'>


            {/* 3 */}
            {/* <div className='col-12 col-md-4'>
                          <div className='card Workflow-card'>
                                 <div className='txt-card'>
                                 <h3> 03. Smart Contract Integration</h3>
                                  <p>We integrate smart contracts to manage the minting, buying, and selling of NFTs.</p>
                                 </div>
                            
                           

                          </div>
                         </div> */}

            {/* 4 */}
            {/* <div className='col-12 col-md-4'>
                          <div className='card Workflow-card'>
                                 <div className='txt-card'>
                                 <h3> 04. Testing & Optimization</h3>
                                  <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                                 </div>
                            
                           

                          </div>
                         </div> */}
            {/* 5 */}
            {/* <div className='col-12 col-md-4'>
                          <div className='card Workflow-card'>
                                 <div className='txt-card'>
                                 <h3> 05. Launch & Marketing</h3>
                                  <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                                 </div>
                            
                           

                          </div>
                         </div> */}
            {/* 6 */}
            {/* <div className='col-12 col-md-4'>
                          <div className='card Workflow-card'>
                                 <div className='txt-card'>
                                 <h3> 06. Support & Maintenance</h3>
                                  <p>We assist with launching your NFT platform and implementing strategies to attract users.</p>
                                 </div>
                            
                           

                          </div>
                         </div> */}
          </div>


        </div>
      </section>
      {/* 4 hide */}
      <section className='bgright samia py-2'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center  py-4">
                <h3>Our NFT Development Workflow </h3>
                <div className='line3'></div>
              </div>
            </div>


          </div>
          <div className='row'>

            <div className='col-12'>
              <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 01. Consultation</h3>
                  <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                </div>



              </div>
            </div>

            {/* 2 */}

            <div className='col-12'>
              <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 02. Design & Development</h3>
                  <p>Our team designs and develops your NFT platform with custom features like decentralized storage and user-friendly interfaces.</p>
                </div>



              </div>
            </div>
            {/* 3 */}
            <div className='col-12'>
              <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 03. Smart Contract Integration</h3>
                  <p>We integrate smart contracts to manage the minting, buying, and selling of NFTs.</p>
                </div>



              </div>
            </div>

            {/* 4 */}
            <div className='col-12 '>
              <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 04. Testing & Optimization</h3>
                  <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                </div>



              </div>
            </div>

            {/* 5 */}
            <div className='col-12'>
              <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 05. Launch & Marketing</h3>
                  <p>We start by understanding your goals and providing expert advice on your NFT project.</p>
                </div>



              </div>
            </div>


            {/* 6 */}
            <div className='col-12'>
              <div className='card Workflow-card'>
                <div className='txt-card'>
                  <h3> 06. Support & Maintenance</h3>
                  <p>We assist with launching your NFT platform and implementing strategies to attract users.</p>
                </div>



              </div>
            </div>

          </div>


        </div>
      </section>

      {/* 5 */}
      <section className='bgleft  py-2'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center  py-4">
                <h3>Core Components of Our NFT Ecosystem</h3>
                <div className='line3'></div>
              </div>
            </div>


          </div>

          <div className='row'>
            <div className='col-12 col-md-4'>
              <div className='cardE eco-cardE'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='ecos-icon'>
                      <img src="./Assets/nft/18.png" className='img-fluid' alt="" />
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className='eco-caption'>
                      <h3>Cross-Platform Compatibility</h3>
                      <p>Ensure seamless interaction of NFTs across various blockchain networks.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className='col-12 col-md-4'>
              <div className='cardE eco-cardE'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='ecos-icon'>
                      <img src="./Assets/nft/19.png" className='img-fluid' alt="" />
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className='eco-caption'>
                      <h3>User-Centric Design</h3>
                      <p>Focus on intuitive navigation and accessibility for a smooth user experience.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className='col-12 col-md-4'>
              <div className='cardE eco-cardE'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='ecos-icon'>
                      <img src="./Assets/nft/20.png" className='img-fluid' alt="" />
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className='eco-caption'>
                      <h3>Robust Security</h3>
                      <p> Implement top-tier encryption and smart contracts to safeguard digital assets.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 4 */}  <div className='col-12 col-md-4'>
              <div className='cardE eco-cardE'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='ecos-icon'>
                      <img src="./Assets/nft/21.png" className='img-fluid' alt="" />
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className='eco-caption'>
                      <h3>Integrated Payment Gateways</h3>
                      <p>Provide multiple payment options, including crypto and fiat, for user convenience..</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className='col-12 col-md-4'>
              <div className='cardE eco-cardE'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='ecos-icon'>
                      <img src="./Assets/nft/22.png" className='img-fluid' alt="" />
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className='eco-caption'>
                      <h3>Community Engagement Tools</h3>
                      <p>Enable vibrant community interaction and growth around NFT projects.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 6 */}
            <div className='col-12 col-md-4'>
              <div className='cardE eco-cardE'>
                <div className='row'>
                  <div className='col-3'>
                    <div className='ecos-icon'>
                      <img src="./Assets/nft/23.png" className='img-fluid' alt="" />
                    </div>
                  </div>
                  <div className='col-9'>
                    <div className='eco-caption'>
                      <h3>Decentralized Storage</h3>
                      <p>Secure your NFTs with decentralized storage solutions, ensuring transparency and reliability.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </section>



      {/* 6 */}


      {/* 7 */}
      {/* slider */}

<section className='bgright'>

<div className="container">
<div class="row py-1">
             <div class="col-12">
               <div class="heading-box">
                 <h3>NFT Development on Various Blockchain Networks </h3>
                 <div class="line2"></div>
               </div>
             </div>
           </div>
<Swiper
  modules={[Navigation, Pagination, Scrollbar, A11y]}
  spaceBetween={10}
  slidesPerView={3}
  centeredSlides={true}
  navigation
  pagination={{ clickable: true }}
  scrollbar={{ draggable: true }}
  onSwiper={(swiper) => console.log(swiper)}
  loop={true}  // Enable looping
  onSlideChange={() => console.log('slide change')}
  breakpoints={{
   320: {  // Screens with a width of 320px or more
     slidesPerView: 1,  // Show 1 slide
     centeredSlides: true,  // Center the slide
   },
   768: {  // Screens with a width of 768px or more (e.g., tablets)
     slidesPerView: 5,  // Show 2 slides
   },
   1024: {  // Screens with a width of 1024px or more (e.g., desktops)
     slidesPerView:5,  // Show 3 slides
   },
 }}
   
  
>
<SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/binance.png"  className="img-fluid" alt="" />
           <h3>Binance Smart Chain (BSC)</h3>
      </div>
   
     </SwiperSlide>
     {/* 2 */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/solana.png"  className="img-fluid" alt="" />
           <h3>Solana</h3>
      </div>
   
     </SwiperSlide>

     {/* 3/ */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/polygon.png"  className="img-fluid" alt="" />
           <h3>Polygon</h3>
      </div>
   
     </SwiperSlide>

     {/* 4 */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/eth.png"  className="img-fluid" alt="" />
           <h3>	Ethereum</h3>
      </div>
   
     </SwiperSlide>
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
      <img src="./Assets/smart-contract/Polkadot.jpg" className='img-fluid' alt="" />
      <h3>Polkadot</h3>
          
      </div>
   
     </SwiperSlide>




  
   ...
 </Swiper>
</div>
</section>

      {/* <section className='bgright py-2'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center  py-4">
                <h3>
                  NFT Development on Various Blockchain Networks  (pending)
                </h3>
                <div className='line3'></div>
              </div>
            </div>


          </div>


         
        </div>
      </section> */}

       {/* 8 */}
       <section className='bgleft py-2'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-box-sec text-center  py-4">
                <h3>
                Diverse NFT Services
                </h3>
                <div className='line3'></div>
              </div>
            </div>


          </div>

       <div className='row py-4'>
       <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                  <img src="./Assets/nft/Untitled6.gif" alt="" />
                </div>
                <h3>Art NFTs</h3>
                <p>Showcase and sell unique digital art pieces on secure, user-friendly platforms.</p>
              </div>
            </div>

            {/* 2 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled7.gif" alt="" />
                </div>
                <h3>Utility NFTs</h3>
                <p>Create NFTs with practical applications, from event tickets to membership access.</p>
              </div>
            </div>

            {/* 3 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled8.gif" alt="" />
                </div>
                <h3>Fractional NFTs</h3>
                <p>Enable fractional ownership of valuable assets, broadening investment access.</p>
              </div>
            </div>

            {/* 4 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled9.gif" alt="" />
                </div>
                <h3>Play-to-Earn NFTs</h3>
                <p> Develop engaging games where users can earn rewards in theform of NFTs.</p>
              </div>
            </div>
            {/* 5 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled10.gif" alt="" />
                </div>
                <h3>Music NFTs</h3>
                <p>Monetize and distribute music through NFTs, giving artists more control over their work.</p>
              </div>
            </div>
            {/* 6 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled11.gif" alt="" />
                </div>
                <h3>	E-commerce NFTs</h3>
                <p> Integrate NFTs into e-commerce platforms for unique product offerings and experiences.</p>
              </div>
            </div>

            {/* 7 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled12.gif" alt="" />
                </div>
                <h3>Video NFTs</h3>
                <p> Create and sell exclusive video content as NFTs, offering fans unique digital ownership.</p>
              </div>
            </div>
            {/* 8 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled13.gif" alt="" />
                </div>
                <h3>	Fashion NFTs</h3>
                <p>Launch digital fashion collections with virtual wearables and exclusive designs.</p>
              </div>
            </div>

            {/* 9 */}
            <div className="col-12 col-md-4">
              <div className='cardNft nft-card-DeverseD'>
                <div className="">
                <img src="./Assets/nft/Untitled14.gif" alt="" />
                </div>
                <h3>	Sports NFTs</h3>
                <p>Develop sports-related NFTs, from digital collectibles to tokenized sports moments.</p>
              </div>
            </div>
       </div>
       {/* 2 */}
       
         
        </div>
      </section>

   
     {/* 9 */}
     <section className='bgright'>
    
    <div className="container py-3">
   <div className="heading-box text-center ">
     <h3 className="mb-3">FAQ</h3>
   </div>
   {/* 1 */}
   <div className="accordion accordion-flush" id="accordionFlushExample">
     <div className="accordion-item rounded-3 border-0 shadow mb-2">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
             What are NFTs?
         </button>
       </h2>
       <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Unique digital assets on the blockchain representing ownership of specific items or content</p>
         </div>
       </div>
     </div>
     {/* 2 */}
     <div className="accordion-item rounded-3 border-0 shadow mb-2">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
         How do I buy an NFT?
         </button>
       </h2>
       <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Purchase NFTs through digital wallets on marketplaces using cryptocurrency or fiat payment gateways.</p>
         </div>
       </div>
     </div>
     {/* 3 */}
     {/* <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
           How can I contact Blockcoaster for business inquiries?
         </button>
       </h2>
       <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>You can contact us through our contact form on Blockcoaster or via email blockcoasterrr@gmail.com.</p>
         </div>
       </div>
     </div> */}
     {/* 4*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
         Can I sell my NFTs?
         </button>
       </h2>
       <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Yes, you can list and sell your NFTs on various marketplaces.</p>
         </div>
       </div>
     </div>
     {/* 5 */}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
         	What’s the cost to create an NFT?
         </button>
       </h2>
       <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Costs vary based on complexity; contact BlockCoaster for a detailed quote.</p>
         </div>
       </div>
     </div>
     {/* 6*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
        	Why are NFTs valuable?
         </button>
       </h2>
       <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>NFTs hold value due to their uniqueness, rarity, and demand.</p>
         </div>
       </div>
     </div>
     {/* 7--*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSEVEN" aria-expanded="false" aria-controls="flush-collapseSEVEN">
         Which blockchain is best for NFTs?
         </button>
       </h2>
       <div id="flush-collapseSEVEN" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p>Ethereum is widely used, but BSC, Polygon, and Solana are also strong choices..</p>
         </div>
       </div>
     </div>
     {/* 8-*/}
     <div className="accordion-item rounded-3 border-0 mb-2 shadow">
       <h2 className="accordion-header">
         <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeaght" aria-expanded="false" aria-controls="flush-collapseeaght">
         How do I secure my NFTs?
         </button>
       </h2>
       <div id="flush-collapseeaght" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
         <div className="accordion-body">
           <p> BlockCoaster provides advanced security measures, including smart contracts and regular updates</p>
         </div>
       </div>
     </div>
   </div>
 </div>
 
 </section>

    </Layout>
  )
}

export default Nftdevelopment;