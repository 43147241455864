// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.block_cont_card{
    color:black;
    padding: 10px;
    margin: 10px 0px;
    height:420px;
}
.block_cont_card img{
    width: 100%;
    height:160px;
}
.block_cont_card h2{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}
.block_cont_card p{
    font-size:14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }`, "",{"version":3,"sources":["webpack://./src/components/blockchainCunsalting/BlockchainCunsalting.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,iCAAiC;IACjC,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["\n.block_cont_card{\n    color:black;\n    padding: 10px;\n    margin: 10px 0px;\n    height:420px;\n}\n.block_cont_card img{\n    width: 100%;\n    height:160px;\n}\n.block_cont_card h2{\n    font-family: \"Roboto\", sans-serif;\n    font-weight: 500;\n    font-style: normal;\n}\n.block_cont_card p{\n    font-size:14px;\n    font-family: \"Roboto\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
