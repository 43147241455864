import React from 'react'
import Layout from '../Layout/Layout'
import '../3D-Design/Design.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import { Link } from 'react-router-dom';

const Design = () => {
  return (
    <Layout>
 
         <section className="banner3">
            <div className='video_fadeoverlay'></div>
            <video autoplay="true" muted="true" loop="true">
  <source src="./Assets/metaverse/3ddesign.mp4" type="video/mp4" />
  <source type="./Assets/block coaster_BG_4.mp4" src="video.mp4"></source>

</video>
                <div className="container3D">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="banner-caption2">
                                <h1> Transforming Ideas into Stunning 3D Visuals for Your Brand– We create, design, and innovate in 3D. </h1>
                       


                            </div>
                        </div>
                        
                        {/* <div className="col-12 col-md-6 text-center">
                      <div className="banner-img">
                        <img src="./Assets/banner-sideimg.png" className='img-fluid' alt="" />
                        
                      </div>
                    </div> */}
                    </div>
                </div>
            </section>

         
            <section className="card-section">
            <div class="heading-box" >
        
        <h3 style={{color:"black",}}>Our Services </h3>
      
      </div> 
        <div className="container3D">
          <div className="row">
            <div className="col-12 text-center">
              <div className="card3d ">
                <div className="image-container">
                <div className="image-itemD">
              <img src="../Assets/3d-design/3d exterior rendering services.png" alt="3D Exterior Rendering" className="card-imgD" />
              <div className="overlay">
              <h6>3D Exterior Rendering Services</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d interior rendering services.png" alt="3D Interior Rendering" className="card-imgD" />
              <div className="overlay">
              <h6>3D Interior Rendering Services</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d walkthrough.png" alt="3D Walkthrough" className="card-imgD" />
              <div className="overlay">
              <h6>3D Walkthrough</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d architectural animation services.png" alt="3D Architectural Animation" className="card-imgD" />
              <div className="overlay">
              <h6>3D Architectural Animation Services</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d floor plan rendering.png" alt="3D Floor Plan Rendering" className="card-imgD" />
              <div className="overlay">
              <h6>3D Floor Plan Rendering</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/visualise.png" alt="Visualize" className="card-imgD" />
              <div className="overlay">
              <h6>Visualize</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d site plan rendering services.png" alt="3D Site Plan Rendering" className="card-imgD" />
              <div className="overlay">
              <h6>3D Site Plan Rendering Services</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d product renderind services.png" alt="3D Product Rendering" className="card-imgD" />
              <div className="overlay">
              <h6>3D Product Rendering Services</h6>
              </div>
            </div>
            <div className="image-itemD">
              <img src="../Assets/3d-design/3d advertisement.png" alt="3D Advertisement" className="card-imgD" />
              <div className="overlay">
              <h6>3D Advertisement</h6>
              </div>
            </div>

                <div className="image-itemD">
              <img src="../Assets/3d-design/2D game.png" alt="2D Game Development" className="card-imgD" />
              <div className="overlay">
              <h6>2D Game Development</h6>
              </div>
            </div>
           
            <div className="image-itemD">
              <img src="../Assets/3d-design/3D game.png" alt="3D Game Development" className="card-imgD" />
              <div className="overlay">
              <h6>3D Game Development</h6>
              </div>
            </div>
           
            <div className="image-itemD">
              <img src="../Assets/3d-design/pre production and post production work.png" alt="Pre/Post Production" className="card-imgD" />
              <div className="overlay">
              <h6>Pre and Post Production Work</h6>
              </div>
            </div>
            
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div class="heading-box" >
                    <h3 style={{color:"black" ,  marginTop:"20px"}}> Portfolio</h3>
                  <p style={{color:"black"}}>Explore our portfolio to see the wide range of 3D services we've brought to life</p>
                  </div> 
<Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
            spaceBetween={60}
            slidesPerView={3}
            centeredSlides={true}
            navigation
            pagination={{ clickable: true }}
            loop={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                centeredSlides: true,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
                  <video src="/Assets/3d-design/5 Sec. Video.mp4" autoPlay muted loop className="video-slide" />
                
            </SwiperSlide>

            <SwiperSlide>
                  <video src="/Assets/3d-design/4 ( 5 Sec).mp4" autoPlay muted loop className="video-slide" />
                 
              </SwiperSlide>
            <SwiperSlide>
                  <video src="/Assets/3d-design/3 (5 Sec).mp4" autoPlay muted loop className="video-slide" />
                  
            </SwiperSlide>
            <SwiperSlide>
                  <video src="/Assets/3d-design/Int. Video (5 Sec).mp4" autoPlay muted loop className="video-slide" />
                  
            </SwiperSlide>
          </Swiper>
       

          <section className='bgdesign'>
                <div className="container">
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3  style={{color:"black"}} > Industries We Serve
                            </h3>
                            <p style={{color:"black"}} >Pioneering 3D services across diverse industries – from gaming to architecture and beyond.</p>
                            </div>
                        </div>
                    </div>
                     <div className="row">
                         <div className="col-12 col-md-3">
                               <div className="trend-card5">

                                 <div className="circle-icon-meta2">
                                      <img src="./Assets/3d-design/1.png" alt="" />
                                 </div>
                                    <h3>Game Development
                                    </h3>
                                     <p>High-performance 3D models and environments for immersive gameplay.</p>
                               </div>
                         </div>
                     

                         {/* 3 */}
                         <div className="col-12 col-md-3">
                               <div className="trend-card5">

                                 <div className="circle-icon-meta2">
                                 <img src="./Assets/3d-design/2.png" alt="" />
                                 </div>
                                    <h3>Architecture & Real Estate
                                    </h3>
                          <p>Photorealistic renderings and 3D walkthroughs to visualize your projects.</p>
                               </div>
                         </div>

                         {/* */}
                         <div className="col-12 col-md-3">
                               <div className="trend-card5">

                                 <div className="circle-icon-meta2">
                                 <img src="./Assets/3d-design/3.png" alt="" />
                                 </div>
                                    <h3>Product Design & Prototyping
                                    </h3>
                                     <p>Turning innovative concepts into tangible products with 3D modeling and prototyping.</p>
                               </div>
                         </div>
                         {/* 4 */}
                         <div className="col-12 col-md-3">
                               <div className="trend-card5">

                                 <div className="circle-icon-meta2">
                                 <img src="./Assets/3d-design/4.png" alt="" />
                                 </div>
                                    <h3>Advertising & Marketing
                                    </h3>
                                     <p>Create captivating 3D content to drive brand engagement and customer loyalty.</p>
                               </div>
                         </div>
                         {/* 5 */}

                         {/* 9 */}
                       
                     </div>
                </div>
                </section>



  <section className="testimonials-section">
      <div className="heading-box">
        <h3 style={{color:"black"}}>Testimonials</h3>
        <p>"Our clients love the results – Here’s what they have to say."</p>
      </div>

      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop={true}
      >
        <SwiperSlide>
          <div className="testimonial-item">
            <p>"Working with this team was a game-changer for our marketing efforts. Their 3D modeling and animation brought our product concepts to life in ways we couldn't have imagined. Highly professional and creative!"</p>
            <h6>- Amit Desai</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testimonial-item">
            <p>"The architectural visualizations provided by this team exceeded our expectations. They truly captured the essence of our designs, making it easier to communicate our vision with clients."</p>
            <h6>- Priya Mehta</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testimonial-item">
            <p>"The 3D renderings we received were top-notch. The team was attentive to our needs and delivered stunning, realistic visuals that greatly enhanced our product presentations."</p>
            <h6>- Rahul Sharma</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testimonial-item">
            <p>"The immersive 3D experiences they created for our VR projects were nothing short of spectacular. Their attention to detail and creativity helped us elevate our product offerings."</p>
            <h6>- James Johnson</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testimonial-item">
            <p>"We’ve collaborated with many 3D service providers, but this team’s ability to produce highly realistic renderings and animations is unparalleled. Their professionalism and quality of work are second to none."</p>
            <h6>- Elena Petrov</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testimonial-item">
            <p>"Their 3D product visualization services have transformed the way we present our products to customers. The high-quality images and animations significantly boosted our sales and customer engagement."</p>
            <h6>- Carlos Mendoza</h6>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>

    <section className="get-in-touch-section">
      <div className="contact-banner-card">
        <div className="contact-text">
          <h3>Get In Touch</h3>
          <p>Ready to bring your ideas to life? Let’s talk about your 3D project today!</p>
         
        </div>
        <div className="contact-button-container">
          {/* <button className="contact-us-btn">Contact Us</button> */}
          <Link to="/Contact" className="rounded-button-link">
          <button className="contact-us-btn">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>


    </Layout>
  )
}

export default Design
