import React from 'react'
import Layout from '../Layout/Layout'
import '../Metaverse-Avatar/MetaAvatar.css'

const MetaAvatar = () => {
  return (
    <Layout>
    <section className="bgdarkMA">
    {/* <div className='video_fadeoverlay'></div> */}
    {/* <video autoplay="true" muted="true" loop="true">
<source src="./Assets/web30/bannervdo.mp4" type="video/mp4" />
<source type="./Assets/block coaster_BG_4.mp4" src="video.mp4">
</source>

</video> */}
<div className="container">
<div className="row ">
<div className="col-12 col-md-6">
<div className="banner-caption2">
<h1> <span className=''> Metaverse Avatar Development Services 
</span> 
</h1>
<p className='text-light'>Step into the future with personalized, immersive avatars that redefine virtual interaction. Our advanced Metaverse Avatar solutions create lifelike digital personas for engaging experiences in virtual worlds.
.


</p>


</div>
</div>

<div className="col-12 col-md-6 text-center">
<div className="banner-img-nft2">
<img src="./Assets/metaverse/meta-avatar/avatar.png" className='img-fluid' alt="" />

</div>
</div>
</div>
</div>
</section>


{/* 2 */}

<section className='bgdarkMA'>
  <div className='container'>
  <div className='row'>
       <div className='col-12'>
       <div class="heading-box">
            <h3>Metaverse Avatar Development

            </h3>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
  </div>
  <div className='row py-3'>
  <div className='col-12 col-md-6 text-center'>
       <div class="why-img2">
       <img src="./Assets/metaverse/meta-avatar/77.png" className='img-fluid' alt="" />
          </div>   
       </div>
       <div className='col-12 col-md-6 pt-3'>
       <div class="heading-box-2">
            <p>Metaverse Avatar development is essential for creating digital identities that enable individuals to explore virtual worlds, attend virtual events, and interact with others. From hyper-realistic avatars to stylized and fantastical characters, our development solutions cater to a range of avatar styles, ensuring users can fully embody their online presence in the metaverse.

            </p>


            {/* <div class="line2"></div> */}
          </div>   
       </div>
     
  </div>
  </div>
</section>

{/* 3 */}
<section className='bgblueMA'>
        <div className='container'>
        <div className="row">
                <div className="col-12">
                    <div className="heading-box-sec text-center py-5">
                    <h3>Comprehensive Metaverse Avatar Development Services

                    </h3>
                    <p>Our end-to-end Metaverse Avatar services bring characters to life, customized to match your style and requirements. We offer:
                    </p>
                    </div>
                </div>
            </div>
            <div className='row'>
       <div className='col-12 col-md-6'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/metaverse/meta-avatar/1.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>3D Modeling and Design

                  </h3>
                     <p>Detailed 3D avatars created by our skilled designers, built to suit various virtual environments.
                     </p>
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>

       {/* 2 */}
    
       {/* 3 */}
 

 
       {/* 6 */}
       <div className='col-12 col-md-6'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/metaverse/meta-avatar/2.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Avatar Customization
                  </h3>
                     <p>Full customization options, allowing users to personalize appearances, accessories, expressions, and more.
                     </p>
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>

       {/* 7 */}
       <div className='col-12 col-md-6'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/metaverse/meta-avatar/3.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Real-time Motion Tracking
                  </h3>
                     <p> Integration of real-time motion capture for enhanced interaction, ensuring your avatar reflects your movements.</p>
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>

       {/* 8 */}
       <div className='col-12 col-md-6'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/metaverse/meta-avatar/4.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Blockchain Integration
                  </h3>
              <p>Secure avatars with blockchain tech to manage digital assets and identities in the metaverse.</p>
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>
        {/* 9 */}
       
  </div>

        </div>

      </section>

      <section className='bgdarkMA'>
        <div className='container'>
        <div className="row">
                <div className="col-12">
                    <div className="heading-box-sec text-center py-5">
                    <h3>Why Do You Need a Metaverse Avatar?
                    </h3>
                    <p>A metaverse avatar is more than just a digital character; it’s your identity in the virtual world. Avatars enable:</p>
                    </div>
                </div>
            </div>
            <div className='row'>
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/metaverse/meta-avatar/5.png" alt="" />
        </div>
        <h3>Personalized Experiences</h3>

        <p> Users can express their style, preferences, and individuality through their avatars.

        </p>
      </div>
    </div>
    {/* 2 */}  <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/8.png" alt="" />
        </div>
        <h3>Enhanced Social Interaction</h3>
        <p> Virtual avatars make interactions feel more realistic, fostering a sense of presence and connection.

        </p>
      </div>
    </div>

    {/* 3 */}
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/metaverse/meta-avatar/6.png" alt="" />
        </div>
        <h3>Access to Virtual Economiest</h3>
        <p>Avatars unlock opportunities to engage in virtual commerce, from buying items to monetizing digital assets.


</p>
      </div>
    </div>
    {/* 4 */}
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/metaverse/meta-avatar/7.png" alt="" />
        </div>
        <h3>Expanded Engagement
        </h3>
        <p>From corporate meetings to social gaming, avatars provide a platform for various engagements within the metaverse.

        </p>
      </div>
    </div>
    {/* 5 */}


  </div>
 

        </div>

      </section>

     {/* 4 */}


      <section className='bgblueMA'>
        <div className='container'>
        <div className="row">
                <div className="col-12">
                    <div className="heading-box-sec text-center py-5">
                    <h3>Diverse Metaverse Avatar Solutions for Every Need

                    </h3>
                  <p>Our development services offer multiple avatar options, tailored for different purposes:
                  </p>
                    </div>
                </div>
            </div>
        <div className='row mt-5  justify-content-center'>
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain2'>
              <div className='line-circle'>
                <p>01</p>
              </div>
                 <div className='s-line'></div>
                    {/* <div className='block-icon'>
                    <img src="./Assets/web30/Process/1.png" alt="" />
                    </div> */}
                  <h3>Photorealistic Avatars
                  </h3>
                  <p>Perfect for professional environments or high-fidelity applications, photorealistic avatars replicate real-world appearances with stunning detail.
                  </p>
                     


             </div>
       </div>

       {/* 2 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain3'>
             <div className='line-circle2'>
                <p>02</p>
              </div>
                 <div className='s-line2'></div>
                   
                  <h3>Stylized Avatars
                  </h3>
                  <p> For gaming and social settings, stylized avatars add creativity and allow for unique visual expressions.
                  </p>
                     


             </div>
       </div>

       {/* 3 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain2'>
             <div className='line-circle'>
                <p>03</p>
              </div>
                 <div className='s-line'></div>
                   
                  <h3>Full-Body Avatars with Motion Tracking
                  </h3>
                  <p> Ideal for virtual events and interactive experiences, these avatars respond to real-world gestures in real time.
                  </p>
                     


             </div>
       </div>
       {/* 4 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain3'>
             <div className='line-circle2'>
                <p>04</p>
              </div>
                 <div className='s-line2'></div>
                  
                  <h3>VR-Ready Avatars
                  </h3>
                  <p>Compatible with virtual reality environments, these avatars enhance immersive experiences.
                  </p>
                     


             </div>
       </div>
       {/* 5 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain2'>
             <div className='line-circle'>
                <p>05</p>
              </div>
                 <div className='s-line'></div>
                   
                  <h3>NFT-based Avatars
                  </h3>
                  <p> Unique, tokenized avatars that users can buy, sell, and trade, increasing exclusivity and personal ownership.

                  </p>
                     


             </div>
       </div>
   

  </div>   
        </div>

      </section>


      {/* 6 */}
    

      {/* 7 */}




      {/* 8 */}
      <section className='bgdarkMA'>
  <div className='container'>
  <div className='row'>
       <div className='col-12'>
       <div class="heading-box">
            <h3>Why Choose Blockcoaster for Metaverse Avatar Development?

            </h3>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
  </div>
  <div className='row py-3 align-items-center'>
       <div className='col-12 col-md-6 pt-3'>
       <div class="heading-box-2">
            <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>
            Advanced Customization Options: </strong>  We provide extensive customization capabilities, enabling avatars to reflect users’ preferences and identities.
            </p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Cutting-edge Technology Integration: </strong> We integrate real-time rendering, AI, and blockchain solutions to build secure, dynamic, and versatile avatars.

         </p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>User-Centric Approach: </strong> Our team focuses on crafting avatars that are intuitive, accessible, and aligned with the client’s brand or vision.
         </p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Full Support and Scalability: </strong>We offer support for avatar deployment, maintenance, and scaling as your metaverse grows.

         </p>
         
         
         
         
          </div>   
       </div>
       <div className='col-12 col-md-6 text-center'>
       <div class="why-img">
       <img src="./Assets/metaverse/meta-avatar/88.png" className='img-fluid' alt="" />
          </div>   
       </div>
  </div>
  </div>
</section>


{/* faq */}

</Layout>
  )
}

export default MetaAvatar
