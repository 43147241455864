import React from 'react'
import Layout from '../Layout/Layout'
import '../Nft-Launchpad/Launchpad.css'
import { Link } from 'react-router-dom';
function Launchpad() {
  return (
    <Layout>
    <section className="bannerSC">
    {/* <div className='video_fadeoverlay'></div> */}
    <video autoplay="true" muted="true" loop="true">
<source src="./Assets/web30/bannervdo.mp4" type="video/mp4" />
<source type="./Assets/block coaster_BG_4.mp4" src="video.mp4"></source>

</video>
<div className="container">
<div className="row ">
<div className="col-12 col-md-7">
<div className="banner-caption2">
<h1> <span className=''> Revolutionize the NFT Space with </span> 
Blockcoaster's NFT Launchpad Development Services</h1>
<p className='text-light'> Whether you're looking to list the next big digital asset or create your own NFT site, we offer comprehensive services to meet your needs, ensuring you're equipped to thrive in the expanding NFT space.


</p>
<Link to="/contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>  

</div>
</div>

{/* <div className="col-12 col-md-6 text-center">
<div className="banner-img-nft2">
<img src="./Assets/web30/banner2.png" className='img-fluid' alt="" />

</div>
</div> */}
</div>
</div>
</section>


{/* 2 */}

<section className='bgdark'>
  <div className='container'>
  <div className='row'>
       <div className='col-12'>
       <div class="heading-box">
            <h3>What is NFT Launchpad Development?
            </h3>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
  </div>
  <div className='row py-3'>
       <div className='col-12 col-md-6 pt-3'>
       <div class="heading-box-2">
            <p>An NFT launchpad is a platform where creators and businesses can easily mint, promote, and sell non-fungible tokens (NFTs). These platforms enable users to invest in the latest NFT projects before they go public, making it an essential part of the NFT ecosystem. At Blockcoaster, we specialize in developing robust and secure NFT platforms where creators can manage their NFT collections, set NFT prices, and offer exclusive NFTs for sale.
            </p>
            <p>
            With a custom-built NFT launchpad, you can easily interact with popular NFT marketplaces like Rarible, buy and sell NFTs seamlessly, and manage your digital assets. Our platforms are scalable, user-friendly, and designed to maximize your success in the NFT market.


            </p>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
       <div className='col-12 col-md-6 text-center'>
       <div class="why-img2">
       <img src="./Assets/Launchpad/11.png" className='img-fluid' alt="" />
          </div>   
       </div>
  </div>
  </div>
</section>

{/* 3 */}
<section className='bgblue'>
        <div className='container'>
        <div className="row">
                <div className="col-12">
                    <div className="heading-box-sec text-center py-5">
                    <h3>Why Do You Need an NFT Launchpad Development?
                    </h3>
                    </div>
                </div>
            </div>
            <div className='row'>
       <div className='col-12 col-md-4'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/Launchpad/1.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Early Access to NFT Projects

                  </h3>
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>

       {/* 2 */}
    
       {/* 3 */}
 

 
       {/* 6 */}
       <div className='col-12 col-md-4'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/Launchpad/2.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Revenue Generation
                  </h3>
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>

       {/* 7 */}
       <div className='col-12 col-md-4'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/Launchpad/3.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Enhanced Security
                  </h3>
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>

       {/* 8 */}
       <div className='col-12 col-md-4'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/Launchpad/4.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Increased Visibility
                  </h3>
              
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>
        {/* 9 */}
        <div className='col-12 col-md-4'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/Launchpad/5.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Scalability & Flexibility
                  </h3>
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>
       
        {/* 10 */}
             {/* 9 */}
             <div className='col-12 col-md-4'>
             <div className='card Industry-blockchain'>
                    <div className='row'>
                      <div className='col-3 text-center'>
                             <div className='indus-img'>
                             <img src="./Assets/Launchpad/6.png" alt="" />
                             </div>
                      </div>
                      <div className='col-9'>
                           <div className='indus-caption '>
                           <h3>Simplified NFT Management
                  </h3>
                     
                           </div>
                      </div>
                    </div>
           


             </div>
       </div>
     
  </div>

        </div>

      </section>

      <section className='bgdark'>
        <div className='container'>
        <div className="row">
                <div className="col-12">
                    <div className="heading-box-sec text-center py-5">
                    <h3>Key Features of Our NFT Launchpad Development</h3>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/7.png" alt="" />
        </div>
        <h3>NFT Minting</h3>

        <p> Easily create and list non-fungible tokens directly on your platform.
        </p>
      </div>
    </div>
    {/* 2 */}  <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/8.png" alt="" />
        </div>
        <h3>Customizable Smart Contracts</h3>
        <p>Secure and manage every transaction with blockchain-based smart contracts.
        </p>
      </div>
    </div>

    {/* 3 */}
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/9.png" alt="" />
        </div>
        <h3>Multi-Chain Support</h3>
        <p> Our platforms can integrate with leading blockchains like Ethereum, Binance, and more, ensuring a broad user base.

</p>
      </div>
    </div>
    {/* 4 */}
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/10.png" alt="" />
        </div>
        <h3>Integrated Wallets
        </h3>
        <p>Support for popular crypto wallets to safely store and trade NFTs.
        </p>
      </div>
    </div>
    {/* 5 */}
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/21.png" alt="" />
        </div>
        <h3>Buy and Sell NFTs</h3>
        <p> List and trade NFTs in real-time, with seamless integration with major marketplaces 
        </p>
      </div>
    </div>
      {/* 6 */}
      <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/12.png" alt="" />
        </div>
        <h3>NFT Auction System</h3>
        <p> Enables users to auction NFTs, driving engagement and creating opportunities for higher sales.
        </p>
      </div>
    </div>
    <div className="col-12 col-md-3">
      <div className='card nft-card-Dev2'>
        <div className='top_line'></div>
        <div className='right_line'></div>
        <div className='bottom_line'></div>
        <div className='bottom-right_line'></div>
        <div className="nftdev-img2">
        <img src="./Assets/Launchpad/13.png" alt="" />
        </div>
        <h3>Analytics Dashboard</h3>
        <p>A built-in feature that allows users to monitor NFT crypto prices, view NFTs for sale, and track the performance of their assets.

        </p>
      </div>
    </div>

  </div>
 

        </div>

      </section>

     {/* 4 */}
     <section className='bgblue'>
  <div className='container'>
  <div className='row'>
       <div className='col-12'>
       <div class="heading-box">
            <h3>Personalized NFT Launchpad Development for Your Vision
            </h3>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
  </div>
  <div className='row py-3'>
       <div className='col-12 col-md-6 pt-3'>
       <div class="heading-box-2">
            <p>At Blockcoaster, we understand that no two businesses are the same. That’s why we offer customized NFT launchpad development tailored specifically to your needs. Whether you are an individual creator looking to sell NFTs online or a large business seeking a comprehensive NFT platform solution, our expert developers will work with you to create a unique, engaging, and fully functional NFT site.

            </p>
            <p>
            From user interface design to smart contract deployment, we ensure your platform stands out in the competitive NFT marketplace. Get NFT solutions personalized for your goals, be it making your own NFT collection or launching the best upcoming NFTs on the market.



            </p>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
       <div className='col-12 col-md-6 text-center'>
       <div class="why-img2">
       <img src="./Assets/Launchpad/22.png" className='img-fluid' alt="" />
          </div>   
       </div>
  </div>
  </div>
</section>

      <section className='bgdark'>
        <div className='container'>
        <div className="row">
                <div className="col-12">
                    <div className="heading-box-sec text-center py-5">
                    <h3>Benefits of NFT Launchpad Development
                    </h3>
                  
                    </div>
                </div>
            </div>
        <div className='row mt-5  justify-content-center'>
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain2'>
              <div className='line-circle'>
                <p>01</p>
              </div>
                 <div className='s-line'></div>
                    {/* <div className='block-icon'>
                    <img src="./Assets/web30/Process/1.png" alt="" />
                    </div> */}
                  <h3>Early Access to NFT Sales
                  </h3>
                  <p>Allow users to invest in the latest and most promising NFTs before they hit the open market.
                  </p>
                     


             </div>
       </div>

       {/* 2 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain3'>
             <div className='line-circle2'>
                <p>02</p>
              </div>
                 <div className='s-line2'></div>
                   
                  <h3>Enhanced Security
                  </h3>
                  <p> With blockchain-based smart contracts, every transaction is secured, providing peace of mind to both buyers and sellers.
                  </p>
                     


             </div>
       </div>

       {/* 3 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain2'>
             <div className='line-circle'>
                <p>03</p>
              </div>
                 <div className='s-line'></div>
                   
                  <h3>Revenue Opportunities
                  </h3>
                  <p> Monetize your platform through fees, auctions, and NFT sales.
                  </p>
                     


             </div>
       </div>
       {/* 4 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain3'>
             <div className='line-circle2'>
                <p>04</p>
              </div>
                 <div className='s-line2'></div>
                  
                  <h3>Increased Visibility
                  </h3>
                  <p>Boost your NFT site with integrated marketing tools and analytics to maximize exposure.
                  </p>
                     


             </div>
       </div>
       {/* 5 */}
       <div className='col-12 col-md-2'>
             <div className='card card-blockchain2'>
             <div className='line-circle'>
                <p>05</p>
              </div>
                 <div className='s-line'></div>
                   
                  <h3>Blockchain for NFT Support
                  </h3>
                  <p>Build on major blockchains like Ethereum and Binance, increasing liquidity and reach for your NFTs.
                  </p>
                     


             </div>
       </div>
   

  </div>   
        </div>

      </section>


      {/* 6 */}
    

      {/* 7 */}




      {/* 8 */}
      <section className='bgblue'>
  <div className='container'>
  <div className='row'>
       <div className='col-12'>
       <div class="heading-box">
            <h3>Why Choose Blockcoaster for NFT Launchpad Development?
            </h3>
            {/* <div class="line2"></div> */}
          </div>   
       </div>
  </div>
  <div className='row py-3 align-items-center'>
       <div className='col-12 col-md-9 pt-3'>
       <div class="heading-box-2">
            <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>
            Comprehensive Solutions:</strong> From concept to deployment, we handle every aspect of NFT platform development, ensuring your project is successful.</p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Security First:</strong> Our platforms are built with the latest security features, including secure wallet integration and blockchain auditing.
         </p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Custom Development:</strong> We offer personalized NFT launchpad development tailored to your specific needs, from branding to functionality </p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Proven Experience:</strong> Our team has years of experience in blockchain development, giving you the expertise required to build a cutting-edge platform.
         </p>
         <p><img src='./Assets/Dapp-D/bullet.png' width="20px" height="20px"/><strong>Ongoing Support:</strong> Post-launch, we provide continuous support to ensure your NFT platform runs smoothly and efficiently.
         </p>
         
         
         
          </div>   
       </div>
       {/* <div className='col-12 col-md-6 text-center'>
       <div class="why-img">
       <img src="./Assets/Launchpad/33.png" className='img-fluid' alt="" />
          </div>   
       </div> */}
  </div>
  </div>
</section>


{/* faq */}
<section className='bgdark'>

<div className="container py-3">
<div className="heading-box text-center ">
<h3 className="mb-3">FAQ</h3>
</div>

<div className="accordion accordion-flush" id="accordionFlushExample">
<div className="accordion-item rounded-3 border-0 shadow mb-2">
<h2 className="accordion-header">
 <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
 What is an NFT launchpad?

 </button>
</h2>
<div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
 <div className="accordion-body">
   <p> An NFT launchpad is a platform that helps creators mint, launch, and sell NFTs, offering early access to new NFT projects.
   </p>
 </div>
</div>
</div>

<div className="accordion-item rounded-3 border-0 shadow mb-2">
<h2 className="accordion-header">
 <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
 Can I customize my NFT launchpad?
 </button>
</h2>
<div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
 <div className="accordion-body">
   <p>Yes, Blockcoaster provides fully personalized NFT launchpad development to suit your specific needs.
   </p>
 </div>
</div>
</div>


<div className="accordion-item rounded-3 border-0 mb-2 shadow">
<h2 className="accordion-header">
 <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
 What features does an NFT launchpad offer?

 </button>
</h2>
<div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
 <div className="accordion-body">
   <p>Our launchpads include NFT minting, customizable smart contracts, wallet integration, auction systems, and multi-chain support.
   </p>
 </div>
</div>
</div>

<div className="accordion-item rounded-3 border-0 mb-2 shadow">
<h2 className="accordion-header">
 <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
 How secure is the platform?

 </button>
</h2>
<div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
 <div className="accordion-body">
   <p>We prioritize security with blockchain-based smart contracts and secure wallet integrations for all transactions.
   </p>
 </div>
</div>
</div>

<div className="accordion-item rounded-3 border-0 mb-2 shadow">
<h2 className="accordion-header">
 <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
 Why choose Blockcoaster for NFT launchpad development?

 </button>
</h2>
<div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
 <div className="accordion-body">
   <p>We offer tailored solutions, extensive blockchain expertise, and ongoing support to ensure your NFT platform’s success.
   </p>
 </div>
</div>
</div>

</div>
</div>

</section>
</Layout>
  )
}

export default Launchpad
