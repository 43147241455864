import React from 'react'
import Layout from '../Layout/Layout'
import '../Metaverse-Healthcare/MetaHealthcare.css'
const MetaHealthcare = () => {
  return (
    <Layout>
        <section className="bannerH">
                {/* <div className='video_fadeoverlay'></div> */}

                <div className="container">
                    <div className="row ">
                        <div className="col-12 col-md-6">
                            <div className="banner-caption2">
                                <h1>Metaverse Healthcare Solutions

                                </h1>
                                <p>
                                Discover how virtual environments are transforming healthcare, enhancing patient care, and opening new frontiers for medical professionals. Dive into Web 3-powered healthcare solutions that connect, heal, and innovate.


                                </p>


                            </div>
                        </div>

                        <div className="col-12 col-md-6 text-center">
                            <div>
                                <img src="./Assets/metaverse/meta-healthcare/44444.png" className='img-fluid' width="320px" style={{marginTop:"60px"}} alt="not" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* 2 */}
            <section className='bgleftH'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Expanding Horizons: How the Metaverse Enhances Healthcare

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                    <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="./Assets/metaverse/meta-healthcare/13333.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>In the rapidly evolving healthcare landscape, the metaverse offers a pioneering approach, utilizing virtual, augmented, and mixed reality to streamline medical services, enhance patient engagement, and optimize healthcare training. Through these immersive Web 3 solutions, healthcare providers can now transcend physical boundaries, delivering virtual healthcare solutions that include telemedicine, remote diagnostics, and patient monitoring in real time. The metaverse’s transformative technology fosters a seamless blend of digital healthcare services that are reshaping the future of patient care and medical education alike.

                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>


            {/* 3 */}
            <section className='bgrightH'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div class="heading-box">
                                <h3>Use Cases of Web 3-Driven Virtual Healthcare Solutions

                                </h3>
                                {/* <p>We provide a full suite of DeFi development services, ensuring you stay ahead in the world of decentralized finance. Explore our offerings:</p> */}
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/metaverse/meta-healthcare/1.png" className='img-fluid' alt="not" />

                                <h3>Remote Consultations and Virtual Clinics</h3>
                                <p>Patients can receive medical consultations from anywhere through VR-driven virtual clinics, ensuring consistent healthcare access regardless of location.

                                </p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/metaverse/meta-healthcare/2.png" className='img-fluid' alt="not" />

                                <h3>Medical Training and Simulation</h3>
                                <p>With augmented reality (AR) and VR, healthcare providers and students can train using realistic simulations, reducing risk and enhancing skill development in a controlled environment.
                                s.
                                </p>
                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/metaverse/meta-healthcare/3.png" className='img-fluid' alt="not" />

                                <h3>Mental Health Therapy</h3>
                                <p>VR environments provide safe spaces for patients undergoing mental health therapy, helping them to relax and focus while receiving support from their therapists virtually.

                                </p>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/metaverse/meta-healthcare/4.png" className='img-fluid' alt="not" />

                                <h3>Rehabilitation and Physical Therapy</h3>
                                <p>Virtual environments allow patients to perform therapy exercises with real-time monitoring and feedback, helping improve recovery outcomes.

                                </p>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className='col-12 col-md-4'>
                            <div class="cardG defi-cardG">
                                <div className='defi-card2'></div>
                                <img src="./Assets/metaverse/meta-healthcare/5.png" className='img-fluid' alt="not" />

                                <h3>Virtual Reality Surgeries and Diagnostics</h3>
                                <p>Medical professionals use virtual tools to collaborate on complex surgeries and diagnostics, allowing for better preparation and accuracy in treatment.
                                .
                                </p>
                            </div>
                        </div>
                        {/* 6 */}
                   

                        {/* 7 */}
                        

                        {/* 8 */}
                 

                        {/* 9 */}
                       


                    </div>
                </div>
            </section>

            {/* 4 */}
            <div className='containerEC'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-boxe">
                                <h3>
                                Latest in Metaverse Healthcare

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-6 pt-3'>
                        <div class="heading-boxe">
                                <p>The healthcare industry is embracing the metaverse, with leading hospitals and companies adopting virtual tools for diagnostics, training, and patient care. Recent advancements in Web 3 healthcare solutions demonstrate a shift toward more accessible and interactive medical services, setting a new standard for virtual patient engagement and treatment.


                                </p>
                             
                               </div>
                        </div>
                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img4">
                                <img src="./Assets/metaverse/meta-healthcare/6.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                    </div>
                </div>     

            {/* 5 */}
            <section className='bgrightH'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Unmatched Benefits of Metaverse in Healthcare

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/metaverse/meta-healthcare/7.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Improved Access to Care</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>The metaverse bridges geographical gaps, giving patients easier access to healthcare services from any location.

                                </p>

                            </div>
                        </div>
                        {/* 2 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/metaverse/meta-healthcare/888.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Enhanced Patient Engagement</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Interactive platforms help patients actively participate in their healthcare, fostering a proactive approach to health management.

</p>

                            </div>
                        </div>

                        {/* 3 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/metaverse/meta-healthcare/999.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Cost-Effective Solutions</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>By reducing the need for physical facilities and on-site consultations, virtual healthcare can lower operational costs, making healthcare more affordable.

                                </p>

                            </div>
                        </div>


                        {/* 4 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/metaverse/meta-healthcare/100.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Immersive Medical Training</h3>
                                        </div>
                                    </div>
                                </div>
                                <p>Medical professionals gain valuable experience in a risk-free virtual environment, enhancing their skills without compromising patient safety.

                                </p>

                            </div>
                        </div>

                        {/* 5 */}
                        <div className='col-md-4 col-12'>
                            <div className='card core-card'>

                                <div className='row align-items-center'>
                                    <div className='col-3 text-center'>
                                        <div className='core-icon'>
                                            <img src="./Assets/metaverse/meta-healthcare/11.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <div className='core-heading'>
                                            <h3>Data-Driven Insights</h3>
                                        </div>
                                    </div>
                                </div>
                                <p> Web 3 technology in healthcare enables secure data sharing, leading to insights that help providers make informed, personalized decisions.

                                </p>

                            </div>
                        </div>

                        {/* 6 */}
                


                    </div>

                </div>
            </section>



            {/* 6 */}
            <section className='bgleftH'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div class="heading-box">
                                <h3>Why Choose Us for Metaverse Healthcare Solutions

                                </h3>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row  align-items-center'>

                        <div className='col-12 col-md-6 text-center'>
                            <div class="why-img">
                                <img src="../Assets/metaverse/meta-healthcare/333333.png" className='img-fluid' alt="not" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 pt-3'>
                            <div class="heading-box-2">
                                <p>As a leading metaverse development company, we offer comprehensive virtual healthcare solutions tailored to the needs of the modern healthcare landscape. Our expertise spans Web 3 applications, virtual consultations, and immersive training programs, designed to enhance the healthcare experience for patients and providers alike. With a commitment to innovation, our team creates secure, user-friendly, and scalable solutions that adhere to the highest standards in data privacy and virtual healthcare practices.




                                </p>
                                {/* <div class="line2"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 7 */}
            

            {/* 8 */}
           

            {/* 9 */}
         
           
            {/* faq */}

            {/* faq */}
       

            {/* 
             new */}
 
  {/* timeline */}



{/* <!-- Timeline end --> */}

    </Layout>
  )
}

export default MetaHealthcare
