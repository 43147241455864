// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metacard img {
    width: 40% !important;
}

.nft-card-Dev2 {
    text-align: center !important;
    padding: 10px !important;
    margin: 10px 0px !important;
    height: 220px !important;
    background-color: #ffffff !important;
    position: relative !important;
    border: 0 !important;
  
}`, "",{"version":3,"sources":["webpack://./src/components/Smartcontract-Dev/SmartDevelopment.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,wBAAwB;IACxB,2BAA2B;IAC3B,wBAAwB;IACxB,oCAAoC;IACpC,6BAA6B;IAC7B,oBAAoB;;AAExB","sourcesContent":[".metacard img {\n    width: 40% !important;\n}\n\n.nft-card-Dev2 {\n    text-align: center !important;\n    padding: 10px !important;\n    margin: 10px 0px !important;\n    height: 220px !important;\n    background-color: #ffffff !important;\n    position: relative !important;\n    border: 0 !important;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
