import React from 'react'
import Layout from "../Layout/Layout";
import '../Dapp-Development/Dapp.css' ;

function Dapp() {
  return (
    <Layout>
<div className="container-fluid jumbotron-container">

      <div className="jumbotron-content col-md-6 ">
        <h1>Decentralized Applications (dApps) Development
        </h1>
        <p>Transform your business with cutting-edge dApp solutions. Our expertise in building decentralized blockchain applications empowers you to leverage the security, transparency, and efficiency of blockchain technology.
        </p>
      </div>
      <div className=" bannerimg  col-md-6">
                <img src="./Assets/Dapp-D/1.png" class="img-fluid" alt=""/>
            </div>
    </div>
  
  {/* </div> */}


  <div id="About" className="container-fluid jumbotron text-center bgleft" >
        <div className="container">
        <h1 style={{marginBottom: '50px'}}>What are dApps?
        </h1>
        <div className="row text-left" style={{padding: '30px 0px'}}>
            <div className=" sideimg col-md-6">
                <img src="./Assets/Dapp-D/2.png" alt=""/>
            </div>
            <div className="content1 col-md-6">
              
               <p>DApps, or Decentralized applications, are software applications that run on blockchain networks rather than centralized servers. Unlike traditional apps, dApps offer enhanced security, privacy, and transparency by eliminating the need for intermediaries. They are especially popular in sectors like finance, gaming, and supply chain due to their decentralized nature and smart contract capabilities.
               </p>
               <p>Whether you're exploring a DeFi application or building the next best decentralized apps, dApps provide endless possibilities for innovation.</p>
            </div> 
        </div>

    </div>
    </div>

    <div id="Product" className="container-fluid jumbotron text-center bgright" >
    <div className="container">
    <h1 style={{marginbottom:' 50px'}}>Our DApp Development Services
    </h1>
    <div className="row text-left">
        <div className="col-md-6">
            <img src="./Assets/Dapp-D/t1.png" alt="" width="30%"/>
        </div>
        <div className="conatiner_2 col-md-6">
           <h3>Custom DApp Development
           </h3>
           <p>We build tailor-made decentralized applications for industries including finance, gaming, healthcare, and more.
           </p>
        </div> 
    </div>
    <div className="row text-left" style={{padding:'30px 0px'}}>
        
        <div className=" conatiner_2 col-md-6">
           <h3>Smart Contract Development
           </h3>
           <p>Our team creates robust, self-executing smart contracts to power your dApp and automate processes securely.</p>
        </div> 
        <div className="col-md-6">
            <img src="./Assets/Dapp-D/T2.png" alt="" width="30%"/>
        </div>
    </div>
    <div className="row text-left" style={{padding: '30px 0px'}}>
        <div className="col-md-6">
            <img src="./Assets/Dapp-D/T3.png" alt="" width="30%"/>
        </div>
        <div className="conatiner_2 col-md-6">
           <h3>DeFi Application Development
           </h3>
           <p>From decentralized exchanges (DEXs) to lending platforms, we develop DeFi applications that redefine how financial services are delivered.
           </p>
        </div> 
    </div> 
    <div className="row text-left" style={{padding:'30px 0px'}}>
        
        <div className=" conatiner_2 col-md-6">
           <h3>Cross-Platform DApp Development

           </h3>
           <p>We create dApps for various platforms, including Android dApp development, ensuring seamless user experiences across devices.
           </p>
        </div> 
        <div className="col-md-6">
            <img src="./Assets/Dapp-D/4.png" alt="" width="30%"/>
        </div>
    </div>
    <div className="row text-left" style={{padding: '30px 0px'}}>
        <div className="col-md-6">
            <img src="./Assets/Dapp-D/5.png" alt="" width="30%"/>
        </div>
        <div className="conatiner_2 col-md-6">
           <h3>DApp Integration and Maintenance
           </h3>
           <p>Our services include integrating your dApp with existing systems and providing ongoing support to ensure smooth operations.
           </p>
        </div> 
    </div> 
</div>
</div>



<section className='bgleft'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Key Benefits of DApp Development
                            </h3>
                          
                            </div>
                        </div>
                    </div>
                <div className='row mt-5'>
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                      <div className='line-circle'>
                        <p>01</p>
                      </div>
                         <div className='s-line'></div>
                            {/* <div className='block-icon'>
                            <img src="./Assets/web30/Process/1.png" alt="" />
                            </div> */}
                          <h3>Security
                          </h3>
                          <p> Built on blockchain, dApps offer high levels of security, making it difficult for hackers to manipulate data.</p>
                             


                     </div>
               </div>

               {/* 2 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>02</p>
                      </div>
                         <div className='s-line2'></div>
                           
                          <h3>Transparency
                          </h3>
                          <p>All transactions are publicly recorded on the blockchain, promoting trust and transparency.
                          </p>
                             


                     </div>
               </div>

               {/* 3 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>03</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>No Downtime
                          </h3>
                          <p> Decentralized applications run continuously, without the risk of server crashes or downtime.
                          </p>
                             


                     </div>
               </div>
               {/* 4 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>04</p>
                      </div>
                         <div className='s-line2'></div>
                          
                          <h3>Cost Efficiency
                          </h3>
                          <p>Eliminating intermediaries reduces operational costs, especially in DeFi applications like payments or trading platforms.
                          </p>
                             


                     </div>
               </div>
               {/* 5 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>05</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>User Control
                          </h3>
                          <p>Users have full control over their data, enhancing privacy and autonomy.
                          </p>
                             


                     </div>
               </div>
               {/* 6 */}
            
        
          </div>   
                </div>

              </section>


<div id="Service" className="services container-fluid jumbotron text-center" >
        <h1 style={{marginbottom: '50px'}}>Our DApp Development Process
        </h1>
 
  <div className="container">
    <div className="row Features-div-row">
      <div className="hover-div col-sm-6 col-md-4 col-lg-4">
        <h3>Consultation and Planning
        </h3>
        <p>We begin by understanding your unique business requirements and objectives for the dApp project.
        </p>
      </div>
      <div className="hover-div col-sm-6 col-md-4 col-lg-4">
        <h3>Architecture Design
        </h3>
        <p>Our team designs the architecture of your decentralized application, ensuring scalability, security, and efficiency.</p>
      </div>
      <div className="hover-div col-sm-12 col-md-4 col-lg-4" >
        <h3>Smart Contract Development
        </h3>        
        <p>We develop the smart contracts that form the backbone of your dApp, ensuring automated and secure operations.
        </p>
      </div>
    </div>
    <div className="row Features-div-row">
        <div className="hover-div col-sm-6 col-md-4 col-lg-4">
        <h3>DApp Development</h3>
        <p>Our developers create the frontend and backend of the application, ensuring a user-friendly interface and seamless blockchain integration.
        </p>
      </div>
      <div className="hover-div col-sm-6 col-md-4 col-lg-4">
        <h3>Testing and Deployment</h3>
        <p>We rigorously test the dApp to ensure there are no vulnerabilities, then deploy it on your preferred blockchain.
        </p>
      </div>
      <div className="hover-div col-sm-12 col-md-4 col-lg-4">
        <h3>Ongoing Support</h3>        
        <p>Post-launch, we offer maintenance services to keep your dApp updated and running smoothly.
        </p>
      </div>
    </div>
    </div>
  </div>

  <section className='bgleft'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Applications of DApps
                            </h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/Dapp-D/6.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Finance
                          </h3>
                          <p>DeFi applications like decentralized exchanges (DEX), lending platforms, and stablecoins.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 2 */}
            
               {/* 3 */}
         

         
               {/* 6 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/Dapp-D/7.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Gaming
                          </h3>
                          <p>Blockchain-powered games where players have true ownership of in-game assets through NFTs.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 7 */}
               <div className='col-12 col-md-4'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/Dapp-D/8.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Supply Chain
                          </h3>
                          <p>Transparent and tamper-proof tracking of goods from origin to destination.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>

               {/* 8 */}
               <div className='col-12 col-md-6'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-3 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/Dapp-D/9.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-9'>
                                   <div className='indus-caption '>
                                   <h3>Healthcare
                          </h3>
                          <p> Secure storage and sharing of patient data while maintaining privacy.

                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
                {/* 9 */}
                <div className='col-12 col-md-6'>
                     <div className='cardDapp Industry-blockchainD'>
                            <div className='row'>
                              <div className='col-2 text-center'>
                                     <div className='indus-img'>
                                     <img src="./Assets/Dapp-D/10.png" alt="" />
                                     </div>
                              </div>
                              <div className='col-8'>
                                   <div className='indus-caption '>
                                   <h3>Voting Systems
                          </h3>
                          <p>Ensuring transparent and tamper-proof elections through blockchain voting platforms.
                          </p>
                             
                                   </div>
                              </div>
                            </div>
                   


                     </div>
               </div>
               
                {/* 10 */}
                     {/* 9 */}
            
             
          </div>
        
                </div>

              </section>

      
              <section className='bgright'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-box-sec text-center py-2">
                                    <h3>Why Choose Us for DApp Development?
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                      
                            <div className="col-12 col-md-8">
                                <div className="caption-list mt-md-5">
                                  <p><strong>Expertise :</strong> With years of experience in blockchain and dApp development, we deliver robust, scalable, and secure solutions. </p>
                                  <p><strong>Innovation :</strong> We stay on the cutting edge of blockchain technology to provide the most advanced solutions.                                  </p>
                                  <p><strong>Custom Solutions :</strong>  Every dApp we develop is tailored to the specific needs and goals of our clients.
                                  </p>
                                  <p><strong>Cross-Platform Experience :</strong> We specialize in building dApps across multiple platforms, including web, Android, and iOS.
                                  </p>
                                  <p><strong>End-to-End Support :</strong>From conceptualization to deployment and beyond, we offer comprehensive support at every stage of the dApp development process.</p>                
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                          <div className="qstimg">
                             <img src="./Assets/Dapp-D/3.png" className='img-fluid' alt="metaverse" />
                          </div>
                            </div>
                        </div>
                    </div>
                </section>
    
    
                <section className='bgright'>
                  <div className="container py-3">
      <div className="heading-box text-center ">
        <h3 className="mb-3">FAQ</h3>
      </div>
      {/* 1 */}
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item rounded-3 border-0 shadow mb-2">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            What is a dApp?

            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>A dApp, or decentralized application, is a software application that runs on a blockchain rather than a centralized server. It leverages the power of blockchain for enhanced security, transparency, and autonomy.

              </p>
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="accordion-item rounded-3 border-0 shadow mb-2">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            How is a dApp different from a traditional app?

    
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>Unlike traditional apps that rely on a central server, dApps are decentralized and run on a peer-to-peer network, providing higher security, transparency, and resistance to censorship.

              </p>
            </div>
          </div>
        </div>
        {/* 3 */}
     
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
            What industries can benefit from dApp development?

    
            </button>
          </h2>
          <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p> Almost any industry can benefit, including finance (DeFi), gaming, healthcare, supply chain, and more, where security and transparency are key concerns.

              </p>
            </div>
          </div>
        </div>
        {/* 5 */}
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
            Can you help with building a DeFi application?

    
            </button>
          </h2>
          <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p> Yes, we specialize in DeFi app development, helping businesses create decentralized financial platforms such as exchanges, lending platforms, and more.
              </p>
            </div>
          </div>
        </div>
        {/* 6*/}
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
            How long does it take to build a dApp?

    
            </button>
          </h2>
          <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>The development timeline varies based on the complexity of the dApp, but typically, it can take anywhere from a few weeks to a few months.

              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item rounded-3 border-0 mb-2 shadow">
          <h2 className="accordion-header">
            <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
            What platforms do you develop dApps for?


    
            </button>
          </h2>
          <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
              <p>We develop dApps for multiple platforms, including Ethereum, Binance Smart Chain, and other blockchain networks, as well as Android dApps for mobile users.


              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
                  </section>

    </Layout>
  )
}

export default Dapp
