import React from 'react'
import '../Footer/footer.css'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
  {/* footer */}
<section className="footer-bg">
  <div className="footer-overlay" />
  {/* <div class="overlay2"></div> */}
  <div className="container">
    <div className="row">
      <div className="col-md-4 col-12">
        <div className="footer-list">
          <div className="row">
            <div className="col-12">
              <div className="list-box">
                <p>Our services</p>
                <ul>
                  {/* <li><a href="nft-marketplace-devlopment.html">NFT Marketplace</a></li> */}
                  <li><Link  to="/nft-development">NFT Development</Link></li>
                  <li><Link  to="/metaverse-development-service">Metaverse Development Services</Link>
               </li>
               <li><Link to="/blockchain-development-company"> BlockChain
               Development</Link></li>
          
                  <li><Link  to="/web-3.0-development-company">Web 3.0 Development</Link></li>
                  {/* <li><a href="#">cryptocurrency token development
                    </a></li> */}
                  <li><Link  to="/defi-development-company">DeFi Development</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-12">
        <div className="footer-list">
          <div className="row">
            <div className="col-12">
              <div className="list-box">
                <p>Head Office</p>
                <ul>
                  <li>
                    <a href> IFZA Properties, Dubai Silicon Oasis, Dubai, United Arab Emirates.
                    </a>
                  </li>
                  {/* <li><a href="">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, neque!</a></li> */}
                </ul>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="list-box">
                    <p>Development Office</p>
                    <ul>
                      <li>
                        <a href> A 202 Polaris Business Park Marol, Maroshi Road, near SBI bank, Andheri East 400059 Mumbai.
                        </a>
                      </li>
                      {/* <li><a href="">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, neque!</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-12">
        <div className="footer-list">
          <div className="row">
            <div className="col-12">
              <div className="list-box">
                <p>London Office</p>
                <ul>
                  <li>
                    <a href> 124 City Road,London,EC1V 2NX
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div class="row">
            <div class="col-12">
              <div class="list-box">
                <p>UK Office</p>

                <ul>
                  <li>
                    <a href=""> IFZA Properties, Dubai Silicon Oasis

                    </a>
                  </li>
                >


                </ul>
              </div>
            </div>

          </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-7">
        <div className="footer-list">
          <div className="row ">
            <div className="col-12">
              <div className="list-box">
                <p>Social Links</p>
                <div className="contact_link">
                  <div className="contactlinkbox2">
                    {/* <i class='bx bx-envelope'></i>  */}
                    <a href="mailto: info@blockcoaster.com"> <i className="bx bx-envelope" /></a>
                  </div>
                  <div className="contactlinkbox2">
                    <a href="https://www.linkedin.com/in/block-coaster-7b04612ab/"> <i className="bx bxl-linkedin" /></a>
                  </div>
                  <div className="contactlinkbox2">
                    {/* <i class='bx bxl-instagram' ></i> */}
                    <a href="https://www.instagram.com/blockcoasterrr/?igsh=MWJ3bnBiYmtwdTNpaA%3D%3D&utm_source=qr">
                      <i className="bx bxl-instagram" /></a>
                  </div>
                  <div className="contactlinkbox2">
                    {/* <i class='bx bxl-instagram' ></i> */}
                    <a href="https://www.facebook.com/people/Blockcoaster/61555764877706/"><i className="bx bxl-facebook" /></a>
                  </div>
                  {/* 5 */}
                  <div className="contactlinkbox2">
                    <a href="https://twitter.com/blockcoasterrr"><i className="bx bxl-twitter" /></a>
                  </div>
                  {/* 6 */}
                  <div className="contactlinkbox2">
                    <a href="https://www.youtube.com/channel/UC5QMgZo8zFNLDu3juwzMuaQ"><i className="bx bxl-youtube" /></a>
                  </div>
                  <div className="contactlinkbox2">
                    <a href="https://t.me/+VoNcmPrCSS80ZDQ1"><i className="bx bxl-telegram" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="caling-box">
          <div className="list-box">
            <p className style={{opacity: 0}} />
            <div className="contact_link2">
              <div className="contactlinkbox4">
                <a href="tel:7977734880"> <i className="bx bxs-phone-call" /></a>
              </div>
              <div className="contactlinkbox3">
                {/* <i class='bx bxl-whatsapp' ></i> */}
                <a href="https://api.whatsapp.com/send?phone=7977734880"> <i className="bx bxl-whatsapp" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</section>
{/* end-footer */}


    </>
  )
}

export default Footer;

