import React from 'react'
import Layout from '../Layout/Layout';
import '../Smartcontract-Dev/SmartDevelopment.css'
import { Link } from 'react-router-dom';

// import '../SmartContractdevelopment/SmartContractdevelopment.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';


import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

const SmartContractDevelopment = () => {
  return (
   <Layout>
              <section className="bannerSC">

<div className="container">
  <div className="row ">
    <div className="col-12 col-md-6">
      <div className="banner-caption2">
        <h1> <span className='block-clr'> Smart Contract Development Services</span> </h1>
        <p>Unlock the future of automation with Blockcoaster's advanced Smart Contract Development solutions. Secure, efficient, and tailored for your blockchain needs.
        </p>
        <Link to="/Contact" className="rounded-button-link">
  <button className="rounded-button">Connect With Our Experts</button>
</Link>                 

      </div>
    </div>

    <div className="col-12 col-md-6 text-center">
      <div className="banner-caption2">
        <img src="./Assets/smart-contract/smarcontract-dev/banner.png" className='img-fluid' alt="" />

      </div>
    </div>
  </div>
</div>
</section>

 {/* 2 */}
 <section className='bgleft'>
          <div className='container'>
         
          <div className='row align-items-center'>
          <div className='col-12 col-md-6 text-center'>
               <div class="why-img2">
               <img src="./Assets/smart-contract/smarcontract-dev/SmartExpertise.png" className='img-fluid' alt="" />
                  </div>   
               </div>
               <div className='col-12 col-md-6 pt-3'>
               <div class="heading-box text-start">
               <h4>Enhance Operational Efficiency with Our Smart Contract Development Expertise</h4>
               
                    <p>
                    At Blockcoaster, we deliver cutting-edge smart contract development services that streamline your business processes, eliminating the need for intermediaries and reducing operational costs. Our team of seasoned smart contract developers ensures your contracts are secure, transparent, and self-executing, boosting productivity across various industries. By utilizing blockchain technology, we create a tamper-proof system that enhances trust and speeds up transactions, helping you stay ahead in the fast-evolving digital landscape.
</p>
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
             
          </div>
          </div>
      </section>

      {/* 3 */}
      <section className='bgright'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-4">
                                <h3>Our Smart Contract Development Services</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="metacardSD">
                                <img src="./Assets/metaverseicons/1.png" alt="" />
                                <h2>End-to-End Smart Contract Development</h2>
                                <p>We provide complete smart contract solutions, from concept to deployment, customized to meet your business's specific needs.</p>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="col-md-4 col-12">
                            <div className="metacardSD">
                                <img src="./Assets/metaverseicons/2.png" alt="" />
                                <h2>Smart Contract Security Audits</h2>
                                <p>Ensure the integrity of your smart contracts with our comprehensive security audits to identify and resolve vulnerabilities.</p>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="col-md-4 col-12">
                            <div className="metacardSD">
                                <img src="./Assets/metaverseicons/3.png" alt="" />
                                <h2>Smart Contract Optimization</h2>
                                <p>Maximize the efficiency and performance of your existing smart contracts with our optimization services.</p>
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="col-md-4 col-12">
                            <div className="metacardSD">
                                <img src="./Assets/metaverseicons/4.png" alt="" />
                                <h2>DeFi Smart Contract Solutions</h2>
                                <p> We specialize in building secure and scalable smart contracts for decentralized finance (DeFi) applications.</p>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="col-md-4 col-12">
                            <div className="metacardSD">
                                <img src="./Assets/metaverseicons/5.png" alt="" />
                                <h2>DAO Smart Contract Development</h2>
                                <p>Automate governance and decision-making processes with smart contracts designed for Decentralized Autonomous Organizations (DAOs).</p>
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="col-md-4 col-12">
                            <div className="metacardSD">
                                <img src="./Assets/metaverseicons/6.png" alt="" />
                                <h2>Cross-Chain Smart Contract Solutions</h2>
                                <p>Facilitate seamless transactions across different blockchain networks with our cross-chain smart contract services.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section> 

            {/* 4 */}

            <section className='bgleft'>
          <div className='container'>
         
          <div className='row align-items-center'>
          <div className='col-12 col-md-6'>
          <h4 style={{fontSize:"35px", color:"#fff"}}>Why Does Your Business Need Smart Contract Development Services?</h4>

               {/* <div class="why-img2">
               <img src="./Assets/smart-contract/smarcontract-dev/needservice.png" className='img-fluid' alt="" />
                  </div>    */}
               </div>
               <div className='col-12 col-md-6 pt-3'>
               <div class="heading-box1">
               
                    <p>
                    In today’s digital-first economy, businesses need to adopt smart contracts to stay competitive. Smart contract development services offer unparalleled security, speed, and accuracy, automating manual processes and reducing the risk of human error. Whether you're managing financial transactions, supply chains, or real estate deals, smart contracts provide transparency and trust. As more industries transition to decentralized solutions, incorporating smart contracts into your business model ensures you stay at the forefront of blockchain technology.
</p>
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
             
          </div>
          </div>
      </section>

      {/* 5 */}

      <section className='bgright'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Our Smart Contract Development Process</h3>
                          
                            </div>
                        </div>
                    </div>
                <div className='row mt-5 justify-content-center'>
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                      <div className='line-circle'>
                        <p>01</p>
                      </div>
                         <div className='s-line'></div>
                            {/* <div className='block-icon'>
                            <img src="./Assets/web30/Process/1.png" alt="" />
                            </div> */}
                          <h3>Consultation
                          </h3>
                          <p>We begin with an in-depth consultation to understand your business requirements and objectives.</p>
                             


                     </div>
               </div>

               {/* 2 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>02</p>
                      </div>
                         <div className='s-line2'></div>
                           
                          <h3>Design & Development
                          </h3>
                          <p>Our team of expert blockchain developers designs and codes the smart contract based on your needs.</p>
                             


                     </div>
               </div>

               {/* 3 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>03</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Testing & Auditing
                          </h3>
                          <p>We rigorously test and audit the contract to ensure it's secure, error-free, and compliant.</p>
                             


                     </div>
               </div>
               {/* 4 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain3'>
                     <div className='line-circle2'>
                        <p>04</p>
                      </div>
                         <div className='s-line2'></div>
                          
                          <h3>Deployment
                          </h3>
                          <p>Once approved, we deploy the smart contract on the chosen blockchain platform.</p>
                             


                     </div>
               </div>
               {/* 5 */}
               <div className='col-12 col-md-2'>
                     <div className='card card-blockchain2'>
                     <div className='line-circle'>
                        <p>05</p>
                      </div>
                         <div className='s-line'></div>
                           
                          <h3>Ongoing Support
                          </h3>
                          <p> We offer continuous support and maintenance for your smart contracts to keep them up to date and optimized.
                          </p>
                             


                     </div>
               </div>
             
        
          </div>   
                </div>

              </section> 


              {/* 6 */}
              <section className='bgleft'>
                <div className='container'>
                <div className="row">
                        <div className="col-12">
                            <div className="heading-box-sec text-center py-5">
                            <h3>Dynamic Use Cases of Smart Contract Consulting Services </h3>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
            <div className="col-12 col-md-4">
              <div className='card nft-card-Dev2'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className='bottom_line'></div>
                <div className='bottom-right_line'></div>
                <div className="nftdev-img2">
                <img src="./Assets/web30/Benefits/1.png" alt="" />
                </div>
                <h3>Supply Chain Management</h3>

                <p>Automate and secure the entire supply chain process with transparent, tamper-proof smart contracts.</p>
              </div>
            </div>
            {/* 2 */}  <div className="col-12 col-md-4">
              <div className='card nft-card-Dev2'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className='bottom_line'></div>
                <div className='bottom-right_line'></div>
                <div className="nftdev-img2">
                <img src="./Assets/web30/Benefits/2.png" alt="" />
                </div>
                <h3>Financial Services</h3>
                <p>From loan agreements to insurance policies, smart contracts streamline financial transactions with minimal friction.</p>
              </div>
            </div>

            {/* 3 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Dev2'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className='bottom_line'></div>
                <div className='bottom-right_line'></div>
                <div className="nftdev-img2">
                <img src="./Assets/web30/Benefits/3.png" alt="" />
                </div>
                <h3>Real Estate</h3>
                <p>Enable quick, secure, and trustless real estate transactions without intermediaries.
</p>
              </div>
            </div>
            {/* 4 */}
            <div className="col-12 col-md-4" >
              <div className='card nft-card-Dev2'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className='bottom_line'></div>
                <div className='bottom-right_line'></div>
                <div className="nftdev-img2">
                <img src="./Assets/web30/Benefits/4.png" alt="" />
                </div>
                <h3>Healthcare
                </h3>
                <p>Smart contracts protect patient data, automate insurance claims, and ensure compliance with healthcare regulations.</p>
              </div>
            </div>
            {/* 5 */}
            <div className="col-12 col-md-4">
              <div className='card nft-card-Dev2'>
                <div className='top_line'></div>
                <div className='right_line'></div>
                <div className='bottom_line'></div>
                <div className='bottom-right_line'></div>
                <div className="nftdev-img2">
                <img src="./Assets/web30/Benefits/5.png" alt="" />
                </div>
                <h3>Gaming & NFTs</h3>
                <p> Empower decentralized gaming platforms and NFT marketplaces with smart contracts to manage assets and rewards.</p>
              </div>
            </div>
             
      
          </div>
         
        
                </div>

              </section>

              {/* 7 */}
              <section className='bgright'>

<div className="container">
<div class="row py-1">
             <div class="col-12">
               <div class="heading-box">
                 <h3>Smart Contract Development on Various Blockchain Platforms </h3>
                 <div class="line2"></div>
               </div>
             </div>
           </div>
<Swiper
  modules={[Navigation, Pagination, Scrollbar, A11y]}
  spaceBetween={10}
  slidesPerView={3}
  centeredSlides={true}
  navigation
  pagination={{ clickable: true }}
  scrollbar={{ draggable: true }}
  onSwiper={(swiper) => console.log(swiper)}
  loop={true}  // Enable looping
  onSlideChange={() => console.log('slide change')}
  breakpoints={{
   320: {  // Screens with a width of 320px or more
     slidesPerView: 1,  // Show 1 slide
     centeredSlides: true,  // Center the slide
   },
   768: {  // Screens with a width of 768px or more (e.g., tablets)
     slidesPerView: 5,  // Show 2 slides
   },
   1024: {  // Screens with a width of 1024px or more (e.g., desktops)
     slidesPerView:5,  // Show 3 slides
   },
 }}
   
  
>
<SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/binance.png"  className="img-fluid" alt="" />
           <h3>Binance Smart Chain (BSC)</h3>
      </div>
   
     </SwiperSlide>
     {/* 2 */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/solana.png"  className="img-fluid" alt="" />
           <h3>Solana</h3>
      </div>
   
     </SwiperSlide>

     {/* 3/ */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/polygon.png"  className="img-fluid" alt="" />
           <h3>Polygon</h3>
      </div>
   
     </SwiperSlide>

     {/* 4 */}
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
         <img src="./Assets/nft/eth.png"  className="img-fluid" alt="" />
           <h3>	Ethereum</h3>
      </div>
   
     </SwiperSlide>
     <SwiperSlide className='my-slider2'>
      <div className='network_card'>
      <img src="./Assets/smart-contract/Polkadot.jpg" className='img-fluid' alt="" />
      <h3>Polkadot</h3>
          
      </div>
   
     </SwiperSlide>



  
   ...
 </Swiper>
</div>
</section>

{/* 8 */}
<section className='bgleft'>
          <div className='container'>
         
          <div className='row align-items-center'>
          <div className='col-12 col-md-6 text-center'>
               <div class="why-img2">
               <img src="./Assets/smart-contract/audit.png" class="img-fluid" alt=""/>
                  </div>   
               </div>
               <div className='col-12 col-md-6 pt-3'>
               <div class="heading-box text-start">
               <h4>Why Choose Blockcoaster as Your Smart Contract Development Company?</h4>
               
                    <p>
                    At Blockcoaster, we take pride in delivering exceptional blockchain development services that cater to your specific business needs. As a leading smart contract development company, we ensure that our contracts are secure, scalable, and fully customized to suit your operational goals. With our expertise in smart contract audit, NFT development, and DeFi solutions, we guarantee a seamless experience from concept to deployment. Our blockchain developer roadmap is designed to keep your business future-proof, and our commitment to quality makes us the partner of choice for businesses looking to thrive in the Web3 space.
</p>
                    {/* <div class="line2"></div> */}
                  </div>   
               </div>
             
          </div>
          </div>
      </section>

      {/* 9 */}

      <section className='bgright'>
              <div className="container py-3">
  <div className="heading-box text-center ">
    <h3 className="mb-3">FAQ</h3>
  </div>
  {/* 1 */}
  <div className="accordion accordion-flush" id="accordionFlushExample">
    <div className="accordion-item rounded-3 border-0 shadow mb-2">
      <h2 className="accordion-header">
        <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        What is a smart contract ?
        </button>
      </h2>
      <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
        <div className="accordion-body">
          <p>A smart contract is a self-executing contract with the terms of the agreement written into code, deployed on a blockchain to automate and secure transactions.</p>
        </div>
      </div>
    </div>
    {/* 2 */}
    <div className="accordion-item rounded-3 border-0 shadow mb-2">
      <h2 className="accordion-header">
        <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        How much does a smart contract audit cost ?
        </button>
      </h2>
      <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
        <div className="accordion-body">
          <p>The cost of a smart contract audit depends on the complexity and size of the contract. Contact us for a customized quote based on your project needs.</p>
        </div>
      </div>
    </div>
    {/* 3 */}
 
    <div className="accordion-item rounded-3 border-0 mb-2 shadow">
      <h2 className="accordion-header">
        <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
        Can you create an ERC20 token for my project ?
        </button>
      </h2>
      <div id="flush-collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
        <div className="accordion-body">
          <p> Yes, we specialize in creating ERC20 tokens and other crypto token development services to help you launch your project seamlessly.</p>
        </div>
      </div>
    </div>
    {/* 5 */}
    <div className="accordion-item rounded-3 border-0 mb-2 shadow">
      <h2 className="accordion-header">
        <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
        What platforms do you support for smart contract development ?
        </button>
      </h2>
      <div id="flush-collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
        <div className="accordion-body">
          <p>We develop smart contracts on multiple blockchain platforms, including Ethereum, Binance Smart Chain, Solana, Polkadot, and Polygon.</p>
        </div>
      </div>
    </div>
    {/* 6*/}
    <div className="accordion-item rounded-3 border-0 mb-2 shadow">
      <h2 className="accordion-header">
        <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
        Why should I choose Blockcoaster for my smart contract development ?
        </button>
      </h2>
      <div id="flush-collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
        <div className="accordion-body">
          <p>Blockcoaster is a leading Web3 development company with extensive experience in smart contract development, smart contract audits, and blockchain solutions. We prioritize security, scalability, and customization to ensure your success in the decentralized world.</p>
        </div>
      </div>
    </div>
 
  </div>
</div>
              </section>
   </Layout>
  )
}

export default SmartContractDevelopment;