import React, { useState, useEffect } from 'react';


const Typetext = ({ text, speed = 100 }) => {

    const [displayText, setDisplayText] = useState('');
    const [index, setIndex] = useState(0);
  
   
  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText(prev => prev + text[index]);
        setIndex(prev => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setDisplayText('');
        setIndex(0);
      }, 1000); // Adjust the delay before restarting the typing effect
      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);
  return (
    <div>
      <h1>{displayText}</h1>
    </div>
  )
}

export default Typetext;
  
